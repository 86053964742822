import * as R from 'ramda'
import React, { Component } from 'react'
import * as qs from 'query-string'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  getProduct,
  setFilter,
  clearFilters,
  removeFilter,
} from '../../features/products/productsActions'

import MatcherProduct from '../../features/product/MatcherProduct'

const parseFiltersJSON = (str) => {
  let json
  try {
    json = JSON.parse(str)
  } catch (error) {}
  return R.or(json, [])
}

class MatcherProductContainer extends Component {
  componentWillMount() {
    // On component mount check the URL, afterwards use Redux only
    this.checkURLAndUpdateRedux()
  }

  checkURLAndUpdateRedux() {
    const { getProductData, location, match, setFilter } = this.props

    const search = qs.parse(R.path(['search'], location))
    const id = R.path(['params', 'partNumber'], match)
    const page = R.or(Number(R.path(['page'], search)), 0)
    const filters = parseFiltersJSON(R.path(['filters'], search))
    // Set Filters
    R.forEach((filter) => setFilter(filter))(filters)
    getProductData(id, page)
  }

  render() {
    const {
      product,
      filters,
      setFilter,
      loading,
      removeFilter,
      clearFilters,
      getProductData,
    } = this.props
    return (
      <MatcherProduct
        loading={loading}
        filters={filters}
        product={product}
        getProduct={getProductData}
        setFilter={setFilter}
        removeFilter={removeFilter}
        clearFilters={clearFilters}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const partNumber = R.path(['match', 'params', 'partNumber'], ownProps)
  return {
    product: R.path(['products', 'object', partNumber], state),
    filters: R.path(['products', 'filters'], state),
    loading: R.path(['products', 'loading'], state),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    getProductData: (id, page) => dispatch(getProduct(id, page, true)),
    setFilter: (filter) => dispatch(setFilter(filter)),
    removeFilter: (filter) => dispatch(removeFilter(filter)),
    clearFilters: () => dispatch(clearFilters()),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(MatcherProductContainer)
)
