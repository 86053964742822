import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'

import Cart from './Cart'

const Draftcarts = ({
  draftcarts,
  removeCart,
  restoreCart,
  aitoMatches,
  email,
  upload,
}) => {
  const [page, setPage] = React.useState(0)
  const rowsPerPage = 3

  function handleChangePage(event, newPage) {
    setPage(newPage)
  }

  function removeCartAndRefresh(cart) {
    removeCart(cart)
    setPage(0)
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Sent to customer</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Color</TableCell>
          <TableCell>Model</TableCell>
          <TableCell>Show cart</TableCell>
          <TableCell>Delete</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {draftcarts
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((draft, index) => (
            <Cart
              key={index}
              cart2={draft.cart}
              upload={upload}
              email={email}
              isDraft={true}
              draftMeta={draft.meta}
              aitoMatches={aitoMatches}
              removeCart={removeCartAndRefresh}
              restoreCart={restoreCart}
            />
          ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[3]}
            count={draftcarts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} of ${count} draft carts`
            }
            onChangePage={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0)
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1)
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1)
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div style={{ flexShrink: 0, marginLeft: 20 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  )
}

export default Draftcarts
