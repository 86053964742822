import React, { useState, useCallback, useEffect } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import AttachmentIcon from '@material-ui/icons/Attachment'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'
import ErrorIcon from '@material-ui/icons/Error'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { TableTopInvoicePdf } from '../tabletops/TableTopInvoicePdf'
import lodash from 'lodash'

export const colorMap = {
  'Clean White': '#FFFFFF',
  'Natural White': '#F2F2F2',
  'Blush Rose': '#DBB9B7',
  'Feather Grey': '#CCCCCC',
  'Linen Brown': '#9E9C91',
  'Thermal Grey': '#8E8E89',
  'Polar Grey': '#89898E',
  'Water Green': '#7D8E7C',
  'Petrol Blue': '#58676D',
  'Deep Burgundy': '#663D47',
  'Graphite Grey': '#404040',
  'Midnight Black': '#191919',
  'Natural Oak': '#c7a683',
  'Natural Walnut': '#554747',
  'Polished Aluminium': '#bdbfbd',
  'Polished Brass': '#e1a84b',
  'Smoke Oak': '#8b785c',
  'White Oak': '#d6c49c',
  'Ash Green': '#b4aea2',
  'Ivory Beige': '#d2cfc8',
  'Dusty Rose': '#b6a59d',
}

const CartItemRow = ({
  item,
  changeComment,
  changeQuantity,
  changeDiscount,
  isDraft,
  removeCartItem,
  price,
  changePdf,
  isTableTop,
  tableTopPdfData,
  checked,
  handleCheckedChange,
  itemUpdateFailed,
  failedRows,
}) => {
  const [showPdf, setShowPdf] = useState(false)
  const [currentComment, setCurrentComment] = useState(item['comment'])
  const [currentDiscount, setCurrentDiscount] = useState(item['discount'])
  const size = item['custom_size']
  const sku = item['sku']
  const section = item['cart_section']
  /*
  Row identifier is sku + section for normal items
  and sku + custom_size + section for custom sized items
  this is important for handling checked items and
  comments
  */
  let identifier = item['product'].includes('customxcustom')
    ? sku + size + section
    : sku + section

  if (item.tabletopId) {
    identifier += item.tabletopId
  }

  const product = item['product'].includes('customxcustom')
    ? item['product'].replace('customxcustom', size)
    : item['product']
  const qty = item['qty']
  const pdf = item['pdf'] && item['pdf']['info_pdf_b64']
  const pdfName = item['pdf'] && item['pdf']['info_pdf_name']

  const discountFraction = item.discount / 100
  const rowPriceAfterDiscount = qty * price - qty * price * discountFraction

  const handlePdfChange = (event) => {
    const pdf = event.target.files[0]
    if (!pdf) return {}

    const name = pdf.name
    let data = ''
    let fileReader = new FileReader()

    fileReader.onload = function (event) {
      data = event.target.result
      changePdf(item, {
        info_pdf_b64: data,
        info_pdf_name: name,
      })
    }
    fileReader.readAsDataURL(pdf)
  }

  const debouncedHandleCommentChange = useCallback(
    lodash.debounce((item, newValue) => changeComment(item, newValue), 1000),
    []
  )

  const debouncedHandleDiscountChange = useCallback(
    lodash.debounce((item, newValue) => changeDiscount(item, newValue), 1000),
    []
  )

  useEffect(() => {
    // Debounce comment field change in parent component to update after 1000ms
    // since this is expensive and triggers rendering of entire Cart component
    // local input state updates immediately
    debouncedHandleCommentChange(item, currentComment)
  }, [currentComment])

  useEffect(() => {
    // Debounce discount field change in parent component to update after 1000ms
    // since this is expensive and triggers rendering of entire Cart component
    // local input state updates immediately
    debouncedHandleDiscountChange(item, currentDiscount)
  }, [currentDiscount])

  return (
    <TableRow
      hover={false}
      selected={itemUpdateFailed}
      key={identifier}
      id={`cart-row-${identifier}`}
    >
      <TableCell>
        <Checkbox
          id={identifier}
          name={identifier}
          checked={checked.includes(identifier)}
          onChange={(e) => handleCheckedChange(e, section)}
        />
      </TableCell>
      <TableCell>
        {product.replace('A.S.Helsingö', '').replace('A.S.Helsing&ouml; ', '')}
      </TableCell>
      <TableCell>
        <form>
          <TextField
            id={`comment-${identifier}`}
            margin="dense"
            variant="outlined"
            value={currentComment}
            onChange={(event) => setCurrentComment(event.target.value)}
            disabled={isDraft}
            inputProps={{ 'aria-label': 'comment' }}
            multiline
          />
        </form>
      </TableCell>
      <TableCell style={{ minWidth: 70, fontSize: 16 }}>
        {isDraft ? (
          qty
        ) : (
          <TextField
            style={{ fontSize: 16 }}
            type="number"
            inputProps={{ min: 0, max: 1000, step: 1 }}
            value={qty}
            // defaultValue={'' + price}
            onChange={(event) =>
              changeQuantity(parseFloat(event.target.value), item)
            }
          />
        )}
      </TableCell>
      <TableCell style={{ minWidth: 70, fontSize: 16 }}>
        {'€ ' + price}
      </TableCell>
      <TableCell style={{ fontSize: 16 }}>
        {item.discount ? '€ ' + rowPriceAfterDiscount : '€ ' + qty * price}
      </TableCell>

      <TableCell>
        <form>
          <TextField
            label="%"
            id={`discount-${identifier}`}
            margin="dense"
            variant="outlined"
            type="number"
            value={currentDiscount}
            onChange={(event) =>
              setCurrentDiscount(parseFloat(event.target.value))
            }
            disabled={isDraft}
            inputProps={{ 'aria-label': 'line-discount' }}
          />
        </form>
      </TableCell>

      {pdfName && pdf ? (
        <TableCell>
          {pdfName && (
            <a
              href={`data:application/pdf;base64,${pdf}`}
              download={pdfName}
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="raised"
                component="span"
                style={{
                  textAlign: 'center',
                  textTransform: 'none',
                  maxWidth: '20%',
                  padding: '4px',
                  wordBreak: 'break-word',
                }}
              >
                {pdfName}
              </Button>
            </a>
          )}
        </TableCell>
      ) : (
        <TableCell>
          {isTableTop && tableTopPdfData ? (
            showPdf ? (
              <PDFDownloadLink
                document={
                  <TableTopInvoicePdf
                    tableTopStats={tableTopPdfData['tableTopStats']}
                    deliveryAndInstallation={
                      tableTopPdfData.deliveryAndInstallation
                    }
                    distance={tableTopPdfData.distanceStats.label}
                    extraHoles={tableTopPdfData.extraHoles}
                    totalPrice={tableTopPdfData.totalPrice}
                    id={tableTopPdfData.id}
                    email={tableTopPdfData.email}
                    language={tableTopPdfData.language}
                  />
                }
                fileName="Tabletop_order_confirmation.pdf"
              >
                {({ blob, url, loading, error }) => {
                  console.log('url', url)
                  console.log('blob', blob)
                  console.log('tabletop', tableTopPdfData.tableTopStats)
                  return loading ? 'Loading document...' : 'PDF'
                }}
              </PDFDownloadLink>
            ) : (
              <IconButton
                aria-label="attach"
                component="span"
                onClick={() => setShowPdf(true)}
              >
                <AttachmentIcon color={'primary'} />
              </IconButton>
            )
          ) : (
            <div>
              <label htmlFor={product}>
                <Tooltip title={pdfName ? pdfName : 'Upload pdf'}>
                  <IconButton aria-label="attach" component="span">
                    <AttachmentIcon color={pdfName ? 'primary' : 'secondary'} />
                  </IconButton>
                </Tooltip>
              </label>
              <input
                accept=".pdf"
                id={product}
                type="file"
                onChange={handlePdfChange}
                style={{ display: 'none' }}
              />
            </div>
          )}
        </TableCell>
      )}
      {!isDraft && (
        <TableCell>
          <IconButton aria-label="delete" onClick={() => removeCartItem(item)}>
            <DeleteIcon color="secondary" />
          </IconButton>
        </TableCell>
      )}
      {itemUpdateFailed && (
        <TableCell>
          <Tooltip title="Not available in selected color, type or dimension!">
            <IconButton aria-label="error" component="span">
              <ErrorIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
      {!itemUpdateFailed && failedRows.length > 0 && <TableCell />}
    </TableRow>
  )
}

export default CartItemRow
