/* eslint-disable no-console */
/* eslint-disable dot-notation */
/* eslint-disable react/no-unused-state */
import * as R from 'ramda'
import moment from 'moment'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button'
import ClearIcon from '@material-ui/icons/Clear';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Alert from '@material-ui/lab/Alert';
import { styled } from '@material-ui/styles'
import Switch from '@material-ui/core/Switch'
import ShopTwoIcon from '@material-ui/icons/ShopTwo'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Auth } from 'aws-amplify'
import MatcherProducts from '../products/MatcherProducts'
import MatcherProduct from '../product/MatcherProduct'
import MatcherMeta from './MatcherMeta'
import CustomItems from './CustomItems'
import Cart from './Cart'
import Draftcarts from './Draftcarts'
import TableTopSection from '../tabletops/TableTops'
import CartCategorySelect from './CartCategorySelect'
import Accessories from '../accessories/Accessories'
import { uploadPDF } from '../products/productsActions'
import customPrice from '../../custom-price.json'
import { fetchAllMatches, convertPriceEstimateToPaos } from '../../api/server'

const getAuthenticatedUser = async () => {
  const userData = await Auth.currentAuthenticatedUser()
  return userData && userData.signInUserSession
}

const PageHeader = styled(CardHeader)({
  marginTop: 20,
  fontSize: '2.5rem',
  textTransform: 'uppercase',
  '& .MuiTypography-displayBlock': {
    fontSize: '2.5rem',
  },
})

const ClearFab = styled(Fab)({
  backgroundColor: '#c7beb8',
  color: '#fafafa',
  '&:hover': {
    backgroundColor: '#e10050',
  },
})

class Matcher extends Component {
  static calculateCustomSidePriceShopify(product, customSize) {
    // Look up meter price from shopify product data and min price from json
    const customProductPrice = customPrice.find((p) => p.sku === product.sku)

    const minPrice =
      customProductPrice &&
      customProductPrice['min_custom_size_price'] !== 'DEFAULT'
        ? customProductPrice['min_custom_size_price']
        : 60
    console.log('min price', minPrice)
    // const minPrice =
    //   product && product['compare_at_price'] ? product['compare_at_price'] : 60
    const squareMeterPrice =
      product && product['compare_at_price']
        ? Number(product['compare_at_price'])
        : 220

    console.log('squreMeter price', squareMeterPrice)

    const size = customSize
      .split('x')
      .map((metric) => parseFloat(metric) / 100)
      .reduce((acc, c) => acc * c, 1)
    const price = Math.round(size * squareMeterPrice)
    console.log('final price by squeremeter price', price)
    console.log('final price', price > minPrice ? price : minPrice)
    // console.log(sku, size, price)
    return price > minPrice ? price : minPrice
  }

  constructor(props) {
    super(props)
    this.loadData = this.loadData.bind(this)
    this.updateCart = this.updateCart.bind(this)
    this.updateTableTopPdfData = this.updateTableTopPdfData.bind(this)
    this.updateAitoMatches = this.updateAitoMatches.bind(this)
    this.filterProductsToggle = this.filterProductsToggle.bind(this)
    this.selectMatch = this.selectMatch.bind(this)
    this.closeMatchSelect = this.closeMatchSelect.bind(this)
    this.openMatchSelect = this.openMatchSelect.bind(this)
    this.saveCart = this.saveCart.bind(this)
    this.clearCart = this.clearCart.bind(this)
    this.removeCart = this.removeCart.bind(this)
    this.restoreCart = this.restoreCart.bind(this)
    this.removeCartItem = this.removeCartItem.bind(this)
    this.updateDraftCarts = this.updateDraftCarts.bind(this)
    this.addNewCartDraftCarts = this.addNewCartDraftCarts.bind(this)
    this.saveSentCartToDraftCarts = this.saveSentCartToDraftCarts.bind(this)
    this.handleCommentChange = this.handleCommentChange.bind(this)
    this.replaceCart = this.replaceCart.bind(this)
    this.handleMinicartCategoryChange =
      this.handleMinicartCategoryChange.bind(this)
    this.getDraftCartColors = this.getDraftCartColors.bind(this)
    this.getDraftCartModels = this.getDraftCartModels.bind(this)
    this.handleFetchAllMatchesAgain = this.handleFetchAllMatchesAgain.bind(this)
    this.handleCouponChange = this.handleCouponChange.bind(this)
    this.handleConvertToPaos = this.handleConvertToPaos.bind(this)
    this.getProductPrice = this.getProductPrice.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    // this.calculateCustomSidePrice = this.calculateCustomSidePrice.bind(this)

    this.handlePdfsChange = this.handlePdfsChange.bind(this)
    this.handleQtyChange = this.handleQtyChange.bind(this)
    this.findProductBySku = this.findProductBySku.bind(this)
    this.handleDiscountChange = this.handleDiscountChange.bind(this)

    this.addItemToCart = this.addItemToCart.bind(this)

    this.draftCartsRef = null
    this.cartRef = null

    this.state = {
      data: '',
      cart: {},
      cart2: {},
      id: '',
      shopifyDraftId: '',
      shopifyCustomerId: '',
      shopifyStore: '',
      filters: [],
      isLoading: false,
      error: null,
      filterProducts: 'ON',
      products: [],
      initialProducts: [],
      showInitialProducts: true,
      matches: {},
      aitoMatches: {},
      currentMatch: 0,
      currentProduct: [],
      selectedRow: [],
      selectedRows: [],
      displayProducts: 'block',
      displayMatches: 'none',
      draftCarts: [],
      showTableTops: true,
      tableTopPdfData: {},
      showDraftCarts: true,
      selectedMinicartCategory: 0,
      coupon: '',
      modal: false
    }
  }

  findProductBySku(sku) {
    return this.props.variants.find((product) => product.sku === sku)
  }

  handleQtyChange(qty, item) {
    const { cart2: cart } = this.state
    const cartSection = this.state.selectedMinicartCategory
    const sectionItems = cart && cart[cartSection]
    const newItem = { ...item, qty }
    const newSectionItems = sectionItems.map((cartItem) =>
      cartItem.sku === item.sku &&
      cartItem.custom_size === item.custom_size &&
      cartItem.tabletopId === item.tabletopId
        ? newItem
        : cartItem
    )
    this.setState({
      cart2: { ...this.state.cart2, [cartSection]: newSectionItems },
    })
  }

  handleCommentChange(item, comment) {
    const cartSection = this.state.selectedMinicartCategory
    const sectionItems = this.state.cart2 && this.state.cart2[cartSection]
    const newItem = { ...item, comment }
    const newSectionItems =
      sectionItems &&
      sectionItems.map((cartItem) =>
        cartItem.sku === item.sku &&
        cartItem.custom_size === item.custom_size &&
        cartItem.tabletopId === item.tabletopId
          ? newItem
          : cartItem
      )
    this.setState({
      cart2: { ...this.state.cart2, [cartSection]: newSectionItems },
    })
  }

  handleDiscountChange(item, discount) {
    const cartSection = this.state.selectedMinicartCategory
    const sectionItems = this.state.cart2 && this.state.cart2[cartSection]
    const newItem = { ...item, discount }
    const newSectionItems = sectionItems.map((cartItem) =>
      cartItem.sku === item.sku &&
      cartItem.custom_size === item.custom_size &&
      cartItem.tabletopId === item.tabletopId
        ? newItem
        : cartItem
    )
    this.setState({
      cart2: { ...this.state.cart2, [cartSection]: newSectionItems },
    })
  }

  loadData(stateData) {
    const { uploadPDF, products } = this.props
    const parsedData = JSON.parse(stateData)
    console.log('parsed data', parsedData)
    const filtersFromData = [
      { door_type: parsedData['doormodel'] },
      { color: parsedData['color'] },
    ]
    const initialMatches = parsedData['matches']
    const initialPartNumbers = parsedData['partNumbers']
    const shopifyDraftId = parsedData['shopify_draft_order_id']
    const shopifyCustomerId = parsedData['shopify_customer_id']

    const initialProducts =
      initialPartNumbers && initialMatches
        ? initialPartNumbers.reduce((acc, curr) => {
            const relatedMatch = initialMatches.find(
              (item) =>
                item.ikea_part_number &&
                item.ikea_part_number === curr.partNumber
            )
            return [...acc, { ...curr, ...relatedMatch }]
          }, [])
        : []

    this.setState({
      data: parsedData,
      filters: filtersFromData,
      id: parsedData['id'],
      shopifyDraftId,
      shopifyCustomerId,
      products,
      initialProducts,
      isLoading: false,
      draftCarts:
        (parsedData.draftCarts &&
          parsedData.draftCarts.filter(
            (draft) => draft.meta && draft.meta.isNewFormat
          )) ||
        [],
    })
    // const data = JSON.stringify({ id: this.state.data['id'] })
  }

  handleFetchAllMatchesAgain() {
    this.setState({ isLoading: true })
    const { id, data } = this.state
    fetchAllMatches(id)
      .then((resp) => {
        const { matches } = resp
        const newData = JSON.stringify({
          ...data,
          matches,
          isLoading: false,
        })
        console.log('setting new data to state', {
          ...data,
          matches,
          isLoading: false,
        })
        this.loadData(newData)
      })
      .catch((error) => {
        console.log(error)
        this.setState({ error, isLoading: false })
      })
  }

  handleConvertToPaos() {
    const { id } = this.state
    convertPriceEstimateToPaos(id)
      .then((resp) => {
        if (resp && resp.status === 200) {
          getAuthenticatedUser()
            .then((user) => {
              return fetch(`/api/ikea/pdf/upload/${id}`, {
                method: 'GET',
                headers: { Authorization: `jwt ${user.accessToken.jwtToken}` },
              })
            })
            .then((response) => {
              if (response.ok) {
                return response.json()
              }
              throw new Error('Something went wrong ...')
            })
            .then((data) => {
              this.loadData(data)
            })
            .catch((error) => this.setState({ error, isLoading: false }))
        } else {
          alert(resp.message)
        }
      })
      .catch((error) => alert(error))
  }

  addItemToCart(identifier, product) {
    console.log('adding product', product, 'with id', identifier)
    /*
    New cart format:
    {
      // First cart section / category
      0: [
        // Array of cart Items
        {
          id: '',
          sku: '',
          product: 'A.S. Helsingö...'
          custom_size: '',
          comment: '',
          pdfs: '',
          discount; '',
          cartSection: 0
        },
        {cartItem},
        {cartItem}
      ]
    }
    */
    const { cart2: cart, selectedMinicartCategory: cartSection } = this.state
    let newCart
    const itemInCart =
      cart[cartSection] &&
      cart[cartSection].find(
        (item) =>
          item.sku === product.sku &&
          item.custom_size === product.custom_size &&
          item.tabletopId === product.tabletopId
      )
    if (itemInCart) {
      // Product was already in cart --> increase qty
      const currentItemIndex = cart[cartSection].findIndex(
        (item) =>
          item.sku === product.sku &&
          item.custom_size === product.custom_size &&
          item.tabletopId === product.tabletopId
      )
      const currentItem = cart[cartSection].find(
        (item) =>
          item.sku === product.sku &&
          item.custom_size === product.custom_size &&
          item.tabletopId === product.tabletopId
      )
      const currentQty = currentItem.qty
      const newItem = (cart[cartSection][currentItemIndex] = {
        ...cart[cartSection][currentItemIndex],
        qty: currentQty + 1,
        matcher_id: [...currentItem.matcher_id, identifier],
      })
      const newCartSection = [...cart[cartSection]]
      newCartSection[currentItemIndex] = newItem
      newCart = { ...cart, [cartSection]: [...newCartSection] }
    } else {
      // Product was not yet in cart, add item to cart
      const newItem = {
        ...product,
        matcher_id: [identifier],
        unit_price: this.getProductPrice(product),
      }
      const currentSectionItems =
        cart && cart[cartSection] ? cart[cartSection] : []
      newCart = { ...cart, [cartSection]: [...currentSectionItems, newItem] }
    }
    this.setState({ cart2: newCart })
  }

  updateCart(newCart) {
    this.setState({ cart2: newCart })
  }

  updateTableTopPdfData(identifier, data) {
    console.log('tabletop data', data)
    const tableTopPdfData = {
      ...this.state.tableTopPdfData,
      [identifier]: { ...data },
    }
    this.setState({ tableTopPdfData })
  }

  updateAitoMatches(matches) {
    this.setState({ aitoMatches: matches })
  }

  selectMatch(row, i, width = false, height = false) {
    if (width && height) {
      row['cabinet_width'] = width
      row['cabinet_height'] = height
      row['product_type'] = 'Custom ' + row['product_type']
    }
    const newMatches = this.state.matches
    newMatches[i] = row
    if (this.state.cart.hasOwnProperty(i)) {
      const { [i.toString()]: _, ...cart } = this.state.cart
      this.setState({ cart })
    }

    this.setState({
      displayProducts: 'block',
      displayMatches: 'none',
      selectedRow: row,
      matches: newMatches,
    })
  }

  closeMatchSelect() {
    this.setState({ displayProducts: 'block', displayMatches: 'none' })
  }

  openMatchSelect(rows, currentMatch, original) {
    this.setState({
      displayProducts: 'none',
      displayMatches: 'block',
      selectedRows: rows,
      currentMatch,
      currentProduct: original,
    })
  }

  filterProductsToggle() {
    const { filterProducts } = this.state
    if (filterProducts === 'ON') this.setState({ filterProducts: 'OFF' })
    else this.setState({ filterProducts: 'ON' })
  }

  getDraftCartColors() {
    // Returns all draft cart colors as a string
    const { cart2: cart } = this.state
    try {
      const cartItems =
        cart &&
        Object.values(cart) &&
        Object.values(cart).reduce((acc, itemArr) => {
          return [...acc, ...itemArr]
        }, [])

      const colorsArr = cartItems.reduce((acc, curr) => {
        if (
          acc &&
          curr['custom_color'] &&
          !acc.includes(curr['custom_color'])
        ) {
          return [...acc, curr['custom_color']]
        }
        return acc
      }, [])
      return colorsArr.join(', ')
    } catch (err) {
      console.log('error in draft cart colors')
      return ''
    }
  }

  getDraftCartModels() {
    // Returns all draft cart models as a string
    try {
      const { cart2: cart } = this.state
      const models = ['INGARO', 'SAMSO', 'ENSIO']

      const cartItems =
        cart &&
        Object.values(cart) &&
        Object.values(cart).reduce((acc, itemArr) => {
          return [...acc, ...itemArr]
        }, [])

      const modelsArr = Object.values(cartItems).reduce((acc, curr) => {
        const modelInCurr = models.find(
          (model) =>
            curr['product'] && curr['product'].toUpperCase().includes(model)
        )
        if (acc && modelInCurr && !acc.includes(modelInCurr)) {
          return [...acc, modelInCurr]
        }
        return acc
      }, [])
      return modelsArr.join(', ')
    } catch (err) {
      console.log(err)
      return ''
    }
  }

  handleMinicartCategoryChange(newCategory) {
    this.setState({ selectedMinicartCategory: Number(newCategory) })
  }

  updateDraftCarts(draftCarts) {
    getAuthenticatedUser()
      .then((user) => {
        const { id } = this.state
        return fetch(`/api/ikea/draftcarts/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `jwt ${user.accessToken.jwtToken}`,
          },
          body: JSON.stringify({ data: draftCarts }),
        })
      })
      .then((response) => {
        if (response.ok) {
          // console.log('ok', response.json())
          window.scrollTo(0, this.draftCartsRef.offsetTop)
          console.log('draft carts updated')
        } else {
          throw new Error('Something went wrong ...')
        }
      })
      .then((data) => this.loadData(data))
      .catch((error) => this.setState({ error, isLoading: false }))
  }

  saveCart() {
    /*
    New saved cart format:
    {
      cart: {...},
      meta: {
        createdAt,
        colors,
        models,
        coupon,
        isNewFormat
      }
    }
    */
    const { cart2, coupon, draftCarts } = this.state
    if (!R.isEmpty(cart2)) {
      const dateCreated = cart2['createdAt'] || moment()
      const draftColors = this.getDraftCartColors()
      const draftModels = this.getDraftCartModels()
      console.log('models in draft', draftModels)
      const newDraftCarts = [
        {
          cart: { ...cart2 },
          meta: {
            createdAt: dateCreated,
            colors: draftColors,
            models: draftModels,
            coupon,
            isNewFormat: true,
          },
        },
        ...draftCarts,
      ]
      // console.log('saving new cart', JSON.stringify(draftCarts))
      this.setState({
        draftCarts: newDraftCarts,
        cart2: {},
      })
      this.updateDraftCarts(newDraftCarts)
    }
  }

  replaceCart(cart) {
    this.setState({ cart2: cart })
  }

  addNewCartDraftCarts(cart) {
    const { draftCarts } = this.state
    console.log('add new cart to draft cart')
    const newDraftCarts = [cart, ...draftCarts]
    this.setState({ draftCarts: newDraftCarts })
    this.updateDraftCarts(newDraftCarts)
    // window.scrollTo(0, this.myRef.offsetTop)
  }

  removeCart(cart) {
    console.log('remove draft cart', cart)
    const { draftCarts } = this.state
    const newDraftCarts = draftCarts.filter(
      (draft) => JSON.stringify(draft.cart) !== JSON.stringify(cart)
    )
    console.log('new draft carts', newDraftCarts)
    this.setState({ draftCarts: newDraftCarts })
    this.updateDraftCarts(newDraftCarts)
  }

  restoreCart(cart, meta) {
    console.log('restore a draft cart')
    // console.log(cart.comments)
    // const newDraftCarts = this.state.draftCarts.filter(draft => draft !== cart)
    this.setState({
      cart2: { ...cart },
      coupon: meta.coupon,
    })
    window.scrollTo(0, this.cartRef.offsetTop)
    // this.updateDraftCarts(newDraftCarts)
  }

  clearCart() {
    this.updateCart({})
    // this.setState({comments: {}})
  }

  removeCartItem(item) {
    // remove item from all cart items
    // TODO
    /*
    let newCart = {}

    if (item && item.tabletopId) {
      // Item was tabletop, so we can't use sku to identify
      newCart = Object.entries(this.state.cart)
        .filter(([k, v]) => v.tabletopId !== item.tabletopId)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
    } else {
      newCart = Object.entries(this.state.cart)
        .filter(([k, v]) =>
          v.sku === item.sku && v.cart_section === item.cart_section
            ? v.custom_size !== item.custom_size
            : true
        )
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
    }
    */

    const { cart2: cart } = this.state

    const sectionItems = cart[item.cart_section]
    let newSectionItems
    let newCart
    if (item && item.tabletopId) {
      newSectionItems = sectionItems.filter(
        (sectionItem) => sectionItem.tabletopId !== item.tabletopId
      )
    } else {
      newSectionItems = sectionItems.filter(
        (sectionItem) =>
          sectionItem.sku !== item.sku ||
          sectionItem.custom_size !== item.custom_size
      )
    }

    if (newSectionItems && newSectionItems.length) {
      newCart = { ...cart, [item.cart_section]: newSectionItems }
    } else {
      // Remove cart section if it's empty
      newCart = { ...cart }
      delete newCart[item.cart_section]
    }

    this.updateCart(newCart)

    // remove from comments this item's comment
    // const comments = {...this.state.comments, [item.sku]: ''}
    // this.setState({comments})
  }

  /*
  calculateCustomSidePrice(sku, custom_size) {
    // look up these 2 variables using sku
    const product = customPrice.find((p) => p.sku === sku)
    const minPrice =
      product && product['min_custom_size_price'] !== 'DEFAULT'
        ? product['min_custom_size_price']
        : 60
    const squareMeterPrice =
      product && product['square_meter_price'] !== 'DEFAULT'
        ? product['square_meter_price']
        : 220

    const size = custom_size
      .split('x')
      .map((size) => parseFloat(size) / 100)
      .reduce((acc, c) => acc * c, 1)
    const price = Math.round(size * squareMeterPrice)
    // console.log(sku, size, price)
    return price > minPrice ? price : minPrice
  }
  */

  handleOpen () {
    this.setState({ modal: true })
  }
  handleClose () {
    this.setState({ modal: false })
  }

  getProductPrice({
    sku,
    custom_size: customSize,
    custom_price: customPrice2,
  }) {
    try {
      const { variants: shopifyProducts } = this.props
      if (customPrice2) return customPrice2
      const productFromShopify = shopifyProducts.find(
        (product) => product.sku === sku
      )
      return productFromShopify.cabinet_height === 'custom'
        ? Matcher.calculateCustomSidePriceShopify(
            productFromShopify,
            customSize
          )
        : productFromShopify.price
    } catch (err) {
      console.log(err)
      alert(
        'Selected product not found. The product might have been deleted during product updates. Please refresh matches using button on the right side of saved matches!'
      )
      return null
    }
  }

  saveSentCartToDraftCarts() {
    const { draftCarts, cart2: cart } = this.state
    const dateCreated = cart['createdAt'] || moment()
    const draftColors = this.getDraftCartColors()
    const draftModels = this.getDraftCartModels()
    const newCart = {
      cart: { ...cart },
      meta: {
        isSent: true,
        createdAt: dateCreated,
        colors: draftColors,
        models: draftModels,
        isNewFormat: true,
      },
    }
    const restOfDraftCarts =
      draftCarts &&
      draftCarts
        .filter((draft) => JSON.stringify(draft) !== JSON.stringify(cart))
        .map((draft) => ({ ...draft, isSent: false }))
    const newDraftCarts = [newCart, ...restOfDraftCarts]
    this.setState({ draftCarts: newDraftCarts, cart2: {} })
    this.updateDraftCarts(newDraftCarts)
  }

  handlePdfsChange(item, pdf) {
    const { cart2, selectedMinicartCategory } = this.state
    const cartSection = selectedMinicartCategory
    const sectionItems = cart2 && cart2[cartSection]
    const newItem = { ...item, pdf }
    const newSectionItems = sectionItems.map((cartItem) =>
      cartItem.sku === item.sku &&
      cartItem.custom_size === item.custom_size &&
      cartItem.tabletopId === item.tabletopId
        ? newItem
        : cartItem
    )
    this.setState({
      cart2: { ...cart2, [cartSection]: newSectionItems },
    })
  }

  componentDidMount() {
    const { match } = this.props
    const { id } = match.params
    this.setState({ isLoading: true })
    getAuthenticatedUser()
      .then((user) => {
        return fetch(`/api/ikea/pdf/upload/${id}`, {
          method: 'GET',
          headers: { Authorization: `jwt ${user.accessToken.jwtToken}` },
        })
      })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw new Error('Something went wrong ...')
      })
      .then((data) => {
        this.loadData(data)
      })
      .catch((error) => this.setState({ error, isLoading: false }))
  }

  handleCouponChange(event) {
    this.setState({ coupon: event.target.value })
  }

  render() {
    const { products, variants } = this.props

    const {
      data,
      filters,
      matches,
      aitoMatches,
      showTableTops,
      showDraftCarts,
      cart,
      cart2,
      pdfs,
      modal
    } = this.state

    const email = R.path(['email'], data)
    const id = R.path(['id'], data)
    const color = R.path(['color'], filters[1])
    const door = R.path(['door_type'], filters[0])
    const { filterProducts: filterOn } = this.state

    // This is addition to old logic: Use initial matches from
    // json data to avoid fetching all the matches every time
    // matcher is opened.
    const { showInitialProducts, initialProducts } = this.state

    const draftCarts = this.state.draftCarts && (
      <Draftcarts
        draftcarts={this.state.draftCarts}
        aitoMatches={aitoMatches}
        upload={data}
        email={email}
        removeCart={this.removeCart}
        restoreCart={this.restoreCart}
      />
    )

    return (
      <Paper style={{ marginLeft: '2vw', height: '100%' }}>
        <div className="matcher">
          <Card raised={false} style={{ width: '100%' }}>
            <PageHeader title="Product matcher" />
            <CardContent>
              <MatcherMeta
                item={data}
                handleConvertToPaos={this.handleConvertToPaos}
              />
            </CardContent>
            <div className="left">
              <div>
                <Button style={{ marginLeft: '16px' }} type="button" variant="contained" onClick={this.handleOpen}>
                  Show Details
                </Button>
                <Modal
                    style={{display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                }}
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={modal}
                    onClose={this.handleClose}
                    closeAfterTransition
                >
                  <div className="modal-list" style={{ border: '2px solid rgb(199, 190, 184)', padding: '40px',
                    'box-shadow':' 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
                    'background-color': '#fff', color: '#000', position: 'relative', maxWidth: '800px', cursor: 'pointer'
                  }}>
                    <div onClick={this.handleClose} style={{ position: 'absolute', top: '10px', right: '10px' }}><ClearIcon /></div>
                    {!data.note || !Object.keys(data.note).length ? (
                        <div>
                          <Alert style={{ fontSize: '16px' }} severity="warning">There is no data about the note</Alert>
                        </div>
                    ) : (
                        <div>
                          <List style={{ border: '1px solid' }} component="nav" aria-label="mailbox folders">
                            { Object.keys(data.note).map((key => (
                                <ListItem>
                                  <span style={{'fontWeight': 700, minWidth: '150px'}}>{key.toUpperCase().replace('_', ' ')}</span>
                                    <div style={{ whiteSpace: "pre-wrap", borderLeft: '1px solid', paddingLeft: '10px' }}>{ typeof data.note[key] !== 'string' ? data.note[key].toString() : data.note[key].replace('_', ' ') }</div>
                                </ListItem>
                                )))
                            }
                          </List>
                        </div>
                    )}
                  </div>
                </Modal>
              </div>
              <CardContent>
                <CartCategorySelect
                  handleMinicartCategoryChange={
                    this.handleMinicartCategoryChange
                  }
                />
              </CardContent>
              {this.state.displayMatches !== 'none' && (
                <CardContent>
                  <MatcherProduct
                    rows={this.state.selectedRows}
                    style={{ display: this.state.displayMatches }}
                    selectMatch={this.selectMatch}
                    closeMatchSelect={this.closeMatchSelect}
                    openMatchSelect={this.openMatchSelect}
                    currentMatch={this.state.currentMatch}
                    original={this.state.currentProduct}
                    product={this.state.currentProduct}
                    cart={cart}
                    door={door}
                    color={color}
                  />
                </CardContent>
              )}
              {!showInitialProducts && (
                <CardContent>
                  <div style={{ display: this.state.displayProducts }}>
                    <Paper
                      elevation={5}
                      style={{ padding: '10px', marginBottom: '20px' }}
                    >
                      <Card>
                        <CardHeader title="Products of the IKEA Plan" />
                        <div style={{ display: 'block' }}>
                          <MatcherProducts
                            products={products}
                            addItemToCart={this.addItemToCart}
                            isInitial={false}
                            filters={filters}
                            cart={cart}
                            cart2={this.state.cart2}
                            aitoMatches={aitoMatches}
                            updateCart={this.updateCart}
                            updateAitoMatches={this.updateAitoMatches}
                            openMatchSelect={this.openMatchSelect}
                            matches={matches}
                            filterOn={filterOn}
                            selectedMinicartCategory={
                              this.state.selectedMinicartCategory
                            }
                          />
                        </div>
                      </Card>
                    </Paper>
                  </div>
                </CardContent>
              )}
              {showInitialProducts && (
                <CardContent>
                  <div style={{ display: this.state.displayProducts }}>
                    <Paper
                      elevation={5}
                      style={{ padding: '10px', marginBottom: '20px' }}
                    >
                      <Card>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <CardHeader title="Products of the IKEA Plan - Saved matches" />
                          <div
                            style={{
                              alignSelf: 'center',
                              padding: '10px',
                            }}
                          >
                            <ClearFab
                              aria-label="clear"
                              size="small"
                              onClick={this.handleFetchAllMatchesAgain}
                              disabled={this.state.isLoading}
                            >
                              <RefreshIcon fontSize="large" />
                            </ClearFab>
                          </div>
                        </div>
                        <div style={{ display: 'block' }}>
                          {this.state.isLoading ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '10px',
                              }}
                            >
                              <p>Loading matches...</p>
                              <CircularProgress
                                disableShrink
                                color="secondary"
                              />
                            </div>
                          ) : (
                            <MatcherProducts
                              products={initialProducts}
                              isInitial
                              filters={filters}
                              cart={cart}
                              cart2={this.state.cart2}
                              aitoMatches={aitoMatches}
                              updateCart={this.updateCart}
                              addItemToCart={this.addItemToCart}
                              updateAitoMatches={this.updateAitoMatches}
                              openMatchSelect={this.openMatchSelect}
                              matches={matches}
                              filterOn={filterOn}
                              selectedMinicartCategory={
                                this.state.selectedMinicartCategory
                              }
                            />
                          )}
                        </div>
                      </Card>
                    </Paper>
                  </div>
                </CardContent>
              )}
              <CardContent>
                <div style={{ display: this.state.displayProducts }}>
                  <Paper
                    elevation={5}
                    style={{ padding: '10px', marginBottom: '20px' }}
                  >
                    <Accessories
                      cart={cart2}
                      updateCart={this.updateCart}
                      isSwitch={false}
                      selectedMinicartCategory={
                        this.state.selectedMinicartCategory
                      }
                      sinkmodel={data && data.sinkmodel}
                      tapmodel={data && data.tapmodel}
                      handlemodel={data && data.handlemodel}
                      shopifyProducts={variants}
                      addItemToCart={this.addItemToCart}
                    />
                  </Paper>
                </div>
              </CardContent>
              <CardContent>
                <div style={{ display: this.state.displayProducts }}>
                  <Paper
                    elevation={5}
                    style={{ padding: '10px', marginBottom: '20px' }}
                  >
                    <Card>
                      <CardHeader
                        title="Tabletops"
                        action={
                          <Switch
                            color="primary"
                            checked={showTableTops}
                            onChange={(_) =>
                              this.setState({ showTableTops: !showTableTops })
                            }
                            // value="checkedA"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        }
                      />
                      <div
                        style={{
                          display: showTableTops ? 'block' : 'none',
                        }}
                      >
                        <TableTopSection
                          data={this.state.data}
                          cart={this.state.cart2}
                          updateCart={this.updateCart}
                          updateTableTopPdfData={this.updateTableTopPdfData}
                          selectedMinicartCategory={
                            this.state.selectedMinicartCategory
                          }
                        />
                      </div>
                    </Card>
                  </Paper>
                </div>
              </CardContent>
              <CardContent>
                <div
                  style={{
                    display: this.state.displayProducts,
                  }}
                >
                  <Paper elevation={5}>
                    <Card style={{ padding: '10px' }}>
                      <div
                        style={{
                          display: 'block',
                        }}
                      >
                        <CustomItems
                          door={door}
                          color={color}
                          cart={cart}
                          shopifyProducts={variants}
                          updateCart={this.updateCart}
                          addItemToCart={this.addItemToCart}
                          openMatchSelect={this.openMatchSelect}
                          selectMatch={this.selectMatch}
                          matches={matches}
                          filterOn={filterOn}
                          isSwitch={false}
                          selectedMinicartCategory={
                            this.state.selectedMinicartCategory
                          }
                        />
                      </div>
                    </Card>
                  </Paper>
                </div>
              </CardContent>
              <div ref={(ref) => (this.draftCartsRef = ref)} />
              {/* <CardContent style={{height: 200}}> */}

              {!R.isEmpty(this.state.draftCarts) && (
                <CardContent style={{ marginTop: 10 }}>
                  <CardHeader
                    title={
                      <div>
                        Draft Carts{' '}
                        <ShopTwoIcon color="secondary" fontSize="large" />
                      </div>
                    }
                  />
                  {/* <DraftCartsContainerWithRouter /> */}
                  <div
                    className="draftcarts"
                    style={{ display: showDraftCarts ? 'block' : 'none' }}
                  >
                    {draftCarts}
                  </div>
                </CardContent>
              )}
            </div>
            <div className="right">
              <div ref={(ref) => (this.cartRef = ref)} />
              {/* <CardContent style={{position: 'sticky', top: 0}}> */}
              <CardContent style={{ marginRight: 15 }}>
                <Cart
                  id={id}
                  shopifyDraftId={this.state.shopifyDraftId}
                  cart={cart}
                  cart2={this.state.cart2}
                  aitoMatches={aitoMatches}
                  upload={data}
                  email={email}
                  saveSentCartToDraftCarts={this.saveSentCartToDraftCarts}
                  saveCart={this.saveCart}
                  replaceCart={this.replaceCart}
                  getProductPrice={this.getProductPrice}
                  clearCart={this.clearCart}
                  updateCart={this.updateCart}
                  removeCartItem={this.removeCartItem}
                  handleCommentChange={this.handleCommentChange}
                  pdfs={pdfs}
                  handlePdfsChange={this.handlePdfsChange}
                  isDraft={false}
                  firstname={
                    data.hasOwnProperty('firstname') ? data.firstname : ''
                  }
                  lastname={
                    data.hasOwnProperty('lastname') ? data.lastname : ''
                  }
                  country={data.hasOwnProperty('country') ? data.country : ''}
                  store={data.hasOwnProperty('store') ? data.store : ''}
                  zipcode={data.hasOwnProperty('zipcode') ? data.zipcode : ''}
                  tableTopPdfData={this.state.tableTopPdfData}
                  handleQuantityChange={this.handleQtyChange}
                  handleCouponChange={this.handleCouponChange}
                  coupon={this.state.coupon}
                  handleColorChange={this.handleColorChange}
                  handleDiscountChange={this.handleDiscountChange}
                  shopifyProducts={variants}
                />
              </CardContent>
            </div>
          </Card>
        </div>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  uploadPDF: (data, mode, filters) => dispatch(uploadPDF(data, mode, filters)),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(Matcher)
)
