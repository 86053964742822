/* eslint-disable no-console */
/* eslint-disable no-alert */
import React, { Component } from 'react'
import * as R from 'ramda'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import Fab from '@material-ui/core/Fab'
import RefreshIcon from '@material-ui/icons/Refresh'
import SaveIcon from '@material-ui/icons/Save'
import SendIcon from '@material-ui/icons/Send'
import { styled } from '@material-ui/styles'
import EmailIcon from '@material-ui/icons/Email'
import InputAdornment from '@material-ui/core/InputAdornment'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Tooltip from '@material-ui/core/Tooltip'
import { getProductShortName } from '../../utils/helpers.js'
import CartItems from './CartItems'
import { postNewCart } from '../../api/server.js'
import minicartCategories from '../../minicart-options.json'
import CartGrandTotal from './CartGrandTotal'
import { Typography } from '@material-ui/core'
import CartSendingDialog from './CartSendingDialog'
import memoizeOne from 'memoize-one'

const ClearFab = styled(Fab)({
  backgroundColor: '#c7beb8',
  color: '#fafafa',
  '&:hover': {
    backgroundColor: '#e10050',
  },
})

const SaveFab = styled(Fab)({
  backgroundColor: '#c7beb8',
  color: '#fafafa',
  '&:hover': {
    backgroundColor: 'rgb(2, 136, 209)',
  },
})

const SendFab = styled(Fab)({
  backgroundColor: '#c7beb8',
  color: '#fafafa',
  '&:hover': {
    backgroundColor: '#4caf50',
  },
})

const getWidthOptions = (shopifyProducts) => {
  // width options
  return (
    shopifyProducts &&
    shopifyProducts
      .reduce(
        (acc, c) =>
          acc.includes(c.cabinet_width) || c.cabinet_width === 'Custom'
            ? acc
            : [...acc, c.cabinet_width],
        []
      )
      .sort((a, b) => parseInt(a) - parseInt(b))
  )
  // console.log(widthOptions, widthOptions.length)
}

const getHeightOptions = (shopifyProducts) => {
  return (
    shopifyProducts &&
    shopifyProducts
      .reduce(
        (acc, c) =>
          acc.includes(c.cabinet_height) || c.cabinet_height === 'Custom'
            ? acc
            : [...acc, c.cabinet_height],
        []
      )
      .sort((a, b) => parseInt(a) - parseInt(b))
  )
}

const getColorOptions = (shopifyProducts) => {
  return (
    shopifyProducts &&
    shopifyProducts
      .reduce((acc, c) => (acc.includes(c.color) ? acc : [...acc, c.color]), [])
      .sort()
  )
}

class Cart extends Component {
  constructor(props) {
    super(props)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleColorChange = this.handleColorChange.bind(this)
    this.handleDoorTypeChange = this.handleDoorTypeChange.bind(this)
    this.sendCart = this.sendCart.bind(this)
    this.handleWidthChange = this.handleWidthChange.bind(this)
    this.handleHeightChange = this.handleHeightChange.bind(this)
    this.handleCheckedChange = this.handleCheckedChange.bind(this)
    this.handleCheckAllChange = this.handleCheckAllChange.bind(this)
    this.findProductBySku = this.findProductBySku.bind(this)
    this.getMinicartCategoryName = this.getMinicartCategoryName.bind(this)
    this.initCheckedMap = this.initCheckedMap.bind(this)

    this.state = {
      email: null,
      color: '',
      doorType: '',
      width: '',
      height: '',
      shippingMethod: {},
      checkedItems: {},
      updateFailed: [],
      loading: false,
      cartSendingError: '',
      isAlert: false,
    }
  }

  componentDidMount() {
    const selectedItemsMap = this.initCheckedMap()
    this.setState({ checkedItems: selectedItemsMap })
  }

  findProductBySku(sku) {
    return this.props.shopifyProducts.find((product) => product.sku === sku)
  }

  handleCheckedChange(event, cartSection) {
    // TODO: Add categories to checked data
    const { checkedItems } = this.state
    const { cart2: cart } = this.props

    const sectionItems = cart[cartSection]
    const sectionCheckedItems = checkedItems[cartSection]

    if (
      !sectionCheckedItems ||
      !sectionCheckedItems.includes(event.target.name)
    ) {
      // Set checkbox as checked
      const newSectionCheckedItems = [...sectionCheckedItems, event.target.name]
      this.setState({
        checkedItems: {
          ...this.state.checkedItems,
          [cartSection]: newSectionCheckedItems,
        },
      })

      // Find based on sku and section or combined sku+size+section
      const selectedItem = sectionItems.find(
        (item) =>
          item.sku + item.cart_section === event.target.name ||
          item.sku + item.custom_size + item.cart_section === event.target.name
      )
      const selectedHelsingoProduct = this.findProductBySku(selectedItem.sku)
      console.log('helsingo', selectedHelsingoProduct)
      const selectedItemsColor =
        selectedItem['custom_color'] || selectedItem['color']
      const selectedItemWidth = selectedHelsingoProduct['cabinet_width']
      const selectedItemHeight = selectedHelsingoProduct['cabinet_height']
      const selectedItemModel = selectedHelsingoProduct['door_type']
      this.setState({
        color: selectedItemsColor,
        width: selectedItemWidth,
        height: selectedItemHeight,
        doorType: selectedItemModel,
      })
    } else {
      // Filter checkbox out from checked array
      const newArr = checkedItems[cartSection].filter(
        (item) => item !== event.target.name
      )
      this.setState({
        checkedItems: { ...this.state.checkedItems, [cartSection]: newArr },
      })
    }
  }

  handleCheckAllChange(event, cartSection) {
    const { checkedItems } = this.state
    const { cart2: cart } = this.props

    const sectionCheckedItems = checkedItems[cartSection]
    if (
      cart[cartSection] &&
      sectionCheckedItems.length === cart[cartSection].length
    ) {
      // Remove all checked items from cart section and clear filters
      this.setState({
        checkedItems: { ...this.state.checkedItems, [cartSection]: [] },
        color: '',
        width: '',
        height: '',
        doorType: '',
      })
    } else {
      // Set all items as checked in cart section and clear filters

      const allKeys = cart[cartSection].map((item) =>
        item.product.includes('CustomxCustom')
          ? item.sku + item.custom_size + item.cart_section
          : item.sku + item.cart_section
      )
      this.setState({
        checkedItems: { ...this.state.checkedItems, [cartSection]: allKeys },
        color: '',
        width: '',
        height: '',
        doorType: '',
      })
    }
  }

  handleEmailChange(event) {
    // TODO: Do we need to update this to shopify?
    this.setState({ email: event.target.value })
  }

  handleColorChange(event) {
    const newColor = event.target.value
    const currentColor = this.state.color
    const { shopifyProducts, cart2: cart } = this.props

    const getNewProduct = (sku) => {
      const currentProduct = this.findProductBySku(sku)
      console.log('current product', currentProduct)
      return currentProduct
        ? shopifyProducts.find(
            (product) =>
              product.door_type === currentProduct.door_type &&
              product.color === newColor &&
              product.cabinet_height === currentProduct.cabinet_height &&
              product.cabinet_width === currentProduct.cabinet_width &&
              product.product_type === currentProduct.product_type
          )
        : null
    }

    const getNewItem = (item) => {
      const newProduct = getNewProduct(item.sku)
      return newProduct
        ? {
            ...item,
            product: newProduct.parent_product_title,
            sku: newProduct.sku,
            custom_color: newColor,
            unit_price: this.props.getProductPrice({
              sku: newProduct.sku
            })
          }
        : null
    }

    if (newColor !== currentColor) {
      const updateFailedNew = []
      const { checkedItems } = this.state
      console.log('cart in change color', cart)

      const newCart = Object.keys(cart).reduce((acc, cartSection) => {
        const newSectionItems = cart[cartSection].map((item) => {
          if (
            checkedItems[cartSection].includes(item.sku + item.cart_section) ||
            checkedItems[cartSection].includes(
              item.sku + item.custom_size + item.cart_section
            )
          ) {
            const newItem = getNewItem(item)
            if (!newItem) {
              // Keep track on failed updates
              updateFailedNew.push(item.sku)
            }
            return newItem ? newItem : item
          } else {
            return item
          }
        })
        return { ...acc, [cartSection]: newSectionItems }
      }, {})

      this.setState({
        updateFailed: updateFailedNew,
        checkedItems: this.initCheckedMap(),
      })
      this.props.replaceCart(newCart)
    }
  }

  handleDoorTypeChange(event) {
    const newDoorType = event.target.value
    const currentDoorType = this.state.doorType

    const getNewProduct = (sku) => {
      const { shopifyProducts, cart2: cart } = this.props
      const currentProduct = this.findProductBySku(sku)
      return currentProduct
        ? shopifyProducts.find(
            (product) =>
              product.door_type === newDoorType &&
              product.color === currentProduct.color &&
              product.cabinet_height === currentProduct.cabinet_height &&
              product.cabinet_width === currentProduct.cabinet_width &&
              product.product_type === currentProduct.product_type
          )
        : null
    }

    const getNewItem = (item) => {
      const newProduct = getNewProduct(item.sku)
      // const newName = getProductShortName(newProduct)
      return newProduct
        ? {
            ...item,
            product: newProduct.parent_product_title,
            sku: newProduct.sku,
            unit_price: this.props.getProductPrice({
              sku: newProduct.sku
            })
          }
        : null
    }

    if (newDoorType !== currentDoorType) {
      const updateFailedNew = []
      const { checkedItems } = this.state
      const { cart2: cart } = this.props
      const newCart = Object.keys(cart).reduce((acc, cartSection) => {
        const newSectionItems = cart[cartSection].map((item) => {
          if (
            checkedItems[cartSection].includes(item.sku + item.cart_section) ||
            checkedItems[cartSection].includes(
              item.sku + item.custom_size + item.cart_section
            )
          ) {
            const newItem = getNewItem(item)
            if (!newItem) {
              // Keep track on failed updates
              updateFailedNew.push(item.sku)
            }
            return newItem ? newItem : item
          } else {
            return item
          }
        })
        return { ...acc, [cartSection]: newSectionItems }
      }, {})

      this.setState({
        updateFailed: updateFailedNew,
        checkedItems: this.initCheckedMap(),
      })
      this.props.replaceCart(newCart)
    }
  }

  handleWidthChange(event) {
    const newWidth = event.target.value
    const currentWidth = this.state.width

    const getNewProduct = (sku) => {
      const { shopifyProducts } = this.props
      const currentProduct = this.findProductBySku(sku)
      return currentProduct
        ? shopifyProducts.find(
            (product) =>
              product.door_type === currentProduct.door_type &&
              product.color === currentProduct.color &&
              product.cabinet_height === currentProduct.cabinet_height &&
              product.cabinet_width === newWidth &&
              product.product_type === currentProduct.product_type
          )
        : null
    }

    const getNewItem = (item) => {
      const newProduct = getNewProduct(item.sku)
      // const newName = getProductShortName(newProduct)
      console.log(newProduct)
      return newProduct
        ? {
            ...item,
            product: newProduct.parent_product_title,
            variant_id: newProduct.id,
            sku: newProduct.sku,
            unit_price: this.props.getProductPrice({
              sku: newProduct.sku
            })
          }
        : null
    }

    if (newWidth !== currentWidth) {
      const updateFailedNew = []
      const { checkedItems } = this.state
      const { cart2: cart } = this.props
      const newCart = Object.keys(cart).reduce((acc, cartSection) => {
        const newSectionItems = cart[cartSection].map((item) => {
          if (
            checkedItems[cartSection].includes(item.sku + item.cart_section) ||
            checkedItems[cartSection].includes(
              item.sku + item.custom_size + item.cart_section
            )
          ) {
            const newItem = getNewItem(item)
            if (!newItem) {
              // Keep track on failed updates
              updateFailedNew.push(item.sku)
            }
            return newItem ? newItem : item
          } else {
            return item
          }
        })
        return { ...acc, [cartSection]: newSectionItems }
      }, {})
      this.setState({
        updateFailed: updateFailedNew,
        checkedItems: this.initCheckedMap(),
      })
      this.props.replaceCart(newCart)
    }
  }

  handleHeightChange(event) {
    const newHeight = event.target.value
    const currentHeight = this.state.height

    const getNewProduct = (sku) => {
      const { shopifyProducts } = this.props
      const currentProduct = this.findProductBySku(sku)
      return currentProduct
        ? shopifyProducts.find(
            (product) =>
              product.door_type === currentProduct.door_type &&
              product.color === currentProduct.color &&
              product.cabinet_height === newHeight &&
              product.cabinet_width === currentProduct.cabinet_width &&
              product.product_type === currentProduct.product_type
          )
        : null
    }

    const getNewItem = (item) => {
      const newProduct = getNewProduct(item.sku)
      // const newName = getProductShortName(newProduct)
      console.log(newProduct)
      return newProduct
        ? {
            ...item,
            product: newProduct.parent_product_title,
            sku: newProduct.sku,
            unit_price: this.props.getProductPrice({
              sku: newProduct.sku
            })
          }
        : null
    }

    if (newHeight !== currentHeight) {
      const updateFailedNew = []
      const { checkedItems } = this.state
      const { cart2: cart } = this.props
      const newCart = Object.keys(cart).reduce((acc, cartSection) => {
        const newSectionItems = cart[cartSection].map((item) => {
          if (
            checkedItems[cartSection].includes(item.sku + item.cart_section) ||
            checkedItems[cartSection].includes(
              item.sku + item.custom_size + item.cart_section
            )
          ) {
            const newItem = getNewItem(item)
            if (!newItem) {
              // Keep track on failed updates
              updateFailedNew.push(item.sku)
            }
            return newItem ? newItem : item
          } else {
            return item
          }
        })
        return { ...acc, [cartSection]: newSectionItems }
      }, {})

      this.setState({
        updateFailed: updateFailedNew,
        checkedItems: this.initCheckedMap(),
      })
      this.props.replaceCart(newCart)
    }
  }

  sendCart(cart, email) {
    console.log('sending cart')
    this.setState({ loading: true })
    if (email !== '' && !R.isEmpty(cart)) {
      const data = {
        email,
        ticket_no: this.props.id,
        items: cart,
        discount_code: this.props.coupon,
        firstname: this.props.firstname,
        lastname: this.props.lastname,
        country: this.props.country,
        store: this.props.store,
        zipcode: this.props.zipcode,
      }
      // console.log(data)
      // data['items'] = cart
      if (!R.isEmpty(this.state.shippingMethod))
        data.shipping_method_id = this.state.shippingMethod.id
      postNewCart(
        data,
        Object.values(this.props.aitoMatches),
        this.props.upload,
        this.props.id,
        this.props.shopifyDraftId
      ).then((data) => {
        if (data['status'] === 'ok') {
          this.props.saveSentCartToDraftCarts()
          this.setState({ email: null, loading: false })
          // this.setState({comments: {}})
          // !this.props.isDraft && this.props.updateDraftCarts(this.props.cart)
          console.log('Cart was successfully sent.')
        } else {
          // alert(data['message'])
          this.setState({ isAlert: true, cartSendingError: data['message'] })
        }
      })
    } else {
      // alert('Please give the email address of the customer.')
      this.setState({
        isAlert: true,
        cartSendingError: 'Please give the email address of the customer.',
      })
    }
  }

  initCheckedMap() {
    return minicartCategories
      .map((category) => category.value)
      .reduce(
        (acc, current) => {
          return { ...acc, [current]: [] }
        },
        { undefined: [] }
      )
  }

  getMinicartCategoryName(category) {
    const option = minicartCategories.find(
      (item) => item.value.toString() === category.toString()
    )
    return option ? option.label : 'No category'
  }

  render() {
    const doorTypeOptions = ['ENSIO', 'SAMSO', 'INGARO', '']
    const { shippingMethod } = this.state
    const { isDraft, shopifyProducts, cart2, draftMeta } = this.props
    console.log('cart2', cart2)

    const memoGetcolorOptions = memoizeOne(getColorOptions)
    const memoGetwidthOptions = memoizeOne(getWidthOptions)
    const memoGetheightOptions = memoizeOne(getHeightOptions)

    const colorOptions = memoGetcolorOptions(shopifyProducts)
    const widthOptions = memoGetwidthOptions(shopifyProducts)
    const heightOptions = memoGetheightOptions(shopifyProducts)

    // These are in correct format to be sent to magento
    const cartItemsToBeSent =
      cart2 &&
      Object.values(cart2) &&
      Object.values(cart2).reduce((acc, itemArr) => {
        return [...acc, ...itemArr]
      }, [])

    console.log('cartItemsToBeSent', cartItemsToBeSent)

    const email =
      this.state.email === null ? this.props.email : this.state.email

    return !isDraft ? (
      <Paper style={{ width: '46vw' }} elevation={5}>
        <Card
          raised={false}
          style={{
            backgroundColor: cart2.isSent ? '#e0e0e0' : 'white',
            borderRadius: 'unset',
            overflowX: 'hidden',
            marginRight: 10,
          }}
        >
          <CardHeader
            // style={{textAlign: 'center'}}
            title={
              <div style={{ position: 'relative' }}>
                <ShoppingCartIcon
                  style={{
                    color: 'rgb(199, 190, 184)',
                    width: '70px',
                    height: '70px',
                  }}
                />
                <TextField
                  variant="outlined"
                  style={{ marginTop: 5, marginLeft: 10, width: '20vw' }}
                  id="email"
                  type="email"
                  value={email}
                  onChange={this.handleEmailChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon color="secondary" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Tooltip title="Send cart">
                  <div>
                    <SendFab
                      disabled={R.isEmpty(cart2) || email === ''}
                      aria-label="send"
                      onClick={() => this.sendCart(cartItemsToBeSent, email)}
                      style={{ position: 'absolute', top: 5, right: 150 }}
                    >
                      <SendIcon fontSize="large" />
                    </SendFab>
                    <CartSendingDialog
                      open={this.state.loading}
                      onClose={() =>
                        this.setState({
                          loading: false,
                          isAlert: false,
                          cartSendingError: '',
                        })
                      }
                      isAlert={this.state.isAlert}
                      errorMessage={this.state.cartSendingError}
                    />
                  </div>
                </Tooltip>
                <Tooltip title="Clear cart">
                  <div>
                    <ClearFab
                      disabled={R.isEmpty(cart2)}
                      aria-label="clear"
                      // size="small"
                      onClick={this.props.clearCart}
                      style={{ position: 'absolute', top: 5, right: 0 }}
                    >
                      <RefreshIcon fontSize="large" />
                    </ClearFab>
                  </div>
                </Tooltip>
                <Tooltip title="Save cart to Draftcarts">
                  <div>
                    <SaveFab
                      disabled={R.isEmpty(cart2)}
                      aria-label="save"
                      // size="small"
                      style={{ position: 'absolute', top: 5, right: 70 }}
                      onClick={this.props.saveCart}
                    >
                      <SaveIcon fontSize="large" />
                    </SaveFab>
                  </div>
                </Tooltip>
              </div>
            }
          />
          <div>
            <CardContent>
              {!R.isEmpty(cart2) &&
                Object.entries(cart2).map(([category, items]) => (
                  <div key={category}>
                    <Typography variant="subtitle1">
                      {this.getMinicartCategoryName(category)}
                    </Typography>

                    <CartItems
                      items={items}
                      handleCommentChange={
                        this.props.isDraft
                          ? null
                          : this.props.handleCommentChange
                      }
                      handlePdfsChange={
                        this.props.isDraft ? null : this.props.handlePdfsChange
                      }
                      handleQuantityChange={
                        this.props.isDraft
                          ? null
                          : this.props.handleQuantityChange
                      }
                      isDraft={this.props.isDraft}
                      removeCartItem={this.props.removeCartItem}
                      shippingMethod={shippingMethod}
                      tableTopPdfData={this.props.tableTopPdfData}
                      coupon={this.props.coupon}
                      handleCouponChange={this.props.handleCouponChange}
                      checkedItems={this.state.checkedItems[category]}
                      handleCheckedChange={this.handleCheckedChange}
                      handleCheckAllChange={(e) =>
                        this.handleCheckAllChange(e, category)
                      }
                      updateFailed={this.state.updateFailed}
                      handleDiscountChange={this.props.handleDiscountChange}
                    />
                  </div>
                ))}
              {!R.isEmpty(cart2) && (
                <CartGrandTotal
                  cartItems={cartItemsToBeSent}
                  shippingMethod={shippingMethod}
                  coupon={this.props.coupon}
                  handleCouponChange={this.props.handleCouponChange}
                />
              )}
            </CardContent>

            <CardContent>
              <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                {!R.isEmpty(cart2) && (
                  <TextField
                    select
                    label="Color"
                    variant="outlined"
                    margin="dense"
                    value={this.state.color}
                    onChange={this.handleColorChange}
                    SelectProps={{
                      native: true,
                    }}
                    style={{ marginRight: 10, minWidth: '210px' }}
                  >
                    {colorOptions.map((color) => (
                      <option key={color} value={color}>
                        {color}
                      </option>
                    ))}
                  </TextField>
                )}
                {!R.isEmpty(cart2) && (
                  <TextField
                    select
                    variant="outlined"
                    margin="dense"
                    label="Model"
                    value={this.state.doorType}
                    onChange={this.handleDoorTypeChange}
                    SelectProps={{
                      native: true,
                    }}
                    style={{ marginRight: 10, minWidth: '100px' }}
                  >
                    {doorTypeOptions.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </TextField>
                )}
                {!R.isEmpty(cart2) && (
                  <TextField
                    select
                    variant="outlined"
                    label="Width"
                    margin="dense"
                    value={this.state.width}
                    onChange={this.handleWidthChange}
                    style={{ marginRight: 10, minWidth: '100px' }}
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {widthOptions.map((width) => (
                      <option key={width} value={width}>
                        {width}
                      </option>
                    ))}
                  </TextField>
                )}
                {!R.isEmpty(cart2) && (
                  <TextField
                    select
                    variant="outlined"
                    label="Height"
                    margin="dense"
                    value={this.state.height}
                    onChange={this.handleHeightChange}
                    style={{ marginRight: 10, minWidth: '100px' }}
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {heightOptions.map((height) => (
                      <option key={height} value={height}>
                        {height}
                      </option>
                    ))}
                  </TextField>
                )}
              </div>
            </CardContent>
          </div>
        </Card>
      </Paper>
    ) : (
      <Tooltip title={draftMeta.isSent ? 'Sent cart' : ''}>
        <TableRow
          style={{
            backgroundColor: draftMeta.isSent
              ? 'rgb(199, 190, 184)'
              : '#fafafa',
          }}
        >
          <TableCell align="center">
            {draftMeta['isSent'] ? (
              <CheckIcon color={draftMeta.isSent ? 'primary' : 'secondary'} />
            ) : (
              ''
            )}
          </TableCell>
          <TableCell>
            {draftMeta['createdAt']
              ? moment(draftMeta['createdAt']).calendar()
              : ''}
          </TableCell>
          <TableCell>{draftMeta['colors']}</TableCell>
          <TableCell>{draftMeta['models']}</TableCell>
          <TableCell align="center">
            {
              <Tooltip title="Restore Cart">
                <IconButton
                  aria-label="restore"
                  onClick={() => this.props.restoreCart(cart2, draftMeta)}
                >
                  <VisibilityIcon
                    color={cart2.isSent ? 'primary' : 'secondary'}
                  />
                </IconButton>
              </Tooltip>
            }
          </TableCell>
          <TableCell align="center">
            <IconButton
              aria-label="remove"
              onClick={() => this.props.removeCart(cart2)}
            >
              <DeleteIcon color={draftMeta.isSent ? 'primary' : 'secondary'} />
            </IconButton>
          </TableCell>
        </TableRow>
      </Tooltip>
    )
  }
}

export default Cart
