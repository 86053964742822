import React, { Component } from 'react'
import Cart from '../../features/home/Cart'
import { Auth } from 'aws-amplify'

const getAuthenticatedUser = async () => {
  const userData = await Auth.currentAuthenticatedUser()
  return userData && userData.signInUserSession
}

class CartContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: '',
      isLoading: false,
      error: null,
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    const id = this.props.match.params.id
    getAuthenticatedUser()
      .then((user) => {
        return fetch('/api/ikea/pdf/cart/' + id, {
          method: 'GET',
          headers: { Authorization: `jwt ${user.accessToken.jwtToken}` },
        })
      })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Something went wrong ...')
        }
      })
      .then((data) =>
        this.setState({ data: JSON.parse(data), isLoading: false })
      )
      .catch((error) => this.setState({ error, isLoading: false }))
  }

  render() {
    const cart = this.state.data
    return <Cart cart={cart} />
  }
}

export default CartContainer
