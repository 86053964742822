import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { getAllProducts } from '../../api/server'

import Matcher from '../../features/home/Matcher'

class MatcherContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      shopifyProducts: [],
      variants: [],
      error: '',
    }

    this.fetchProductData = this.fetchProductData.bind(this)
  }

  componentDidMount() {
    this.fetchProductData()
  }

  fetchProductData() {
    getAllProducts()
      .then((data) => {
        const mappedData = data.reduce((acc, curr) => {
          const variantsWithProductData = curr.variants.map((variant) => {
            return {
              parent_product_title: curr.title,
              parent_tags: curr.tags,
              product_type: curr.product_type,
              ...variant,
            }
          })
          return [...acc, ...variantsWithProductData]
        }, [])

        // Modify variant data to gather important json key/values for
        // filtering options
        const result = mappedData.map((variant) => {
          const [cabinetWidth, cabinetHeight] = variant.title
            .toLowerCase()
            .includes(' x ')
            ? variant.title.toLowerCase().split(' x ')
            : [undefined, undefined]
          const tagsArr = variant.parent_tags.split(', ')
          const color = tagsArr.find((item) => item.includes('color'))
          const doorType = tagsArr.find((item) => item.includes('door_type'))
          const productCategory = tagsArr.find((item) =>
            item.includes('category')
          )
          return {
            ...variant,
            cabinet_height: cabinetHeight,
            cabinet_width: cabinetWidth,
            color: color ? color.split('__')[1] : undefined,
            door_type: doorType ? doorType.split('__')[1] : undefined,
            product_category: productCategory
              ? productCategory.split('__')[1]
              : undefined,
          }
        })

        this.setState({
          shopifyProducts: data,
          variants: result,
        })
      })
      .catch((error) => this.setState({ error }))
  }

  render() {
    const { shopifyProducts, variants, error } = this.state
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
    return <Matcher products={shopifyProducts} variants={variants} />
  }
}

export default withRouter(MatcherContainer)
