import React from 'react'
import {
  Table,
  TextField,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'
import { styled } from '@material-ui/styles'

const StyledTableRow = styled(TableRow)({
  borderTop: '2px solid #c7beb8',
  borderBottom: '2px solid #c7beb8',
})

const CouponRow = ({ coupon, handleCouponChange }) => (
  <TableRow key={'total'} hover={false}>
    <TableCell
      colSpan={3}
      style={{
        fontSize: 18,
        borderBottom: 'none',
      }}
    >
      <TextField
        variant="outlined"
        label="Coupon"
        margin="dense"
        value={coupon}
        onChange={handleCouponChange}
      />
    </TableCell>
  </TableRow>
)

const GrandTotalRow = ({ total }) => (
  <StyledTableRow key={'total'} hover={false}>
    <TableCell
      colSpan={3}
      style={{
        fontSize: 18,
        textTransform: 'uppercase',
        color: 'rgba(0, 0, 0, 0.54)',
      }}
    >
      Grand Total
    </TableCell>
    <TableCell style={{ fontSize: 18, textAlign: 'center' }}>
      {'€ ' + total.toFixed(2)}
    </TableCell>
  </StyledTableRow>
)

const ShippingCostRow = ({ shippingCost }) => (
  <TableRow key={'shipping'} hover={false}>
    <TableCell
      colSpan={3}
      style={{
        fontSize: 18,
        color: 'rgba(0, 0, 0, 0.54)',
      }}
    >
      Shipping
    </TableCell>
    <TableCell style={{ fontSize: 18, textAlign: 'center' }}>
      {shippingCost ? '€ ' + shippingCost : ''}
    </TableCell>
  </TableRow>
)

const CartGrandTotal = ({
  cartItems,
  shippingMethod,
  coupon,
  handleCouponChange,
}) => {
  const shippingCost = shippingMethod ? 0 : shippingMethod.price
  let totalPrice = cartItems.reduce((acc, current) => {
    const price = current['unit_price']
    const discount = current['discount']
    let rowPrice
    if (discount) {
      const discountFraction = current['discount'] / 100
      const rowPriceAfterDiscount =
        current['qty'] * price - current['qty'] * price * discountFraction
      rowPrice = rowPriceAfterDiscount
    } else {
      rowPrice = price * current['qty']
    }
    return acc + rowPrice
  }, 0)

  totalPrice += shippingCost

  return (
    <div>
      <Table>
        <TableBody>
          {/*<CouponRow coupon={coupon} handleCouponChange={handleCouponChange} />*/}
          <ShippingCostRow shippingCost={shippingCost} />
          <GrandTotalRow
            total={totalPrice}
            coupon={coupon}
            handleCouponChange={handleCouponChange}
          />
        </TableBody>
      </Table>
    </div>
  )
}

export default CartGrandTotal
