import React, { useEffect } from 'react'
import * as R from 'ramda'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import { makeStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import PendingIcon from '@material-ui/icons/Cached'
import TablePagination from '@material-ui/core/TablePagination'
import TableFooter from '@material-ui/core/TableFooter'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import Fab from '@material-ui/core/Fab'
import ConfirmDialog from './ConfirmDialog'

import { colorMap } from './CartItems'
import { Button } from '@material-ui/core'

const useStyles = makeStyles({
  statusIcon: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  icon: {
    marginLeft: 'auto',
    float: 'right',
  },
  tableHead: {
    position: 'sticky',
    top: 0,
  },
  deleteIcon: {
    color: 'rgba(0, 0, 0, 0.87)',
    '&:hover': {
      color: 'red',
      cursor: 'pointer',
    },
  },
})

const UploadItemRow = ({ item, deleteUpload, isPriceEstimate, history }) => {
  const date = R.path(['date'], item)
  const id = R.path(['id'], item)
  const email = R.path(['email'], item)
  const firstName = R.pathOr('', ['firstname'], item)
  const lastName = R.pathOr('', ['lastname'], item)
  const price = R.pathOr('n/a', ['price'], item)
  const name = firstName ? firstName + ' ' + lastName : ''
  // const text = R.path(['comment'], item)
  const color = R.path(['color'], item)
  const status = R.pathOr('', ['status'], item)
  const doormodel = R.path(['doormodel'], item)
  const type = R.path(['type'], item)
  const country = R.path(['country'], item)
  const isNew = R.path(['isNew'], item)

  const customer = (
    <span>
      <p>{name}</p>
      <p>{email}</p>
    </span>
  )
  const [open, setOpen] = React.useState(false)

  const deleteItem = (id) => {
    deleteUpload(id)
  }

  const handleOpenDialog = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
  }

  const classes = useStyles()

  return (
    <TableRow hover selected={isNew} component={Link} to={`/matcher/${id}`}>
      <TableCell className={classes.statusIcon}>
        {status.toLowerCase() === 'pending' ? (
          <PendingIcon fontSize="large" className={classes.statusIcon} />
        ) : (
          <DoneIcon fontSize="large" className={classes.statusIcon} />
        )}
      </TableCell>
      <TableCell>{customer}</TableCell>
      <TableCell>{id}</TableCell>
      <TableCell>{date}</TableCell>
      <TableCell>{country}</TableCell>
      <TableCell>{type}</TableCell>
      <TableCell>
        <Button component={Link} to={`/matcher/${id}`}>
          <Fab
            style={{
              backgroundColor: colorMap[color] || 'white',
              height: '36px',
              width: '36px',
            }}
            size="small"
          />
          <p style={{ margin: '0 10px', textTransform: 'initial' }}>{color}</p>
        </Button>
      </TableCell>

      <TableCell>{doormodel}</TableCell>

      <TableCell>{price === 'n/a' ? `${price}` : `€ ${price}`}</TableCell>

      <TableCell name="delete-row-cell">
        <DeleteOutlinedIcon
          name="delete-icon-button"
          onClick={handleOpenDialog}
          className={classes.deleteIcon}
          fontSize="large"
        />

        <ConfirmDialog
          id={id}
          onClose={handleClose}
          open={open}
          onConfirm={deleteItem}
        />
      </TableCell>
    </TableRow>
  )
}

const UploadItems = ({
  items,
  meta,
  deleteUpload,
  isPriceEstimate,
  fetchClientData,
}) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(50)

  console.log('items in uploadItems', items)

  useEffect(() => {
    // Fetch client data whenever page or rows per page change
    // HOX: UI pagination starts from 0, backend pagination from 1
    const backendPage = page + 1
    fetchClientData(backendPage, rowsPerPage)
  }, [page, rowsPerPage])

  /*
  const itemsOrdered = Object.keys(items).sort((a, b) => {
    // First sort by date and if date is same, then
    // secondary sort by id
    if (
      moment(items[b]['date'], ['MM/DD/YYYY', 'YYYY-MM-DD']) -
        moment(items[a]['date'], ['MM/DD/YYYY', 'YYYY-MM-DD']) ===
      0
    ) {
      return items[b]['id'] - items[a]['id']
    } else {
      return moment(items[b]['date'], ['MM/DD/YYYY', 'YYYY-MM-DD']).diff(
        moment(items[a]['date'], ['MM/DD/YYYY', 'YYYY-MM-DD']),
        'days'
      )
    }
  })
  

  var tableRows = []
  if (items !== '') {
    for (var item in itemsOrdered) {
      tableRows.push(
        <UploadItemRow
          key={item}
          item={items[itemsOrdered[item]]}
          deleteUpload={deleteUpload}
          isPriceEstimate={isPriceEstimate}
        />
      )
    }
  }
  */

  function handleChangePage(event, newPage) {
    setPage(newPage)
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const classes = useStyles()

  return (
    <Table>
      <TableHead className={classes.tableHead}>
        <TableRow>
          <TableCell />
          <TableCell>Name / Email</TableCell>
          <TableCell>ID</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Country</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Color</TableCell>
          <TableCell>Model</TableCell>
          <TableCell>Price estimate</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {items
          ? Object.values(items).map((item) => (
              <UploadItemRow
                key={item.id}
                item={item}
                deleteUpload={deleteUpload}
                isPriceEstimate={isPriceEstimate}
              />
            ))
          : []}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            // colSpan={3}
            count={meta.count}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              // native: true
            }}
            labelRowsPerPage="Items per page"
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0)
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1)
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1)
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div style={{ flexShrink: 0, marginLeft: 20 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  )
}

export default UploadItems
