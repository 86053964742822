import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import productsReducer from './features/products/productsReducer'

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    products: productsReducer,
  })

/*
const rootReducer = (state, action) => {
  return appReducer(state, action)
}
*/

export default rootReducer
