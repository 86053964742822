import React from 'react'
import { Switch, Route } from 'react-router-dom'

import HomeContainer from './containers/home/HomeContainer'
import ProductsContainer from './containers/products/ProductsContainer'
import MatcherProductContainer from './containers/product/MatcherProductContainer'
import MatcherContainer from './containers/home/MatcherContainer'
import CartContainer from './containers/home/CartContainer'
import PriceEstimate from './features/home/PriceEstimate'
import PricingInfo from './features/home/PricingInfo'
import ProductsAdmin from './features/products/ProductsAdmin'

const Routes = () => (
  <main>
    <Switch>
      <Route exact path="/" component={HomeContainer} />
      <Route exact path="/matcher/:id" component={MatcherContainer} />
      <Route exact path="/prices" component={PriceEstimate} />
      <Route exact path="/products" component={ProductsContainer} />
      <Route exact path="/products/" component={ProductsContainer} />
      <Route
        exact
        path="/products/:partNumber"
        component={MatcherProductContainer}
      />

      <Route exact path="/cart" component={CartContainer} />
      <Route exact path="/cart/" component={CartContainer} />
      <Route exact path="/cart/:id" component={CartContainer} />
      <Route exact path="/info" component={PricingInfo} />
      <Route exact path="/productadmin" component={ProductsAdmin} />
    </Switch>
  </main>
)

export default Routes
