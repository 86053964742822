import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'

const MaterialDistanceTable = ({ setDistanceStats }) => {
  const distances = [
    {
      value: JSON.stringify({
        price1: 0,
        price2: 0,
        price3: 0,
        label: '',
      }),
      label: 'No distance selected',
    },
    {
      value: JSON.stringify({
        price1: 434,
        price2: 434,
        price3: 434,
        label: 'Zone 1 (0-90km from Helsinki)',
      }),
      label: 'Zone 1 (0-90km from Helsinki)',
    },
    {
      value: JSON.stringify({
        price1: 558,
        price2: 558,
        price3: 558,
        label: 'Zone 2 (91-150km from Helsinki)',
      }),
      label: 'Zone 2 (91-150km from Helsinki)',
    },
    {
      value: JSON.stringify({
        price1: 682,
        price2: 682,
        price3: 682,
        label: 'Zone 3 (151-300km from Helsinki)',
      }),
      label: 'Zone 3 (151-300km from Helsinki)',
    },
  ]

  const distancesNo = [
    {
      value: JSON.stringify({
        price1: 0,
        price2: 0,
        price3: 0,
        label: '',
      }),
      label: 'No distance selected',
    },
    {
      value: JSON.stringify({
        price1: 681,
        price2: 681,
        price3: 681,
        label: 'Zone 1 (0-60km from Oslo)',
      }),
      label: 'Zone 1 (0-60km from Oslo)',
    },
    {
      value: JSON.stringify({
        price1: 925,
        price2: 925,
        price3: 925,
        label: 'Zone 2 (61-150km from Oslo)',
      }),
      label: 'Zone 2 (61-150km from Oslo)',
    },
    {
      value: JSON.stringify({
        price1: 1325,
        price2: 1325,
        price3: 1325,
        label: 'Zone 3 (151-250km from Oslo)',
      }),
      label: 'Zone 3 (151-250km from Oslo)',
    },
  ]

  const distancesDen = [
    {
      value: JSON.stringify({
        price1: 0,
        price2: 0,
        price3: 0,
        label: '',
      }),
      label: 'No distance selected',
    },
    {
      value: JSON.stringify({
        price1: 800,
        price2: 800,
        price3: 800,
        label: 'Zone 1 (0-10 km from CPH)',
      }),
      label: 'Zone 1 (0-10 km from CPH)',
    },
    {
      value: JSON.stringify({
        price1: 933,
        price2: 933,
        price3: 933,
        label: 'Zone 2 (11-20 km from CPH)',
      }),
      label: 'Zone 2 (11-20 km from CPH)',
    },
    {
      value: JSON.stringify({
        price1: 1067,
        price2: 1067,
        price3: 1067,
        label: 'Zone 3 (21-50 km from CPH)',
      }),
      label: 'Zone 3 (21-50 km from CPH)',
    },
    {
      value: JSON.stringify({
        price1: 1200,
        price2: 1200,
        price3: 1200,
        label: 'Zone 4 (51-100km from CPH)',
      }),
      label: 'Zone 4 (51-100km from CPH)',
    },
    {
      value: JSON.stringify({
        price1: 1333,
        price2: 1333,
        price3: 1333,
        label: 'Zone 5 (101-200km from CPH)',
      }),
      label: 'Zone 5 (101-200km from CPH)',
    },
  ]

  const distancesSe = [
    {
      value: JSON.stringify({
        price1: 0,
        price2: 0,
        price3: 0,
        label: '',
      }),
      label: 'No distance selected',
    },
    {
      value: JSON.stringify({
        price1: 694,
        price2: 694,
        price3: 694,
        label: 'Zone 1 (< 60 km from Stockholm)',
      }),
      label: 'Zone 1 (< 60 km from Stockholm)',
    },
    {
      value: JSON.stringify({
        price1: 888,
        price2: 888,
        price3: 888,
        label: 'Zone 2 (61 - 150 km from Stockholm)',
      }),
      label: 'Zone 2 (61 - 150 km from Stockholm)',
    },
    {
      value: JSON.stringify({
        price1: 1075,
        price2: 1075,
        price3: 1075,
        label: 'Zone 3 (151 - 250 km from Stockholm)',
      }),
      label: 'Zone 3 (151 - 250 km from Stockholm)',
    },
    {
      value: JSON.stringify({
        price1: 946,
        price2: 946,
        price3: 946,
        label: 'Zone 4 (0-50 km from Malmö)',
      }),
      label: 'Zone 4 (0-50 km from Malmö)',
    },
    {
      value: JSON.stringify({
        price1: 1079,
        price2: 1079,
        price3: 1079,
        label: 'Zone 5 (51-150 km from Malmö and 0-50 km from Helsingborg)',
      }),
      label: 'Zone 5 (51-150 km from Malmö and 0-50 km from Helsingborg)',
    },
    {
      value: JSON.stringify({
        price1: 1213,
        price2: 1213,
        price3: 1213,
        label:
          'Zone 6 (151-230km from Malmö, 51-100km from Helsingborg, 0-50km from Göteborg)',
      }),
      label:
        'Zone 6 (151-230km from Malmö, 51-100km from Helsingborg, 0-50km from Göteborg)',
    },
  ]

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>Select delivery Zone</TableCell>
          <TableCell align="right">
            <TextField
              select
              onChange={(event) => {
                setDistanceStats(JSON.parse(event.target.value))
              }}
              SelectProps={{
                native: true,
              }}
            >
              <optgroup label="FI">
                {distances.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </optgroup>
              <optgroup label="SE">
                {distancesSe.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </optgroup>
              <optgroup label="NO">
                {distancesNo.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </optgroup>
              <optgroup label="DEN">
                {distancesDen.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </optgroup>
            </TextField>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default MaterialDistanceTable
