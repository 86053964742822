import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { Auth } from 'aws-amplify'
import Home from './Home'

const getAuthenticatedUser = async () => {
  const userData = await Auth.currentAuthenticatedUser()
  return userData && userData.signInUserSession
}

function PriceEstimate() {
  const [data, setData] = useState('')
  const [meta, setMeta] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const filterPriceEstimate = (data) =>
    Object.entries(data).reduce(
      (acc, [k, v]) =>
        'isPriceEstimate' in v && v['isPriceEstimate'] === true
          ? {
              ...acc,
              [k]: v,
            }
          : acc,
      {}
    )

  const fetchClientData = (page = 1, pageSize = 50) => {
    getAuthenticatedUser()
      .then((user) => {
        console.log('user', user)
        return fetch(
          `/api/ikea/pdf/upload/json?currentPage=${page}&pageSize=${pageSize}`,
          {
            method: 'GET',
            headers: { Authorization: `jwt ${user.accessToken.jwtToken}` },
          }
        )
      })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Something went wrong ...')
        }
      })
      .then((data) => {
        console.log('data', data)
        setData(filterPriceEstimate(data.data))
        setMeta(data.meta)
        setIsLoading(false)
      })
      .catch((error) => setError(error))
  }

  useEffect(() => {
    setIsLoading(true)
    fetchClientData()
  }, [])

  return (
    <Home
      data={data}
      meta={meta}
      isPriceEstimate={true}
      addDataToState={setData}
      fetchClientData={fetchClientData}
    />
  )
}

export default withRouter(PriceEstimate)
