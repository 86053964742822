export const getProductShortName = (productObj) => {
  if (!productObj) {
    return undefined
  }
  if (
    productObj.productcategory &&
    (productObj.productcategory.toLowerCase() === 'handles' ||
      productObj.productcategory.toLowerCase() === 'sinks' ||
      productObj.productcategory.toLowerCase() === 'legs' ||
      productObj.productcategory.toLowerCase() === 'taps')
  ) {
    return productObj.name
      .replace('A.S.Helsing&ouml;', '')
      .replace('A.S.Helsingö', '')
  }
  if (
    productObj.name &&
    productObj.door_type &&
    productObj.name.toLowerCase().includes('customxcustom')
  ) {
    return `${productObj.door_type} ${productObj.name
      .replace('A.S.Helsing&ouml;', '')
      .replace('A.S.Helsingö', '')}`
  }
  // return `${productObj.color} ${productObj.door_type} ${productObj.product_type} ${productObj.cabinet_width}x${productObj.cabinet_height}`
  return productObj.name
    ? productObj.name
        .replace('A.S.Helsing&ouml;', '')
        .replace('A.S.Helsingö', '')
    : undefined
}
