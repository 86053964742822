import * as R from 'ramda'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Products from '../../features/products/Products'

class ProductsContainer extends Component {
  getProductMap() {
    const { productsObject } = this.props
    return R.map((key) => productsObject[key])(Object.keys(productsObject))
  }

  render() {
    const productsMap = this.getProductMap()
    return <Products products={productsMap} />
  }
}

const mapStateToProps = (state, ownProps) => ({
  productsObject: R.path(['products', 'object'], state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(ProductsContainer)
)
