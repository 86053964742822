import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import Home from '../../features/home/Home'

const filterOutPriceEstimate = (data) =>
  Object.entries(data).reduce(
    (acc, [k, v]) =>
      'isPriceEstimate' in v && v.isPriceEstimate === true
        ? acc
        : {
            ...acc,
            [k]: v,
          },

    {}
  )

const getAuthenticatedUser = async () => {
  const userData = await Auth.currentAuthenticatedUser()
  return userData && userData.signInUserSession
}

class HomeContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: '',
      meta: {},
      user: null,
      isLoading: false,
      error: null,
    }

    this.addUserToState = this.addUserToState.bind(this)
    this.addDataToState = this.addDataToState.bind(this)
    this.fetchClientData = this.fetchClientData.bind(this)
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    this.addUserToState()
    // this.fetchClientData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.user &&
      this.state.user &&
      JSON.stringify(prevState.user) !== JSON.stringify(this.state.user)
    ) {
      // User was updated
      this.fetchClientData()
    }
  }

  addUserToState() {
    getAuthenticatedUser().then((user) => this.setState({ user }))
  }

  addDataToState(data) {
    this.setState({ data: filterOutPriceEstimate(data), isLoading: false })
  }

  fetchClientData(page = 1, pageSize = 50) {
    getAuthenticatedUser()
      .then((user) => {
        console.log('user', user)
        return fetch(
          `/api/ikea/pdf/upload/json?currentPage=${page}&pageSize=${pageSize}`,
          {
            method: 'GET',
            headers: { Authorization: `jwt ${user.accessToken.jwtToken}` },
          }
        )
      })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Something went wrong ...')
        }
      })
      .then((data) =>
        this.setState({
          data: filterOutPriceEstimate(data.data),
          meta: data.meta,
          isLoading: false,
        })
      )
      .catch((error) => this.setState({ error, isLoading: false }))
  }

  render() {
    const { data, meta } = this.state
    return (
      <Home
        data={data}
        meta={meta}
        isPriceEsimate={false}
        addDataToState={this.addDataToState}
        fetchClientData={this.fetchClientData}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(HomeContainer)
)
