import React, { useState } from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { styled } from '@material-ui/styles'

const AddFab = styled(Fab)({
  backgroundColor: '#c7beb8',
  color: '#fafafa',
  '&:hover': {
    backgroundColor: '#4caf50',
  },
})

const TableCellNoBorder = styled(TableCell)({
  borderBottom: 'none',
})

const AccessoryProductRow = ({
  rowId,
  options,
  categoryName,
  selectedProducts,
  setSelectedProduct,
  handleChangeQty,
  handleAddRow,
}) => {
  const product = selectedProducts[rowId]['item']
  const isFirst = selectedProducts[rowId].first
  const isLast =
    Object.keys(selectedProducts).length &&
    Number(rowId) + 1 === Object.keys(selectedProducts).length
  const isMultipleRows =
    Object.keys(selectedProducts).length &&
    Object.keys(selectedProducts).length > 1
  const [inputValue, setInputValue] = useState('')
  const handleChange = (event, value) => {
    setSelectedProduct(categoryName, rowId, value)
  }

  const handleQtyChange = (event) => {
    console.log('qty', event.target.value)
    handleChangeQty(categoryName, rowId, parseInt(event.target.value))
  }

  const getTruncatedSku = (sku) => {
    if (!sku) {
      return ''
    } else if (sku.length && sku.length > 10) {
      return sku.substring(0, 7) + '...'
    }
    return sku
  }

  if (isMultipleRows && !isLast) {
    {
      /* We don't want bottom borders if there are 
      multiple rows of same accessory category */
    }
    return (
      <TableRow hover={false} style={{ position: 'relative' }}>
        <TableCellNoBorder>{categoryName}</TableCellNoBorder>
        <TableCellNoBorder>
          <Autocomplete
            id="combobox-product"
            options={options}
            inputValue={inputValue}
            onInputChange={(event, newValue) => setInputValue(newValue)}
            value={product}
            getOptionLabel={(option) => option.parent_product_title}
            onChange={(event, newValue) => handleChange(event, newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={categoryName}
                variant="outlined"
                margin="dense"
                fullWidth
              />
            )}
          />
        </TableCellNoBorder>
        <TableCellNoBorder>
          {product ? getTruncatedSku(product.sku) : ''}
        </TableCellNoBorder>
        <TableCellNoBorder>
          <TextField
            type="number"
            inputProps={{ min: '1', max: '100', step: '1', placeholder: '1' }}
            onChange={handleQtyChange}
          />
        </TableCellNoBorder>
        <TableCellNoBorder align="right">
          {isFirst && (
            <AddFab
              aria-label="add"
              size="small"
              onClick={() => handleAddRow(categoryName)}
            >
              <AddIcon fontSize="large" />
            </AddFab>
          )}
        </TableCellNoBorder>
      </TableRow>
    )
  }

  return (
    <TableRow hover={false} style={{ position: 'relative' }}>
      <TableCell>{categoryName}</TableCell>
      <TableCell>
        <Autocomplete
          id="combobox-product"
          options={options}
          inputValue={inputValue}
          onInputChange={(event, newValue) => setInputValue(newValue)}
          value={product}
          getOptionLabel={(option) => option.parent_product_title}
          onChange={(event, newValue) => handleChange(event, newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={categoryName}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          )}
        />
      </TableCell>
      <TableCell>{product ? getTruncatedSku(product.sku) : ''}</TableCell>
      <TableCell>
        <TextField
          type="number"
          inputProps={{ min: '1', max: '100', step: '1', placeholder: '1' }}
          onChange={handleQtyChange}
        />
      </TableCell>
      <TableCell align="right">
        {isFirst && (
          <AddFab
            aria-label="add"
            size="small"
            onClick={() => handleAddRow(categoryName)}
          >
            <AddIcon fontSize="large" />
          </AddFab>
        )}
      </TableCell>
    </TableRow>
  )
}

export default AccessoryProductRow
