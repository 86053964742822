const userPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID
const webClientId = process.env.REACT_APP_COGNITO_WEB_CLIENT_ID

const config = {
  Auth: {
    region: 'eu-north-1',
    userPoolId,
    userPoolWebClientId: webClientId,
    mandatorySignIn: true,
  },
}

export default config
