import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'

import { extraHolesEn } from './Pricing'

export const ExtraHoles = ({ updateExtraHoles, extraHoles }) => {
  console.log('extra holes', extraHoles)
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Extra openings</TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(extraHoles).map((extraHolesKey) => {
          // const extraHole = extraHoles[extraHolesKey];
          return (
            <ExtraHoleRow
              label={extraHolesKey}
              updateExtraHoles={updateExtraHoles}
              key={extraHolesKey}
            />
          )
        })}
      </TableBody>
    </Table>
  )
}

const ExtraHoleRow = ({ label, updateExtraHoles }) => {
  console.log('extra holes options', extraHolesEn)
  const [quantityValue, setQuantityValue] = useState(0)
  const [descriptionValue, setDescriptionValue] = useState('')
  const [labelFi, setLabelFi] = useState('')
  const [labelSe, setLabelSe] = useState('')
  const [price, setPrice] = useState(0)

  return (
    <TableRow>
      <TableCell>{`Opening ${label}.`}</TableCell>
      <TableCell>
        <TextField
          style={{ fontSize: 12 }}
          type="number"
          inputProps={{ min: 0, max: 1000, step: 1 }}
          value={quantityValue}
          onChange={(event) => {
            setQuantityValue(event.target.value)
            updateExtraHoles(label, {
              label: descriptionValue,
              label_fi: labelFi,
              label_se: labelSe,
              price: price,
              qty: Number(event.target.value),
            })
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          select
          onChange={(event) => {
            const value = JSON.parse(event.target.value)
            setDescriptionValue(value.label)
            setLabelFi(value.label_fi)
            setLabelSe(value.label_se)
            setPrice(Number(value.price))
            updateExtraHoles(label, {
              qty: Number(quantityValue),
              price: Number(value.price),
              label: value.label,
              label_fi: value.label_fi,
              label_se: value.label_se,
            })
          }}
          SelectProps={{
            native: true,
          }}
        >
          {extraHolesEn.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </TableCell>
      <TableCell>{(quantityValue * price).toFixed(3)}</TableCell>
    </TableRow>
  )
}
