import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const PriceTable = (props) => {
  const { totalPrice, materials, machinings, deliveryAndInstallation } = props
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Price category</TableCell>
          <TableCell align="right">Customer price (VAT 24%) (eur)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Total price</TableCell>
          <TableCell align="right">{totalPrice}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Materials</TableCell>
          <TableCell align="right">{materials}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Machinings</TableCell>
          <TableCell align="right">{machinings}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Measurements, delivery and installation</TableCell>
          <TableCell align="right">
            {Number(deliveryAndInstallation).toFixed(3)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default PriceTable
