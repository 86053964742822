import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import Fab from '@material-ui/core/Fab'
import AddNewDialog from './AddNewDialog'
import AddIcon from '@material-ui/icons/Add'
import { deleteUploadItem } from '../../api/server'

import UploadItems from './UploadItems'
import GreetingsApp from './GreetingsApp'

const useStyles = makeStyles({
  root: {
    width: '95vw',
  },
  search: {
    width: '15vw',
    marginRight: '3vw',
  },
  status: {
    width: '15vw',
    marginRight: '3vw',
  },
  cardContent: {
    marginTop: 15,
  },
  cardHeader: {
    fontSize: '2.5rem',
    fontFamily: 'Diamonds Light',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
  },
  addFab: {
    backgroundColor: '#c7beb8',
    color: '#fafafa',
    margin: '10px 0',
    '&:hover': {
      backgroundColor: '#4caf50',
    },
  },
})

function Home({
  data,
  meta,
  isPriceEstimate,
  addDataToState,
  fetchClientData,
}) {
  const [items, setItems] = useState(data)
  const [searchField, setSearchField] = useState('')
  const [searchedItems, setSearchedItems] = useState('')
  const [option, setOption] = useState('')
  const [newDialogOpen, setNewDialogOpen] = useState(false)

  useEffect(() => {
    console.log('setting state data', data)
    setItems(data)
  }, [data])

  const search = ({ target: { value: text } }) => {
    setSearchField(text)
    if (!text) {
      setSearchedItems(items)
    } else {
      const newData = Object.entries(items).filter(
        ([_, value]) =>
          value.email
            .split('@')[0]
            .toLowerCase()
            .includes(text.toLowerCase()) ||
          value.email.toLowerCase().includes(text.toLowerCase()) ||
          value.id.includes(text.toLowerCase()) ||
          (value.firstname &&
            value.firstname.toLowerCase().includes(text.toLowerCase())) ||
          (value.lastname &&
            value.lastname.toLowerCase().includes(text.toLowerCase()))
      )
      // create object from array
      const obj = newData.reduce(
        (acc, cur) => ({ ...acc, [cur[0]]: cur[1] }),
        {}
      )
      setSearchedItems(obj)
    }
  }

  const handleAddNewRow = (item) => {
    addUpload(item)
  }

  const deleteUpload = (id) => {
    // TODO: This needs refactoring using addDataToState
    console.log('delete ', id)
    deleteUploadItem(id).then((res) => {
      if (res['message'] === 'removed') {
        // This is an anti-pattern: modifying props directly
        delete data[id + '.json']
        // remove from searchedItems object using rest es7 operator
        const { [id + '.json']: _, ...newSearchedItems } = searchedItems
        setSearchedItems(newSearchedItems)
        alert('Upload items removed')
      } else {
        alert(res['message'])
      }
    })
  }

  const addUpload = (item) => {
    addDataToState({ [`${item.id}.json`]: { ...item, isNew: true }, ...data })
  }

  const handleOptionChange = ({ target: { value: newOption } }) => {
    setOption(newOption)
    if (newOption === 'All') {
      setItems(data)
    } else {
      const newData = Object.entries(data).filter(
        ([_, value]) =>
          value.status && value.status.toLowerCase() === newOption.toLowerCase()
      )
      const obj = newData.reduce(
        (acc, cur) => ({ ...acc, [cur[0]]: cur[1] }),
        {}
      )
      setItems(obj)
    }
    setSearchField('')
  }

  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <Card
        raised={false}
        style={{ paddingLeft: '5vw', paddingBottom: '64px' }}
      >
        <div id="head">
          <CardHeader
            title={isPriceEstimate ? 'Price estimate list' : 'Purchase help'}
            action={<GreetingsApp />}
            classes={{ title: classes.cardHeader }}
            style={{
              textTransform: 'uppercase',
              paddingTop: 20,
              marginBottom: 20,
            }}
          />
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {!isPriceEstimate ? (
              <div style={{ width: '60%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Fab
                    size="small"
                    className={classes.addFab}
                    onClick={() => setNewDialogOpen(true)}
                  >
                    <AddIcon />
                  </Fab>
                  <p
                    style={{
                      margin: '0 10px',
                      textTransform: 'uppercase',
                      color: 'rgba(0, 0, 0, 0.54)',
                    }}
                  >
                    Add new
                  </p>
                </div>
                <AddNewDialog
                  onClose={() => setNewDialogOpen(false)}
                  open={newDialogOpen}
                  title="add-new-dialog"
                  onSave={handleAddNewRow}
                  isPriceEstimate={isPriceEstimate}
                />
              </div>
            ) : (
              <div style={{ width: '60%' }}></div>
            )}
            <div style={{ width: '40%' }}>
              <TextField
                select
                label="Status"
                margin="dense"
                variant="outlined"
                className={classes.status}
                onChange={handleOptionChange}
                value={option}
                inputProps={{
                  name: 'status',
                  id: 'status',
                }}
              >
                <MenuItem value={'All'}>All</MenuItem>
                <MenuItem value={'Pending'}>Pending</MenuItem>
                <MenuItem value={'Cart sent to customer'}>
                  Cart sent to customer
                </MenuItem>
              </TextField>
              <TextField
                className={classes.search}
                id="outlined-search"
                label="Search"
                type="search"
                margin="dense"
                variant="outlined"
                onChange={search}
                value={searchField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <CardContent className={classes.cardContent}>
          <UploadItems
            items={searchField ? searchedItems : items}
            meta={meta}
            deleteUpload={deleteUpload}
            isPriceEstimate={isPriceEstimate}
            addUpload={addUpload}
            fetchClientData={fetchClientData}
          />
        </CardContent>
      </Card>
    </Paper>
  )
}

export default Home
