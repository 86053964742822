export const stoveTypes = [
  {
    value: JSON.stringify({
      price: 0,
      label_se: 'Ingen öppning för spis',
      label_fi: 'Ei aukkoa liedelle',
      label: 'No opening for stove',
    }),
    label: 'No opening for stove',
  },
  {
    value: JSON.stringify({
      price: 117.18,
      label: 'Stove attached from the top',
      label_se: 'Ovanifrån fäst spis',
      label_fi: 'Päältä kiinnitetty liesi',
    }),
    label: 'Stove attached from the top',
  },
  {
    value: JSON.stringify({
      price: 223.944,
      label: 'Flushmounted stove',
      label_fi: 'Huullosliesi',
      label_se: 'Infälld spis',
    }),
    label: 'Flushmounted stove',
  },
]

export const sinkTypes = [
  {
    value: JSON.stringify({
      price: 0,
      label: 'No opening for sink',
      label_fi: 'Ei aukkoa altaalle',
      label_se: 'Ingen öppning för dikskho',
    }),
    label: 'No opening for sink',
  },
  {
    value: JSON.stringify({
      price: 117.18,
      label: 'Top mounted sink',
      label_se: 'Planmontage',
      label_fi: 'Päältä kiinnitetty allas',
    }),
    label: 'Top mounted sink',
  },
  {
    value: JSON.stringify({
      price: 223.944,
      label: 'Flushmounted sink',
      label_se: 'Infälld diskho',
      label_fi: 'Huullosallas',
    }),
    label: 'Flushmounted sink',
  },
  {
    value: JSON.stringify({
      price: 204.414,
      label: 'Undermounted sink',
      label_se: 'Diskho undermontage',
      label_fi: 'Altapäin kiinnitetty allas',
    }),
    label: 'Undermounted sink',
  },
]

export const faucetTypes = [
  {
    value: JSON.stringify({
      price: 0,
      label: 'No opening for tap',
      label_fi: 'Ei aukkoa hanalle',
      label_se: 'Ingen öppning för kran',
    }),
    label: 'No opening for tap',
  },
  {
    value: JSON.stringify({
      price: 22.134,
      label: 'Incl. opening for tap',
      label_fi: 'Aukko hanalle',
      label_se: 'Öppning för kran',
    }),
    label: 'Incl. opening for tap',
  },
]

export const chamferPricePerMeter = 23.436

export const extraHoles = [
  {
    value: JSON.stringify({
      price: 0,
      label: '',
    }),
    label: '',
  },
  {
    value: JSON.stringify({
      price: 22.134,
      label: 'Pyöreä < 50mm',
    }),
    label: 'Pyöreä < 50mm',
  },
  {
    value: JSON.stringify({
      price: 28.644,
      label: 'Pyöreä 50-250mm',
    }),
    label: 'Pyöreä 50-250mm',
  },
  {
    value: JSON.stringify({
      price: 36.456,
      label: 'Pyöreä > 250mm',
    }),
    label: 'Pyöreä > 250mm',
  },
  {
    value: JSON.stringify({
      price: 63.798,
      label: 'Suorakaide pisin sivu < 100mm',
    }),
    label: 'Suorakaide pisin sivu < 100mm',
  },
  {
    value: JSON.stringify({
      price: 80.724,
      label: 'Suorakaide pisin sivu 100-400mm',
    }),
    label: 'Suorakaide pisin sivu 100-400mm',
  },
  {
    value: JSON.stringify({
      price: 101.556,
      label: 'Suorakaide pisin sivu > 400mm',
    }),
    label: 'Suorakaide pisin sivu > 400mm',
  },
]

export const chamferTypes = [
  // values represent factors for the price of width and depth
  // these are html form option values which must be strings
  // examples:
  // "1,0" means 1 * width * chamferPricePerMeter + 0
  // "1,2" means 1 * width * chamferPricePerMeter + 2 * depth * chamferPricePerMeter

  {
    value: JSON.stringify({
      price: '0,0',
      label: 'Unfinished edges',
      label_se: 'Obehandlade kanter',
      label_fi: 'Ei reunojen viimeistelyä',
    }),
    label: 'Unfinished edges',
  },
  {
    value: JSON.stringify({
      price: '1,0',
      label: 'Chamfered edge 1 x Width',
      label_fi: 'Viistereuna 1 x Leveys',
      label_se: 'Sneddad kant 1 x bredd',
    }),
    label: 'Chamfered edge 1 x Width',
  },
  {
    value: JSON.stringify({
      price: '2,0',
      label: 'Chamfered edge 2 x Width',
      label_fi: 'Viistereuna 2 x Leveys',
      label_se: 'Sneddad kant 2 x bredd',
    }),
    label: 'Chamfered edge 2 x Width',
  },
  {
    value: JSON.stringify({
      price: '1,1',
      label: 'Chamfered edge 1 x Width and 1 x Depth',
      label_fi: 'Viistereuna 1 x Leveys ja 1 x Syvyys',
      label_se: 'Sneddad kant 1 x bredd och 1 x djup',
    }),
    label: 'Chamfered edge 1 x Width and 1 x Depth',
  },
  {
    value: JSON.stringify({
      price: '2,1',
      label: 'Chamfered edge 2 x Width and 1 x Depth',
      label_fi: 'Viistereuna 2 x Leveys ja 1 x Syvyys',
      label_se: 'Sneddad kant  2 x bredd och 1 x djup',
    }),
    label: 'Chamfered edge 2 x Width and 1 x Depth',
  },
  {
    value: JSON.stringify({
      price: '1,2',
      label: 'Chamfered edge 1 x Width and 2 x Depth',
      label_fi: 'Viistereuna 1 x Leveys ja 2 x Syvyys',
      label_se: 'Sneddad kant  1 x bredd och 2 x djup',
    }),
    label: 'Chamfered edge 1 x Width and 2 x Depth',
  },
  {
    value: JSON.stringify({
      price: '2,2',
      label: 'Chamfered edge 2 x Width and 2 x Depth',
      label_fi: 'Viistereuna 2 x Leveys ja 2 x Syvyys',
      label_se: 'Sneddad kant  2 x bredd och 2 x djup',
    }),
    label: 'Chamfered edge 2 x Width and 2 x Depth',
  },
  {
    value: JSON.stringify({
      price: '0,1',
      label: 'Chamfered edge 1 x Depth',
      label_fi: 'Viistereuna 1 x Syvyys',
      label_se: 'Sneddad kant  1 x djup',
    }),
    label: 'Chamfered edge 1 x Depth',
  },
  {
    value: JSON.stringify({
      price: '0,2',
      label: 'Chamfered edge 2 x Depth',
      label_fi: 'Viistereuna 2 x Syvyys',
      label_se: 'Sneddad kant 2 x djup',
    }),
    label: 'Chamfered edge 2 x Depth',
  },
]

export const extraHolesEn = [
  {
    value: JSON.stringify({
      price: 0,
      label: '',
      label_fi: '',
      label_se: '',
    }),
    label: '',
  },
  {
    value: JSON.stringify({
      price: 22.32,
      label: 'Round < 50mm',
      label_fi: 'Pyöreä < 50mm',
      label_se: 'Rund < 50mm',
    }),
    label: 'Round < 50mm',
  },
  {
    value: JSON.stringify({
      price: 26.04,
      label: 'Round 50-250mm',
      label_fi: 'Pyöreä 50-250mm',
      label_se: 'Rund 50-250mm',
    }),
    label: 'Round 50-250mm',
  },
  {
    value: JSON.stringify({
      price: 78.12,
      label: 'Round > 250mm',
      label_fi: 'Pyöreä > 250mm',
      label_se: 'Rund > 250mm',
    }),
    label: 'Round > 250mm',
  },
  {
    value: JSON.stringify({
      price: 59.52,
      label: 'Rectangle longest side < 100mm',
      label_fi: 'Suorakaide pisin sivu < 100mm',
      label_se: 'Rektangel längsta sida < 100mm',
    }),
    label: 'Rectangle longest side < 100mm',
  },
  {
    value: JSON.stringify({
      price: 78.12,
      label: 'Rectangle longest side 100-400mm',
      label_fi: 'Suorakaide pisin sivu 100-400mm',
      label_se: 'Rektangel längsta sida 100-400mm',
    }),
    label: 'Rectangle longest side 100-400mm',
  },
  {
    value: JSON.stringify({
      price: 97.96,
      label: 'Rectangle longest side > 400mm',
      label_fi: 'Suorakaide pisin sivu > 400mm',
      label_se: 'Rektangel längsta sida > 400mm',
    }),
    label: 'Rectangle longest side > 400mm',
  },
]

export const materials = [
  {
    value: JSON.stringify({
      price: 0,
      label: 'No material selected',
      label_fi: 'Ei valittua materiaalia',
      label_se: 'Inget material valt',
    }),
    label: 'No material selected',
  },
  {
    value: JSON.stringify({
      price: 390.1,
      label: 'Super White (Quartz)',
      label_fi: 'Super White (Kvartsikomposiitti)',
      label_se: 'Super White (Kvartskomposit)',
      sku: 'SQSWXX-XX',
    }),
    label: 'Super White (Quartz)',
  },
  {
    value: JSON.stringify({
      price: 510.14,
      label: 'Carrara (Quartz)',
      label_fi: 'Carrara (Kvartsikomposiitti)',
      label_se: 'Carrara (Kvartskomposit)',
      sku: 'SQCQXX-XX',
    }),
    label: 'Carrara (Quartz)',
  },
  {
    label: 'Hestia (Quartz)',
    value: JSON.stringify({
      price: 510.14,
      label: 'Hestia (Quartz)',
      label_fi: 'Hestia (Kvartsikomposiitti)',
      label_se: 'Hestia (Kvartskomposit)',
      sku: 'SQHEXX-XX',
    }),
  },
  {
    label: 'Coral Clay (Quartz)',
    value: JSON.stringify({
      price: 390.1,
      label: 'Coral Clay (Quartz)',
      label_fi: 'Coral Clay (Kvartsikomposiitti)',
      label_se: 'Coral Clay (Kvartskomposit)',
      sku: 'SQCCXX-XX',
    }),
  },
  {
    label: 'Calacatta Goldlight (Quartz)',
    value: JSON.stringify({
      price: 660.18,
      label: 'Calacatta Goldlight (Quartz)',
      label_fi: 'Calacatta Goldlight (Kvartsikomposiitti)',
      label_se: 'Calacatta Goldlight (Kvartskomposit)',
      sku: 'SQCGXX-XX',
    }),
  },
  {
    label: 'Blanco Abujardado (Ceramics)',
    value: JSON.stringify({
      price: 510.14,
      label: 'Blanco Abujardado (Ceramics)',
      label_fi: 'Blanco Abujardado (Keraaminen)',
      label_se: 'Blanco Abujardado (Keramik)',
      sku: 'SCBAXX-XX',
    }),
  },
  {
    label: 'Beton (Ceramics)',
    value: JSON.stringify({
      price: 510.14,
      label: 'Beton (Ceramics)',
      label_fi: 'Beton (Keraaminen)',
      label_se: 'Beton (Keramik)',
      sku: 'SCBCXX-XX',
    }),
  },
  {
    label: 'Carrara (Marble)',
    value: JSON.stringify({
      price: 390.1,
      label: 'Carrara (Marble)',
      label_fi: 'Carrara (Marmori)',
      label_se: 'Carrara (Marmor)',
      sku: 'SMCMXX-XX',
    }),
  },
  {
    label: 'Verde Quatemala (Marble)',
    value: JSON.stringify({
      price: 330.09,
      label: 'Verde Quatemala (Marble)',
      label_fi: 'Verde Quatemala (Marmori)',
      label_se: 'Verde Quatemala (Marmor)',
      sku: 'SMVGXX-XX',
    }),
  },
  {
    label: 'Absolute Black Leather (Granite)',
    value: JSON.stringify({
      price: 390.1,
      label: 'Absolute Black Leather (Granite)',
      label_fi: 'Absolute Black Leather (Graniitti)',
      label_se: 'Absolute Black Leather (Granit)',
      sku: 'SGABXX-XX',
    }),
  },
  {
    label: 'Colonial White (Granite)',
    value: JSON.stringify({
      price: 960.26,
      label: 'Colonial White (Granite)',
      label_fi: 'Colonial White (Graniitti)',
      label_se: 'Colonial White (Granit)',
      sku: 'SGCWXX-XX',
    }),
  },
  {
    label: 'Mykonos (Quartzite)',
    value: JSON.stringify({
      price: 660.18,
      label: 'Mykonos (Quartzite)',
      label_fi: 'Mykonos (Kvartsiitti)',
      label_se: 'Mykonos (Kvartsit)',
      sku: 'SKMYXX-XX',
    }),
  },
]

export const chamferPricing = (width, depth) => (value) => {
  if (!value) return 0
  const [widthFactor, depthFactor] = String(value)
    .split(',')
    .map((dimension) => parseInt(dimension))

  return (
    (width * widthFactor * chamferPricePerMeter +
      depth * depthFactor * chamferPricePerMeter) /
    1e3
  )
}
