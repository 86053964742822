import * as R from 'ramda'
import { combineReducers } from 'redux'
import {
  ADD_TO_PRODUCTS,
  CLEAR_PRODUCTS,
  FETCH_PRODUCT,
  UPLOAD_PDF,
  SET_FILTER,
  REMOVE_FILTER,
  CLEAR_FILTERS,
} from './productsActions'

const addProduct = (product, state) =>
  R.assoc(R.path(['position'], product), product, state)

const object = (state = {}, action) => {
  switch (action.type) {
    case ADD_TO_PRODUCTS:
      return addProduct(R.path(['product'], action), state)
    case UPLOAD_PDF + '_SUCCESS':
      return R.reduce(
        (acc, product) => addProduct(product, acc),
        state,
        R.path(['products'], action)
      )
    case CLEAR_PRODUCTS:
      return {}
    default:
      return state
  }
}

const filters = (state = [], action) => {
  switch (action.type) {
    case SET_FILTER:
      return R.uniq(R.append(R.path(['filter'], action))(state))
    case REMOVE_FILTER:
      return R.reject(R.equals(R.path(['filter'], action)))(state)
    case CLEAR_FILTERS:
      return []
    default:
      return state
  }
}

const loading = (state = false, action) => {
  switch (action.type) {
    case FETCH_PRODUCT:
      return true
    case FETCH_PRODUCT + '_SUCCESS':
      return false
    case FETCH_PRODUCT + '_FAILED':
      return false
    default:
      return state
  }
}

const error = (state = null, action) => {
  switch (action.type) {
    case FETCH_PRODUCT + '_FAILED':
      return R.path(['error'], action)
    default:
      return state
  }
}

export default combineReducers({
  object,
  filters,
  loading,
  error,
})
