import * as R from 'ramda'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'

import MatcherProductRow from './MatcherProductRow'

const MatcherProducts = ({
  products,
  addItemToCart,
  isInitial,
  filterOn,
  cart,
  cart2,
  aitoMatches,
  updateCart,
  updateAitoMatches,
  openMatchSelect,
  matches,
  selectedMinicartCategory,
}) => {
  // const prodLength = products.length
  const productsForRows = []

  R.addIndex(R.map)((product, i) => {
    let { qty } = product
    const original = R.or(R.path(['original'], product), {})
    const metric = R.path(['metric'], original)

    while (qty--) {
      if (metric && metric.includes('Korkeus: 9.7 cm')) {
        productsForRows.push(product)
      }
      productsForRows.push(product)
    }
  })(products)

  const filteredProducts = productsForRows
    .filter((product) => product.filterOut === 1)
    .map((product, i) => {
      const hits = R.or(R.path(['hits'], product), {})
      const index = hits.findIndex(
        (p, index) => cart[i] && p.name === cart[i].product
      )
      const row = hits[index !== -1 ? index : 0]
      const match = R.or(R.path([i], matches), row)
      // console.log('row', row)
      return (
        <MatcherProductRow
          key={i}
          rowId={i}
          product={product}
          match={match}
          cart={cart}
          cart2={cart2}
          aitoMatches={aitoMatches}
          updateCart={updateCart}
          addItemToCart={addItemToCart}
          updateAitoMatches={updateAitoMatches}
          openMatchSelect={openMatchSelect}
          selectedMinicartCategory={selectedMinicartCategory}
          rows={hits}
        />
      )
    })

  const filteredProductsCount = filteredProducts.length
  const [page, setPage] = React.useState(0)
  const rowsPerPage = 10

  function handleChangePage(event, newPage) {
    setPage(newPage)
  }
  React.useEffect(() => setPage(0), [filterOn])

  return (
    <div style={{ padding: '15px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 100 }}>IKEA SKU</TableCell>
            <TableCell>IKEA Product</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Matched Helsingö Product</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredProducts.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              count={filteredProductsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} of ${count} products`
              }
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  )
}

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0)
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1)
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1)
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div style={{ flexShrink: 0, marginLeft: 20 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  )
}

export default MatcherProducts
