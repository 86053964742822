import React from 'react'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import * as prices from '../tabletops/Pricing'

const useStyles = makeStyles({
  root: {
    width: '95vw',
  },
  search: {
    width: '15vw',
    marginRight: '3vw',
  },
  status: {
    width: '15vw',
    marginRight: '3vw',
  },
  cardContent: {
    marginTop: 15,
  },
  cardHeader: {
    fontSize: '2.5rem',
    fontFamily: 'Diamonds Light',
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
  },
  table: {
    minWidth: 650,
    maxWidth: 850,
  },
})

function createData(category, price, label, sku) {
  return { category, price, label, sku }
}

const rows = prices.materials
  .filter((item) => !item.label.includes('Ei valittua materiaalia'))
  .map((item) =>
    createData(
      'Tabletop Materials',
      JSON.parse(item.value).price,
      item.label,
      JSON.parse(item.value).sku
    )
  )

const stoveRows = prices.stoveTypes.map((item) =>
  createData('Stove Types', JSON.parse(item.value).price, item.label)
)

const sinkRows = prices.sinkTypes.map((item) =>
  createData('Sink Types', JSON.parse(item.value).price, item.label)
)

const faucetRows = prices.faucetTypes.map((item) =>
  createData('Faucet Types', JSON.parse(item.value).price, item.label)
)

const holesRows = prices.extraHolesEn.map((item) =>
  createData('Extra holes', JSON.parse(item.value).price, item.label)
)

const chamferRows = prices.chamferTypes.map((item) =>
  createData('Chamfer prices', JSON.parse(item.value).price, item.label)
)

const PricingInfo = () => {
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      <Card raised={false} style={{ paddingLeft: '5vw' }}>
        <div id="head">
          <CardHeader
            title="Pricing info for Tabletops"
            classes={{ title: classes.cardHeader }}
            style={{
              textTransform: 'uppercase',
              paddingTop: 20,
              marginBottom: 20,
            }}
          />
        </div>
        <div>
          <CardContent className={classes.cardContent}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Material prices</TableCell>
                    <TableCell align="right">SKU</TableCell>
                    <TableCell align="right">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.label}>
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell align="right">{row.sku}</TableCell>
                      <TableCell align="right">{row.price}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <CardContent className={classes.cardContent}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Stove prices</TableCell>
                    <TableCell align="right">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stoveRows.map((row) => (
                    <TableRow key={row.label}>
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell align="right">{row.price}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <CardContent className={classes.cardContent}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sink prices</TableCell>
                    <TableCell align="right">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sinkRows.map((row) => (
                    <TableRow key={row.label}>
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell align="right">{row.price}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <CardContent className={classes.cardContent}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Faucet prices</TableCell>
                    <TableCell align="right">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {faucetRows.map((row) => (
                    <TableRow key={row.label}>
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell align="right">{row.price}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <CardContent className={classes.cardContent}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Extra Holes</TableCell>
                    <TableCell align="right">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {holesRows.map((row) => (
                    <TableRow key={row.label}>
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell align="right">{row.price}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <CardContent className={classes.cardContent}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Chamfer Pricing</TableCell>
                    <TableCell align="right">Price factor for width</TableCell>
                    <TableCell align="right">Price factor for depth</TableCell>
                    <TableCell align="right">Price per meter </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chamferRows.map((row) => (
                    <TableRow key={row.label}>
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell align="right">
                        {row.price.split(',')[0]}
                      </TableCell>
                      <TableCell align="right">
                        {row.price.split(',')[1]}
                      </TableCell>
                      <TableCell align="right">
                        {prices.chamferPricePerMeter}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </div>
      </Card>
    </Paper>
  )
}

export default PricingInfo
