import * as R from 'ramda'
import React, { Component } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import Tooltip from '@material-ui/core/Tooltip'
import { styled } from '@material-ui/styles'
import { withTranslation } from 'react-i18next'
import { getProductShortName } from '../../utils/helpers.js'

const AddFab = styled(({ isAdded, ...other }) => <Fab {...other} />)({
  backgroundColor: (props) => (props.isAdded ? '#4caf50' : '#c7beb8'),
  color: '#fafafa',
  '&:hover': {
    backgroundColor: (props) => (props.isAdded ? '#e10050' : '#4caf50'),
  },
})

const SwitchFab = styled(({ isSwitched, ...other }) => <Fab {...other} />)({
  backgroundColor: (props) => (props.isSwitched ? '#e10050' : '#c7beb8'),
  color: '#fafafa',
  '&:hover': {
    backgroundColor: (props) => (props.isAdded ? '#c7beb8' : '#e10050'),
  },
})

const fromMetricTo2D = (metric) => {
  const width = Math.round(
    parseFloat(metric.replace(/.*Leveys:([\d\.\s]*)cm.*/, '$1'))
  )
  const height = Math.round(
    parseFloat(metric.replace(/.*Korkeus:([\d\.\s]*)cm.*/, '$1'))
  )
  return `${width}x${height}`
}

class MatcherProductRow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      added: false,
      // itemColor: 'white',
      // cartText: 'ADD TO CART',
    }
  }

  componentDidMount() {
    const { cart2, selectedMinicartCategory, rowId } = this.props
    const categoryItems = cart2[selectedMinicartCategory]
    if (
      categoryItems &&
      categoryItems.find(
        (item) => item.matcher_id && item.matcher_id.includes(rowId)
      )
    ) {
      // Is in cart
      this.setState({ added: true })
    } else {
      this.setState({ added: false })
    }

    /*
    if (this.props.cart.hasOwnProperty(this.props.rowId)) {
      this.setState({ added: true })
    } else {
      this.setState({ added: false })
    }
    */
  }

  static getDerivedStateFromProps(props, state) {
    const { cart2, selectedMinicartCategory, rowId } = props
    const categoryItems = cart2[selectedMinicartCategory]
    if (
      categoryItems &&
      categoryItems.find(
        (item) => item.matcher_id && item.matcher_id.includes(rowId)
      )
    ) {
      // Is in cart
      return { added: true }
    } else {
      return { added: false }
    }

    /*
    if (props.cart.hasOwnProperty(props.rowId)) {
      return { added: true }
    } else {
      return { added: false }
    }
    */
  }

  render() {
    const {
      rowId,
      product,
      cart,
      aitoMatches,
      updateCart,
      addItemToCart,
      updateAitoMatches,
      rows,
      openMatchSelect,
      match,
      selectedMinicartCategory,
      t,
    } = this.props
    const partNumber = R.path(['partNumber'], product)
    var filterOut = R.path(['filterOut'], product)
    // const section = R.path(['section'], product)
    const original = R.path(['original'], product)
    // const name = R.pathOr('-', ['name'], original)

    const type = R.pathOr('-', ['type'], original)
    var metric = R.pathOr('-', ['metric'], original)
    metric = metric.replace(new RegExp('<br/><br/>', 'g'), '. ')
    metric = metric.replace(new RegExp('<br/>', 'g'), '. ')
    // const matchName = R.or(R.path(['name'], match), '-')
    const matchSku = R.or(R.path(['sku'], match), '-')
    const matchType = R.or(R.path(['product_type'], match), '-')
    const matchDoorType = R.or(R.path(['door_type'], match), '-')
    const matchColor = R.or(R.path(['color'], match), '-')

    const matchSize =
      R.or(R.path(['cabinet_width'], match), 0) +
      'x' +
      R.or(R.path(['cabinet_height'], match), 0)
    var exceptionProductColor = '-webkit-link'

    const matchName = getProductShortName(match)

    var currentMatch = '-'
    if (type !== '-') {
      currentMatch = matchName
    }
    const helsingoProductInfo =
      type !== '-'
        ? `${matchColor} ${matchDoorType} ${matchType} ${matchSize}`
        : '-'

    if (metric.includes('Korkeus: 9.7 cm')) {
      exceptionProductColor = 'red'
    }

    const isSwitched = product.hits.findIndex((p) => p.sku === match.sku) !== 0
    const color = isSwitched ? 'red' : 'black'

    // if (R.isEmpty(cart)) {
    //   // console.log('empty cart')
    //   if (this.state.cartText !== 'ADD TO CART')
    // this.setState({itemColor: 'white', cartText: 'ADD TO CART'})
    // }

    // if (cart.hasOwnProperty(rowId)) {
    //   this.setState({itemColor: '#8FBC8F', cartText: '  REMOVE   '})
    // } else {
    //   console.log(rowId, cart)
    //   this.setState({itemColor: 'white', cartText: 'ADD TO CART'})
    // }

    const itemToCart = (
      id,
      partNumber,
      cart,
      aitoMatches,
      updateCart,
      updateAitoMatches,
      selectedMinicartCategory,
      addItemToCart
    ) => {
      const newCart = { ...cart }
      const newMatches = aitoMatches

      if (cart[id] == null) {
        const item = {
          sku: matchSku,
          type: matchType,
          product: matchName,
          custom_color: matchColor,
          qty: 1,
          custom_size: matchSize,
          cart_section: selectedMinicartCategory,
        }
        this.setState({ added: true })
        // this.setState({itemColor: '#8FBC8F', cartText: '  REMOVE   '})
        newCart[id] = item
        newMatches[id] = {}
        newMatches[id]['sku'] = matchSku
        newMatches[id]['partNumber'] = partNumber
        filterOut = 1
      } else {
        this.setState({ added: false })
        // this.setState({itemColor: 'white', cartText: 'ADD TO CART'})
        delete newCart[id]
        delete newMatches[id]
      }
      updateCart(newCart)
      updateAitoMatches(newMatches)

      addItemToCart(id, {
        sku: matchSku,
        type: matchType,
        product: matchName,
        custom_color: matchColor,
        qty: 1,
        custom_size: matchSize,
        cart_section: selectedMinicartCategory,
      })
    }

    const selectNewMatch = (rows, currentMatch, original) => {
      openMatchSelect(rows, currentMatch, original)
    }

    return (
      <TableRow
        hover={false}
        style={{
          opacity: filterOut,
          // background: this.state.itemColor,
          position: 'relative',
        }}
      >
        {/* <TableCell style={{color: color}}>{section}</TableCell> */}
        <TableCell>{partNumber}</TableCell>
        <Tooltip title={`${matchName} ${type} ${metric}`}>
          <TableCell>{`${fromMetricTo2D(metric)}`}</TableCell>
        </Tooltip>
        <TableCell style={{ color }}>{t(type)}</TableCell>
        {/* <TableCell style={{color: color}} >{metric}</TableCell> */}
        <Tooltip title={`${matchSku} ${matchName}`}>
          <TableCell
            aria-label="matcher-product-title"
            style={{
              color: isSwitched ? 'red' : '#0288d1',
            }}
            // onClick={() => selectNewMatch(rows, rowId, original)}
          >
            {helsingoProductInfo}
          </TableCell>
        </Tooltip>
        {/* <TableCell style={{minWidth: '75px', color: '-webkit-link', cursor: 'pointer', textDecoration: 'underline'}} onClick={() => itemToCart(rowId, partNumber, cart, aitoMatches, updateCart, updateAitoMatches)}>
                     {this.state.cartText}
          </TableCell> */}
        <TableCell style={{ textAlign: 'center' }}>
          <SwitchFab
            isSwitched={isSwitched}
            aria-label="switch"
            size="small"
            onClick={() => selectNewMatch(rows, rowId, original)}
          >
            <SwapHorizIcon fontSize="large" />
          </SwitchFab>
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          <AddFab
            isAdded={this.state.added}
            aria-label="add"
            size="small"
            onClick={() =>
              itemToCart(
                rowId,
                partNumber,
                cart,
                aitoMatches,
                updateCart,
                updateAitoMatches,
                selectedMinicartCategory,
                addItemToCart
              )
            }
          >
            {this.state.added ? (
              <CheckIcon fontSize="large" />
            ) : (
              <AddIcon fontSize="large" />
            )}
          </AddFab>
          {/* <Chip
            label={this.state.cartText}
            onClick={() =>
              itemToCart(
                rowId,
                partNumber,
                cart,
                aitoMatches,
                updateCart,
                updateAitoMatches
              )
            }
            style={{
              textTransform: 'uppercase',
              backgroundColor:
                this.state.cartText === 'ADD TO CART'
                  ? '#2196f3'
                  : 'rgb(225, 0, 80)',
              color: 'white',
            }}
          /> */}
        </TableCell>
      </TableRow>
    )
  }
}

export default withTranslation()(MatcherProductRow)
