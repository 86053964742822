import * as R from 'ramda'
const API_URL = process.env.REACT_APP_API_URL

export const defaults = {
  method: 'get',
  mode: 'cors',
}

const _fetch = (path, options) => {
  if (R.path(['body'], options)) {
    options.body = JSON.stringify(options.body)
    options.headers = R.merge(R.path(['headers'], options), {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    })
  }

  //options.credentials = 'include'

  return new Promise((resolve, reject) => {
    fetch(API_URL + path, options)
      .then((response) =>
        response.status >= 400 && response.status < 600
          ? reject(new Error(R.path(['statusText'], response)))
          : resolve(response)
      )
      .catch(reject)
  })
}

export const fetchJSON = (path, opt = {}) =>
  _fetch(path, R.merge(defaults, opt))

export const parseJSON = (response) =>
  new Promise((resolve) => {
    response.json().then((json) =>
      resolve({
        status: response.status,
        ok: response.ok,
        json,
      })
    )
  })
