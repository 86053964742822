import * as R from 'ramda'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CloseIcon from '@material-ui/icons/Close'
import Fab from '@material-ui/core/Fab'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import { styled } from '@material-ui/styles'

import IkeaProducts from '../products/IkeaProducts'
import CustomItems from '../home/CustomItems'
import { getProductShortName } from '../../utils/helpers.js'

const CloseFab = styled(Fab)({
  backgroundColor: '#c7beb8',
  color: '#fafafa',
  '&:hover': {
    backgroundColor: '#e10050',
  },
})

const SwapFab = CloseFab

const MatcherProduct = ({
  rows,
  style,
  closeMatchSelect,
  selectMatch,
  currentMatch,
  original,
  cart,
  door,
  color,
}) => {
  var currentRows = rows.slice(0, 5)
  const matches = R.map((row) => {
    const sku = R.path(['sku'], row)
    const probability = Math.round(R.path(['$score'], row) * 100 * 100) / 100
    const name = getProductShortName(row)

    const selectNewMatch = (row, currentMatch) => {
      selectMatch(row, currentMatch)
    }

    return (
      <TableRow
        key={sku}
        style={{ cursor: 'pointer' }}
        hover={true}
        // onClick={() => selectNewMatch(row, currentMatch)}
      >
        <TableCell>{sku}</TableCell>
        <TableCell>{name}</TableCell>
        {/* <TableCell>{R.path(['product_type'], row)}</TableCell>
        <TableCell>{R.path(['door_type'], row)}</TableCell>
        <TableCell>{R.path(['cabinet'], row)}</TableCell>
        <TableCell>{R.path(['color'], row)}</TableCell>
        <TableCell>
          {R.path(['cabinet_width'], row) + 'x' + R.path(['cabinet_height'], row)}
        </TableCell>
        <TableCell>
          {R.path(['door_width'], row) + 'x' + R.path(['door_height'], row)}
        </TableCell> */}
        <TableCell numeric>{probability + '%'}</TableCell>
        <TableCell>
          <SwapFab
            aria-label="swap"
            size="small"
            onClick={() => selectNewMatch(row, currentMatch)}
          >
            <SwapHorizIcon fontSize="large" />
          </SwapFab>
        </TableCell>
      </TableRow>
    )
  })(currentRows)

  const closeSelect = () => {
    closeMatchSelect()
  }

  return (
    <div style={style}>
      <Paper
        style={{
          position: 'relative',
          border: '5px solid rgb(199, 190, 184)',
          boxShadow: 'unset',
          borderRadius: 'unset',
        }}
      >
        <div
          style={{
            position: 'absolute',
            right: '30px',
            top: '10px',
            color: '-webkit-link',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          <CloseFab aria-label="close" onClick={() => closeSelect()}>
            <CloseIcon />
          </CloseFab>
          {/* <a onClick={() => closeSelect()}>CLOSE</a> */}
        </div>
        <Card raised={true}>
          <CardHeader title="IKEA Product" />
          <CardContent>
            <IkeaProducts originals={[original]} />
          </CardContent>
          <CardHeader title="Recommended Helsingö products:" />
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SKU</TableCell>
                  <TableCell>Name</TableCell>
                  {/* <TableCell>Type</TableCell> */}
                  {/* <TableCell>Door type</TableCell> */}
                  {/* <TableCell>Cabinet</TableCell> */}
                  {/* <TableCell>Color</TableCell> */}
                  {/* <TableCell>Cabinet size</TableCell> */}
                  {/* <TableCell>Door size</TableCell> */}
                  <TableCell>Probability</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{matches}</TableBody>
            </Table>
          </CardContent>
          <CardContent>
            <CustomItems
              door={door}
              color={color}
              cart={cart}
              isSwitch={true}
              selectMatch={(row) => selectMatch(row, currentMatch)}
            />
          </CardContent>
        </Card>
      </Paper>
    </div>
  )
}

export default MatcherProduct
