import * as R from 'ramda'
import * as Promise from 'bluebird'
import { fetchProduct, postIkeaPDF } from '../../api/server'
import { push } from 'connected-react-router'

export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const ADD_TO_PRODUCTS = 'ADD_TO_PRODUCTS'
export const REMOVE_FROM_PRODUCTS = 'REMOVE_FROM_PRODUCTS'
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'

export const UPLOAD_PDF = 'UPLOAD_PDF'
export const UPLOAD_CART = 'UPLOAD_CART'

export const SET_FILTER = 'SET_FILTER'
export const REMOVE_FILTER = 'REMOVE_FILTER'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'

const parseSearchFilters = (filters = []) =>
  encodeURIComponent(JSON.stringify(filters))

export const getProduct =
  (id = '', page = 0, updateURL = false, section = 1, position, qty = 1) =>
  (dispatch, getState) => {
    dispatch({ type: FETCH_PRODUCT })

    const { location } = history
    const { products } = getState()
    const filters = R.path(['filters'], products)

    fetchProduct(id, page, filters)
      .then((json) => {
        dispatch({ type: FETCH_PRODUCT + '_SUCCESS' })
        updateURL &&
          push(
            `${R.path(
              ['pathname'],
              location
            )}?page=${page}&filters=${parseSearchFilters(filters)}`
          )
        dispatch({
          type: ADD_TO_PRODUCTS,
          product: R.compose(
            R.assoc('position', position),
            R.assoc('partNumber', id),
            R.assoc('page', page),
            R.assoc('section', section),
            R.assoc('qty', qty)
          )(json),
        })
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PRODUCT + '_FAILED',
          error,
        })
      })
  }

const populatePDFProducts = (filters) => (dispatch, getState) => {
  const { products } = getState()
  products['filters'] = filters
  const objects = R.path(['object'], products)
  const items = Object.values(objects)
  Promise.map(
    items,
    (item) =>
      dispatch(
        getProduct(
          item['partNumber'],
          0,
          false,
          item['section'],
          item['position'],
          item['qty']
        )
      ),
    { concurrency: 1 }
  )
}

export const uploadPDF = (data, mode, filters) => (dispatch) => {
  dispatch(clearProducts())
  dispatch({ type: UPLOAD_PDF })
  postIkeaPDF(data, mode)
    .then((json) => {
      dispatch({
        type: UPLOAD_PDF + '_SUCCESS',
        products: json,
      })
      dispatch(populatePDFProducts(filters))
    })
    .catch((error) => {
      dispatch({
        type: UPLOAD_PDF + '_FAILED',
        error,
      })
    })
}

export const removeProduct =
  (id = '') =>
  (dispatch) =>
    dispatch({
      type: REMOVE_FROM_PRODUCTS,
      partNumber: id,
    })

export const clearProducts = () => (dispatch) =>
  dispatch({ type: CLEAR_PRODUCTS })

export const setFilter =
  (filter = {}) =>
  (dispatch) =>
    dispatch({ type: SET_FILTER, filter })

export const removeFilter =
  (filter = {}) =>
  (dispatch) =>
    dispatch({ type: REMOVE_FILTER, filter })

export const clearFilters = () => (dispatch) =>
  dispatch({ type: CLEAR_FILTERS })
