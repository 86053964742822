import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Fab from '@material-ui/core/Fab'

import DeleteIcon from '@material-ui/icons/Delete'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  confirmIcon: {
    color: 'white',
    backgroundColor: '#f50057',
    '&:hover': {
      color: 'black',
      backgroundColor: 'rgb(199, 190, 184)',
    },
  },
  cancelIcon: {
    color: 'white',
    backgroundColor: '#8bc34a',
    '&:hover': {
      color: 'black',
      backgroundColor: 'rgb(199, 190, 184)',
    },
  },
  dialog: {},
})

const ConfirmDialog = (props) => {
  const classes = useStyles()
  const { onClose, onConfirm, open, id, confirmIcon, clearIcon } = props

  function handleClose() {
    onClose(id)
  }

  function handleConfirmDelete(value) {
    onClose(value)
    onConfirm(value)
  }

  return (
    <Dialog
      name="confirm-delete-dialog"
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      open={open}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id="confirm-dialog-title">Are you sure?</DialogTitle>
      <DialogContent>
        <DialogActions>
          <Fab
            name="cancel-delete-button"
            aria-label="cancel"
            className={classes.cancelIcon}
            onClick={handleClose}
          >
            {!clearIcon && <ClearOutlinedIcon name="cancel-delete-icon" />}
            {clearIcon}
          </Fab>

          <Fab
            name="confirm-delete-button"
            aria-label="delete"
            className={classes.confirmIcon}
            onClick={() => handleConfirmDelete(id)}
          >
            {!confirmIcon && <DeleteIcon name="confirm-delete-icon" />}
            {confirmIcon}
          </Fab>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmDialog
