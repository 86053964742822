import React, { Component } from 'react'
import { uploadPDF } from '../../features/products/productsActions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

class Upload extends Component {
  constructor(props) {
    super(props)
    this.handleUpload = this.handleUpload.bind(this)
  }

  handleUpload(event) {
    const { uploadPDF } = this.props
    const data = new FormData()
    data.append('file', event.target.files[0])
    console.log('DATA', data)
    uploadPDF(data, 'manual')
  }

  render() {
    return (
      <div>
        <input type="file" name="file" onChange={this.handleUpload} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  uploadPDF: (data, mode) => dispatch(uploadPDF(data, mode)),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(Upload)
)
