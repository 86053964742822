import * as R from 'ramda'
import React, { Component } from 'react'

import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import Upload from '../upload/Upload'
import IkeaProducts from './IkeaProducts'

class Products extends Component {
  render() {
    const { products } = this.props
    const originals = R.map(R.prop('original'))(products)
    return (
      <Paper>
        <Card raised={true}>
          <CardHeader title="Ikea Products" />
          <CardContent>
            <Upload />
          </CardContent>
          <CardContent>
            <IkeaProducts originals={originals} />
          </CardContent>
        </Card>
      </Paper>
    )
  }
}

export default Products
