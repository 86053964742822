import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { createTheme, makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol'
import InfoIcon from '@material-ui/icons/Info'
import AccountTree from '@material-ui/icons/AccountTree'
import TestingEnvBanner from './features/home/TestingEnvBanner'
import Amplify from 'aws-amplify'
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifyForgotPassword,
} from '@aws-amplify/ui-react'
import propTypes from 'prop-types'
import amplifyConfig from './utils/AmplifyConfig'
import Routes from './Routes'
import './App.css'

Amplify.configure(amplifyConfig)

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: 'rgb(199, 190, 184)',
    },
  },
  typography: {
    fontFamily: [
      '"Crimson Text"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    subtitle1: {
      color: '#777777;',
      fontSize: '18px',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#fafafa',
        boxShadow: 'none',
      },
      elevation1: {
        // overflowX: 'scroll',
        boxShadow: 'none',
      },
      elevation8: {
        boxShadow: 'none',
      },
    },
    // MuiCard: {
    //   root: {
    //     overflow: 'scroll',
    //   },
    // },
    MuiCardHeader: {
      root: {
        backgroundColor: '#fafafa',
        padding: '4px 8px',
      },
      action: {
        marginTop: 0,
        marginRight: 20,
      },
    },
    MuiCardContent: {
      root: {
        backgroundColor: '#fafafa',
        padding: '16px 16px',
      },
    },
    MuiTable: {
      root: {
        // overflow: 'scroll',
        backgroundColor: '#fafafa',
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: '#fafafa',
        textDecoration: 'none',
        '&.MuiTableRow-hover': {
          '&:hover': {
            backgroundColor: 'rgb(199, 190, 184)',
          },
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '4px 8px',
        '&:last-child': {
          paddingRight: '12px',
        },
        fontSize: '1rem',
      },
      head: {
        fontSize: '1rem',
      },
    },
    MuiButton: {
      root: {
        fontSize: '1rem',
      },
    },
    MuiTabs: {
      root: {
        width: '5vw',
        paddingTop: '10vh',
      },
    },
    MuiTab: {
      root: {
        height: '10vh',
        '&:hover': {
          backgroundColor: '#fafafa',
        },
      },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: '#fafafa',
      },
    },
    MuiTablePagination: {
      caption: {
        fontSize: '1rem',
      },
      select: {
        fontSize: '1rem',
      },
    },
    MuiTooltip: {
      tooltip: {
        maxWidth: 500,
        fontSize: '1rem',
      },
    },
  },
})

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexGrow: 1,
  },
  icon: {
    color: 'black',
    fontSize: '48px',
  },
  appbar: {
    position: 'fixed',
    height: '100%',
    width: '5vw',
    backgroundColor: 'rgb(199, 190, 184)',
  },
  tab: {
    '@media (min-width: 960px)': {
      minWidth: 'initial',
    },
    '&.Mui-selected': {
      backgroundColor: '#fafafa',
    },
    '&.Mui-disabled': {
      opacity: 1,
    },
  },
})

function App({ history, location }) {
  const head = location.pathname
  const [navigation, setNavigation] = useState(!head ? '/' : `${head}`)
  const appENV = process.env.REACT_APP_ENV

  useEffect(() => {
    if (head.includes('matcher')) {
      setNavigation('/matcher')
    }
  }, [head])

  const navigate = (event, value) => {
    setNavigation(value)
    history.push(value)
  }

  const classes = useStyles()

  return (
    <AmplifyAuthenticator>
      <AmplifySignIn
        headerText={`Login to Sales Software ${appENV ? appENV : 'dev'}`}
        slot="sign-in"
        usernameAlias="email"
      >
        {appENV && appENV === 'v2' ? (
          <div slot="secondary-footer-content">Saso 2.0</div>
        ) : (
          <div slot="secondary-footer-content" />
        )}
      </AmplifySignIn>
      <AmplifyForgotPassword slot="forgot-password" usernameAlias="email" />
      <ThemeProvider theme={theme}>
        <div className={`App + ${classes.root}`}>
          {appENV && appENV === 'v1' && <TestingEnvBanner />}
          <AppBar position="static" className={classes.appbar}>
            <Tabs
              centered
              value={navigation}
              orientation="vertical"
              onChange={navigate}
            >
              <Tab
                icon={<ShoppingBasketIcon className={classes.icon} />}
                aria-label="purchase helper"
                className={classes.tab}
                value="/"
              />
              <Tab
                icon={<EuroSymbolIcon className={classes.icon} />}
                aria-label="price estimate"
                className={classes.tab}
                value="/prices"
              />
              <Tab
                icon={<InfoIcon className={classes.icon} />}
                aria-label="pricing info"
                className={classes.tab}
                value="/info"
              />
              <Tab
                icon={<AccountTree className={classes.icon} />}
                aria-label="products admin"
                className={classes.tab}
                value="/productadmin"
              />
            </Tabs>
          </AppBar>
          <Routes />
        </div>
      </ThemeProvider>
    </AmplifyAuthenticator>
  )
}

App.propTypes = {
  history: propTypes.instanceOf(Object).isRequired,
  location: propTypes.instanceOf(Object).isRequired,
}

export default withRouter(App)
