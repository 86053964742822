import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
// import PropTypes from 'prop-types'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import moment from 'moment'

import { Typography, Divider } from '@material-ui/core'
import { triggerProductFetch, getProductDataStats } from '../../api/server'

const ConfirmDialog = (props) => {
  const { onClose, onConfirm, open, id } = props

  function handleClose() {
    onClose(id)
  }

  function handleConfirm(value) {
    onClose(value)
    onConfirm(value)
  }

  return (
    <Dialog
      name="confirm-delete-dialog"
      onClose={handleClose}
      aria-labelledby="confirm-product-fetch-dialog-title"
      open={open}
    >
      <DialogTitle id="confirm-product-fetch-dialog-title">
        Are you sure you want to trigger product fetch from Shopify?
      </DialogTitle>
      <DialogContent>
        <Typography>This might take several minutes.</Typography>
        <DialogActions>
          <Button
            name="cancel-button"
            aria-label="cancel"
            onClick={handleClose}
            style={{ borderRadius: '0px' }}
          >
            No
          </Button>

          <Button
            name="confirm-button"
            aria-label="confirm"
            variant="contained"
            color="secondary"
            onClick={() => handleConfirm(id)}
            style={{ borderRadius: '0px' }}
          >
            Yes
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

const ProductsAdmin = () => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [lastModified, setLastModified] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const stats = await getProductDataStats()
      if (stats && stats.mtime) {
        setLastModified(stats.mtime)
      }
    }
    fetchData()
  }, [])

  return (
    <Paper>
      <Card raised>
        <CardHeader title="Products" />
        <CardContent>
          <ConfirmDialog
            open={confirmOpen}
            id="trigger-product-fetch-dialog"
            onConfirm={triggerProductFetch}
            onClose={() => setConfirmOpen(false)}
            confirmIcon="Yes"
            clearIcon="No"
          />
          <div style={{ margin: '10px' }}>
            <Typography>Product data updated</Typography>
            <Typography>
              {moment(lastModified).format('DD-MM-YYYY HH:mm:ss')}
            </Typography>
          </div>
          <Divider />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setConfirmOpen(true)}
            style={{ borderRadius: '0px', marginTop: '10px' }}
          >
            Trigger fetch from Shopify
          </Button>
        </CardContent>
      </Card>
    </Paper>
  )
}

ProductsAdmin.propTypes = {}

export default ProductsAdmin
