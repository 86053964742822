import React from 'react'
import * as R from 'ramda'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import CartItemRow from './CartItemRow'
import { styled } from '@material-ui/styles'

export const colorMap = {
  'Clean White': '#FFFFFF',
  'Natural White': '#F2F2F2',
  'Blush Rose': '#DBB9B7',
  'Feather Grey': '#CCCCCC',
  'Linen Brown': '#9E9C91',
  'Thermal Grey': '#8E8E89',
  'Polar Grey': '#89898E',
  'Water Green': '#7D8E7C',
  'Petrol Blue': '#58676D',
  'Deep Burgundy': '#663D47',
  'Graphite Grey': '#404040',
  'Midnight Black': '#191919',
  'Natural Oak': '#c7a683',
  'Natural Walnut': '#554747',
  'Polished Aluminium': '#bdbfbd',
  'Polished Brass': '#e1a84b',
  'Smoke Oak': '#8b785c',
  'White Oak': '#d6c49c',
  'Ash Green': '#b4aea2',
  'Ivory Beige': '#d2cfc8',
  'Dusty Rose': '#b6a59d',
}

const StyledTableHead = styled(TableHead)({
  borderTop: '2px solid #c7beb8',
})

const CartItems = ({
  items,
  handleCommentChange,
  handleQuantityChange,
  isDraft,
  removeCartItem,
  shippingMethod,
  handlePdfsChange,
  tableTopPdfData,
  checkedItems,
  handleCheckedChange,
  handleCheckAllChange,
  updateFailed,
  handleDiscountChange,
}) => {
  var grandTotal = 0

  console.log('tabletopPDFData', tableTopPdfData)

  const tableRows = items.map((item) => {
    const sku = item['sku']
    const price = item['unit_price']
    const qty = item['qty']
    const discount = item['discount']
    const discountFraction = discount / 100
    const rowPriceAfterDiscount = qty * price - qty * price * discountFraction
    const rowPrice = discount ? rowPriceAfterDiscount : price * item['qty']
    const isTableTop = item.product
      ? item.product.toLowerCase().includes('tabletop')
      : false
    const tabletopId = isTableTop ? item['tabletopId'] : null
    const itemTableTopData = tabletopId ? tableTopPdfData[tabletopId] : null
    return (
      <CartItemRow
        key={`${item.sku}-${item.custom_size}`}
        price={price}
        item={item}
        changeComment={handleCommentChange}
        changeQuantity={handleQuantityChange}
        changeDiscount={handleDiscountChange}
        isDraft={isDraft}
        isTableTop={isTableTop}
        tableTopPdfData={itemTableTopData}
        removeCartItem={removeCartItem}
        changePdf={handlePdfsChange}
        checked={checkedItems}
        handleCheckedChange={handleCheckedChange}
        itemUpdateFailed={updateFailed.includes(sku)}
        failedRows={updateFailed}
      />
    )
  })

  const subtotalRow = (
    <TableRow key={'subtotal'} hover={false}>
      <TableCell
        colSpan={5}
        style={{
          textAlign: 'right',
          fontSize: 18,
          borderBottom: 'none',
          color: 'rgba(0, 0, 0, 0.54)',
        }}
      >
        Subtotal
      </TableCell>
      <TableCell
        colSpan={3}
        style={{ fontSize: 18, textAlign: 'center', borderBottom: 'none' }}
      >
        {'€ ' + grandTotal.toFixed(2)}
      </TableCell>
    </TableRow>
  )

  const shippingCost = R.isEmpty(shippingMethod) ? 0 : shippingMethod.price
  grandTotal += shippingCost

  return (
    <Table>
      <StyledTableHead>
        <TableRow>
          <TableCell>
            <Checkbox
              checked={checkedItems.length === items.length}
              onChange={handleCheckAllChange}
            />
          </TableCell>
          <TableCell style={{}}>Product</TableCell>
          <TableCell>Comment</TableCell>
          <TableCell>Qty</TableCell>
          <TableCell style={{}}>Unit Price</TableCell>
          <TableCell style={{}}>Subtotal</TableCell>
          <TableCell style={{}}>Discount</TableCell>
          <TableCell />
          {!isDraft && <TableCell />}
          {updateFailed.length > 0 && <TableCell>Problem</TableCell>}
        </TableRow>
      </StyledTableHead>
      <TableBody>{[...tableRows, subtotalRow]}</TableBody>
    </Table>
  )
}

export default CartItems
