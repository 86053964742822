import React from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from '@react-pdf/renderer'
import { chamferPricing } from './Pricing'
import englishPdfTexts from './translations/englishPdfTexts'
import swedishPdfTexts from './translations/swedishPdfTexts'
import finnishPdfTexts from './translations/finnishPdfTexts'

Font.register({
  family: 'Crimson Text',
  fonts: [
    {
      src: `${process.env.PUBLIC_URL}/CrimsonText-Regular.ttf`,
    }, // font-style: normal, font-weight: normal
    {
      src: `${process.env.PUBLIC_URL}/CrimsonText-Bold.ttf`,
      fontWeight: 700,
    },
    {
      src: `${process.env.PUBLIC_URL}/CrimsonText-SemiBoldItalic.ttf`,
      fontStyle: 'italic',
    },
  ],
})

Font.register({
  family: 'Quarto',
  fonts: [
    {
      src: `${process.env.PUBLIC_URL}/Quarto-Light.otf`,
    }, // font-style: normal, font-weight: normal
    {
      src: `${process.env.PUBLIC_URL}/Quarto-Bold.otf`,
      fontWeight: 700,
    },
    {
      src: `${process.env.PUBLIC_URL}/Quarto-LightItalic.otf`,
      fontStyle: 'italic',
    },
  ],
})

Font.register({
  family: 'CircularXX',
  fonts: [
    {
      src: `${process.env.PUBLIC_URL}/CircularXX-Light.otf`,
    }, // font-style: normal, font-weight: normal
    {
      src: `${process.env.PUBLIC_URL}/CircularXX-Bold.otf`,
      fontWeight: 700,
    },
  ],
})

const BORDER_COLOR = '#d4d4d4'
const BORDER_STYLE = 'solid'
const THEME_COLOR = '#c7beb8'
const TABLE_HEADER_COLOR = '#777777'
const FOOTER_COLOR = '#c1b9af'
const FOOTER_COL1_WIDTH = 45
const FOOTER_COL2_WIDTH = 15
const FOOTER_COL_WIDTH = 20
const COL_FULL_WIDTH = 100
const COL1_WIDTH = 60
const COLN_WIDTH = (100 - COL1_WIDTH) / 2

// Create styles
const styles = StyleSheet.create({
  page: {
    lineHeight: 1.6,
    paddingHorizontal: 40,
    paddingBottom: 120,
    fontSize: 10,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    fontFamily: 'CircularXX',
    flexGrow: 0,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
  },
  section: {
    margin: 3,
    padding: 3,
    flexGrow: 1,
  },
  footer: {
    backgroundColor: FOOTER_COLOR,
    border: 'none',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 40,
  },
  footerTextItalic: {
    fontFamily: 'Crimson Text',
    fontStyle: 'italic',
    fontSize: 11,
    color: '#ffffff',
    paddingLeft: 18,
  },
  footerText: {
    fontFamily: 'Crimson Text',
    fontSize: 10,
    fontStyle: 'normal',
    color: '#ffffff',
  },
  footerLogo: {
    height: 24,
    width: 24,
  },
  headerLogo: {
    height: 140,
    width: 140,
    marginLeft: -20,
  },
  footerTable: {
    display: 'table',
    width: 'auto',
    borderBottomStyle: BORDER_STYLE,
    borderTopStyle: 'none',
    borderLeftStyle: 'none',
    borderRightStyle: 'none',
    borderColor: BORDER_COLOR,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    marginVertical: 10,
  },
  footerCol1: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  footerPageNumber: {
    alignItems: 'center',
  },
  footerLogoAndText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  mainHeaderText: {
    fontSize: 22,
    fontFamily: 'Quarto',
  },
  infoSection: {
    marginVertical: 20,
  },
  generalTableInfo: {
    marginBottom: 10,
  },
  textContent: {},
  textContentHeader: {
    fontSize: 16,
    fontWeight: 'normal',
    marginVertical: 20,
  },
  contactInfo: {
    flexDirection: 'row',
    marginTop: 'auto',
    marginBottom: 10,
    justifyContent: 'space-around',
  },
  info: {
    flexDirection: 'row',
  },
  bold: {
    fontWeight: 'bold',
  },
  bottomText: {
    position: 'absolute',
    bottom: 50,
    left: 0,
    right: 0,
    paddingHorizontal: 40,
  },
  italicSmall: {
    fontSize: 10,
    fontStyle: 'italic',
    fontFamily: 'Crimson Text',
  },
  textCircularSmall: {
    fontSize: 8,
    fontWeight: 'bold',
    fontFamily: 'CircularXX',
  },
  table: {
    display: 'table',
    flexGrow: 0,
    width: 'auto',
    borderBottomStyle: BORDER_STYLE,
    borderTopStyle: 'none',
    borderLeftStyle: 'none',
    borderRightStyle: 'none',
    borderColor: BORDER_COLOR,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    marginVertical: 20,
  },
  tableNoMargin: {
    display: 'table',
    flexGrow: 0,
    width: 'auto',
    borderTopStyle: 'none',
    borderLeftStyle: 'none',
    borderRightStyle: 'none',
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    marginVertical: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableRowWideBorder: {
    margin: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: THEME_COLOR,
    borderStyle: BORDER_STYLE,
    borderBottomWidth: 2,
  },
  tableRowMainHeader: {
    margin: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'right',
  },
  tableRowContactInfo: {
    margin: 'auto',
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderStyle: 'none',
    borderBottomWidth: 0,
  },
  tableRowNoBorderSpacing: {
    margin: 'auto',
    paddingVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
    borderStyle: 'none',
    borderBottomWidth: 0,
  },
  tableRowNoBorder: {
    margin: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    borderStyle: 'none',
    borderBottomWidth: 0,
  },
  tableRowDark: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: THEME_COLOR,
  },
  dark: {
    backgroundColor: THEME_COLOR,
  },
  tableCol1: {
    width: COL1_WIDTH + '%',
  },
  tableCol1Footer: {
    width: FOOTER_COL1_WIDTH + '%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tableCol2Footer: {
    width: FOOTER_COL2_WIDTH + '%',
  },
  tableColFooter: {
    width: FOOTER_COL_WIDTH + '%',
  },
  tableColFull: {
    width: COL_FULL_WIDTH + '%',
  },
  tableCol: {
    width: COLN_WIDTH + '%',
  },
  tableCellHeader: {
    fontSize: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: TABLE_HEADER_COLOR,
  },
  tableCell: {
    fontSize: 10,
    marginVertical: 5,
    marginRight: 5,
  },
  tableCellRight: {
    fontSize: 10,
    marginVertical: 5,
    marginRight: 5,
    textAlign: 'right',
  },
  textAlignRightHeader: {
    textAlign: 'right',
    fontSize: 8,
  },
  spacing: {
    height: 10,
  },
})

const tableTopPrice = (tableTop) => {
  const width = Number(tableTop.width)
  const depth = Number(tableTop.depth)
  return Number(
    Number(((width * depth) / 1e6) * Number(tableTop.Material.price)) +
      Number(tableTop.Sink.price) +
      Number(tableTop.Faucet.price) +
      Number(tableTop.Stove.price) +
      chamferPricing(width, depth)(tableTop.Chamfers.price)
  ).toFixed(2)
}

const getDateString = () => {
  var today = new Date()
  var dd = today.getDate()
  var mm = today.getMonth() + 1 //January is 0!
  var yyyy = today.getFullYear()

  if (mm < 10) {
    mm = '0' + mm
  }
  return dd + '.' + mm + '.' + yyyy
}

const getDatePlusMonths = (plusMonths) => {
  var date = new Date()
  var newDate = new Date(date.setMonth(date.getMonth() + plusMonths))
  var dd = newDate.getDate()
  var mm = newDate.getMonth() + 1
  var yyyy = newDate.getFullYear()

  if (mm < 10) {
    mm = '0' + mm
  }
  return dd + '.' + mm + '.' + yyyy
}

const getTextContent = (language) => {
  if (language && language === 1) {
    return finnishPdfTexts
  } else if (language && language === 3) {
    return swedishPdfTexts
  }
  return englishPdfTexts
}

const getLabelTranslation = (language) => {
  console.log(language)
  if (language && language === 1) {
    return 'label_fi'
  } else if (language && language === 3) {
    return 'label_se'
  }
  return 'label'
}

const getTabletopStatsFormatted = (tabletop, language) => {
  console.log('tabletop', tabletop)
  const languageLabel = getLabelTranslation(language)
  const sink =
    tabletop.Sink && tabletop.Sink.price && tabletop.Sink.price !== 0
      ? `, ${tabletop.Sink[languageLabel]}`
      : ''
  const stove =
    tabletop.Stove && tabletop.Stove.price && tabletop.Stove.price !== 0
      ? `, ${tabletop.Stove[languageLabel]}`
      : ''
  const faucet =
    tabletop.Faucet && tabletop.Faucet.price && tabletop.Faucet.price !== 0
      ? `, ${tabletop.Faucet[languageLabel]}`
      : ''
  const chamfers =
    tabletop.Chamfers &&
    tabletop.Chamfers.price &&
    tabletop.Chamfers.price !== 0
      ? `, ${tabletop.Chamfers[languageLabel]}`
      : ''
  return `${tabletop.Material[languageLabel]} ${tabletop.width} x ${tabletop.depth} mm${sink}${stove}${faucet}${chamfers}`
}

// Create Document Component
export const TableTopInvoicePdf = ({
  tableTopStats,
  totalPrice,
  extraHoles,
  deliveryAndInstallation,
  distance,
  email,
  id,
  language,
}) => (
  <Document title="Tabletop invoice">
    <Page size="A4" style={styles.page}>
      {/* Header */}
      <View fixed>
        <View style={styles.tableNoMargin}>
          <View style={styles.tableRowMainHeader}>
            <View style={styles.tableCol1}>
              <Image
                src={`${process.env.PUBLIC_URL}/H_logot3.jpg`}
                style={styles.headerLogo}
              />
            </View>
            <View style={styles.tableCol}>
              <Text
                fixed
                style={styles.textAlignRightHeader}
                render={(props) => {
                  if (props.pageNumber == 1) {
                    return `ID: ${id}`
                  } else {
                    return ''
                  }
                }}
              />
            </View>
            <View style={styles.tableCol}>
              <Text
                fixed
                style={styles.textAlignRightHeader}
                render={(props) => {
                  if (props.pageNumber == 1) {
                    return getDateString()
                  } else {
                    return ''
                  }
                }}
              />
            </View>
          </View>
        </View>
      </View>

      {/* Page title */}
      <View>
        <View>
          <Text style={styles.mainHeaderText}>
            {language
              ? getTextContent(language).main_title
              : 'Order confirmation'}
          </Text>
        </View>

        {/* Contact info table */}
        <View style={styles.table}>
          <View style={styles.tableRowContactInfo}>
            <View style={styles.tableCol1}>
              <View style={styles.infoSection}>
                <Text>A.S. Helsingö</Text>
                <Text>Fabianinkatu 4</Text>
                <Text>00130, Helsinki</Text>
                <Text>+358 20 718 1510</Text>
                <Text>info@helsingo.com</Text>
              </View>
            </View>
            <View style={styles.tableCol1}>
              <View style={styles.infoSection}>
                <Text>{email}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.spacing} />

      {/* Column titles */}
      <View style={styles.table}>
        <View style={styles.tableRowNoBorderSpacing}>
          <View style={styles.tableCol1}>
            <Text style={styles.bold}>
              {language ? getTextContent(language).items : 'Items'}
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellRight}>
              <Text style={styles.bold}>VAT-%</Text>
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellRight}>
              <Text style={styles.bold}>
                {language
                  ? getTextContent(language).price.toUpperCase()
                  : 'EUR'}
              </Text>
            </Text>
          </View>
        </View>
      </View>

      {/* Tabletop items */}
      {Object.keys(tableTopStats).map((tableTopKey) => {
        const tableTop = tableTopStats[tableTopKey]
        const materialName =
          tableTop['Material'] &&
          tableTop['Material'][getLabelTranslation(language)]
        const tabletopTranslation =
          getTextContent(language).tabletop || 'tabletop'
        return (
          <View style={styles.table} key={tableTopKey}>
            <View style={styles.tableRow}>
              <View style={styles.tableColFull}>
                <Text
                  style={styles.bold}
                >{`A.S. Helsingö ${tabletopTranslation} / ${materialName}`}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>
                  {getTabletopStatsFormatted(tableTop, language)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellRight}>24%</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellRight}>
                  {tableTopPrice(tableTop)}
                </Text>
              </View>
            </View>
          </View>
        )
      })}

      {/* Extra openings */}
      {Object.keys(extraHoles).length > 0 && (
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColFull}>
              <Text style={styles.bold}>
                {language
                  ? getTextContent(language).extra_openings
                  : 'Extra openings'}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>
                {Object.keys(extraHoles).map((extraHolesKey, i) => {
                  // TODO: Check if i is same than extraholes length and only put "," if not last
                  const extraHole = extraHoles[extraHolesKey]
                  return extraHole.qty > 0
                    ? `${extraHole.qty}kpl ${
                        extraHole[getLabelTranslation(language)]
                      }`
                    : ''
                })}{' '}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>24%</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>
                {Number(
                  Object.keys(extraHoles).reduce(
                    (total, holes) =>
                      total + extraHoles[holes].price * extraHoles[holes].qty,
                    0
                  )
                ).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>
      )}

      {/* Services */}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColFull}>
            <Text style={styles.bold}>
              {language ? getTextContent(language).services : 'Services'}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>
              {language
                ? getTextContent(language).measurement_shipping_installation
                : 'Measurement, shipping and istallation'}
              {` ${distance} km`}
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellRight}>24%</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCellRight}>{deliveryAndInstallation}</Text>
          </View>
        </View>

        {/* Grand total */}
        <View style={styles.table} wrap={false}>
          <View style={styles.tableRowNoBorder}>
            <View style={styles.tableCol1} />
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>
                <Text style={styles.bold}>
                  {language
                    ? getTextContent(language).total_without_tax
                    : 'Grand total without tax'}
                </Text>
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>
                <Text style={styles.bold}>
                  {Number(Number(totalPrice) / 1.24).toFixed(2)} EUR
                </Text>
              </Text>
            </View>
          </View>
          <View style={styles.tableRowNoBorder}>
            <View style={styles.tableCol1} />
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>
                <Text style={styles.bold}>
                  {language ? getTextContent(language).total_vat : 'Total VAT'}
                </Text>
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>
                <Text style={styles.bold}>
                  {Number(
                    Number(totalPrice) - Number(Number(totalPrice) / 1.24)
                  ).toFixed(2)}{' '}
                  EUR
                </Text>
              </Text>
            </View>
          </View>
          <View style={styles.tableRowNoBorder}>
            <View style={styles.tableCol1} />
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>
                <Text style={styles.bold}>
                  {language
                    ? getTextContent(language).grand_total
                    : 'Grand total'}
                </Text>
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>
                <Text style={styles.bold}>
                  {Number(totalPrice).toFixed(2)} EUR
                </Text>
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.spacing} />

      {/* Footer */}
      {/*
      <View fixed style={styles.footer}>
        <View style={styles.footerTable}>
          <View style={styles.tableRowNoBorder}>
            <View style={styles.tableCol1Footer}>
              <Image
                src={`${process.env.PUBLIC_URL}/H_logot6.jpg`}
                style={styles.footerLogo}
              />
              <Text style={styles.footerTextItalic}>Feels like Sunday</Text>
            </View>
            <View style={styles.tableCol2Footer}>
              <View style={styles.footerText}>
                <Text
                  fixed
                  style={styles.footerText}
                  render={(props) => {
                    console.log(props)
                    return `${props.pageNumber} • ${props.totalPages}`
                  }}
                />
              </View>
            </View>
            <View style={styles.tableColFooter}>
              <Text style={styles.tableCellRight}>
                <Text style={styles.footerText}>+358 20 718 1510</Text>
              </Text>
            </View>
            <View style={styles.tableColFooter}>
              <Text style={styles.tableCellRight}>
                <Text style={styles.footerText}>info@helsingo.com</Text>
              </Text>
            </View>
          </View>
        </View>
      </View>
      */}

      {/* Bottom info text*/}
      <View fixed style={styles.bottomText}>
        <Text
          fixed
          style={styles.textCircularSmall}
          render={(props) => {
            return props.pageNumber !== props.totalPages
              ? getTextContent(language).next_page_info
              : ''
          }}
        />
      </View>

      {/* Instructions text content */}
      <View style={styles.textContent} break>
        <Text style={styles.textContentHeader}>
          {getTextContent(language).title}
        </Text>
        {getTextContent(language) &&
          getTextContent(language)['chapters'].map((section, i) => (
            <View key={i}>
              <Text>{section}</Text>
              <View style={styles.section} />
            </View>
          ))}
        {!getTextContent(language) && (
          <>
            <Text>
              Our recommendation is that the measuring of the worktop is
              performed by our partner, when kitchen frames, doors and cover
              panels are in place. If measuring is done before mounting the
              cover panels, you are responsible for notifying us about this.
            </Text>
            <View style={styles.section} />
            <Text>
              The delivery time varies between the different worktop materials.
              We recommend that you order the worktops together with other
              A.S.Helsingö products to make sure that the material is ready in
              stock for processing after the measuring. The delivery time from
              measuring to mounting is 2 weeks. Delivery time is exceptionally
              longer, 4-8 weeks for Colonial White & Coral Clay.
            </Text>
            <View style={styles.section} />
            <Text>
              Note that worktops made of natural stone and quartz, the pattern
              of the surface, texture and color can be slightly different than
              in the sample pieces.
            </Text>
            <View style={styles.section} />
            <Text>
              The cost estimate can change after the measuring if the actual
              measurements differ significantly from those given in the
              drawings. The offer includes one visit for measuring, one for
              mounting and one for the delivery. Every additional visit will be
              charged separately.
            </Text>
            <View style={styles.section} />
            <Text>
              Please note, that the mounting of the worktop does not include the
              following:
            </Text>
            <Text>- Removing and disposal of the current worktop</Text>
            <Text>
              - Puddling the opening between the mounted product and the wall
            </Text>
            <Text>
              - Switching off and on the water and electricity systems
            </Text>

            <View style={styles.section} />
            <Text>
              We will send you detailed information about the measuring and
              mounting after the order.
            </Text>
          </>
        )}
      </View>
    </Page>
  </Document>
)
