import * as R from 'ramda'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useTranslation } from 'react-i18next'

const IkeaProductRow = ({ original }) => {
  const { t } = useTranslation()
  const partNumber = R.path(['partNumber'], original)
  const name = R.pathOr('', ['name'], original)
  const type = R.pathOr('', ['type'], original)
  // const rawPrice = R.pathOr('', ['rawPrice'], original)
  const metric = R.pathOr('', ['metric'], original).replace(
    new RegExp('<br/>', 'g'),
    '. '
  )
  return (
    <TableRow hover={false}>
      <TableCell>{partNumber}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{t(type)}</TableCell>
      {/* <TableCell numeric>{rawPrice}</TableCell> */}
      <TableCell>{metric}</TableCell>
    </TableRow>
  )
}

const IkeaProducts = ({ originals }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>SKU</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Type</TableCell>
          {/* <TableCell numeric>IKEA Price</TableCell> */}
          <TableCell>Metric</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {R.addIndex(R.map)((original, i) => {
          return <IkeaProductRow key={i} original={original} />
        })(originals)}
      </TableBody>
    </Table>
  )
}

export default IkeaProducts
