/* eslint-disable */
import { fetchJSON, parseJSON } from './fetchUtil'
import { Auth } from 'aws-amplify'
import 'whatwg-fetch'

const getAuthenticatedUser = async () => {
  const userData = await Auth.currentAuthenticatedUser()
  return userData && userData.signInUserSession
}

export function getOriginalPdf(id, options = {}) {
  return new Promise((resolve, reject) => {
    // Gets the original customer ikea plan (pdf)
    getAuthenticatedUser()
      .then((user) => {
        const URL = `/api/uploadPDFs/${id}.pdf`
        const options = {
          method: 'GET',
          headers: { Authorization: `jwt ${user.accessToken.jwtToken}` },
        }
        console.log(options)
        return fetch(URL, options)
      })
      .then((response) => response.blob())
      .then(async (blob) => {
          let fileType = ''
          try {
              await blob.arrayBuffer()
                  .then(buffer => {
                      let arr = (new Uint8Array(buffer)).subarray(0, 4);
                      let header = ''
                      for (let i = 0; i < arr.length; i++) {
                          header += arr[i].toString(16);
                      }

                      switch (header) {
                          case '89504e47':
                              fileType = 'png'
                              break;
                          case '47494638':
                              fileType = 'gif'
                              break;
                          case 'ffd8ffe0':
                          case 'ffd8ffe1':
                          case 'ffd8ffe2':
                          case 'ffd8ffe3':
                          case 'ffd8ffe8':
                              fileType = 'jpeg'
                              break;
                          default:
                              fileType = 'pdf' // Use the pdf as fallback
                              break;
                      }
                  })
          } catch (e) {
              console.error('Error when trying to resolve file type', e)
          }

          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]))
          const link = document.createElement('a')
          link.href = url
          if (fileType) {
              link.setAttribute('download', `${id}.${fileType}`)
          } else {
              link.setAttribute('download', `${id}.pdf`)
          }

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
      .catch(reject)
  })
}

export function fetchProduct(id = '', page = 0, filters = [], options = {}) {
  return new Promise((resolve, reject) => {
    const filtersString = encodeURIComponent(JSON.stringify(filters))
    getAuthenticatedUser()
      .then((user) => {
        options.headers = {
          Authorization: `jwt ${user.accessToken.jwtToken}`,
        }
        return fetchJSON(
          `/ikea/product/${id}?page=${page}&filters=${filtersString}`,
          options
        )
      })
      .then(parseJSON)
      .then((response) =>
        response.ok ? resolve(response.json) : reject(response)
      )
      .catch(reject)
  })
}

export function fetchAllMatches(id = '', options = {}) {
  // Fetches all matches for existing customer (id)
  return new Promise((resolve, reject) => {
    getAuthenticatedUser()
      .then((user) => {
        options.headers = {
          Authorization: `jwt ${user.accessToken.jwtToken}`,
        }
        return fetchJSON(`/ikea/products/all/${id}`, options)
      })
      .then(parseJSON)
      .then((response) =>
        response.ok ? resolve(response.json) : reject(response)
      )
      .catch(reject)
  })
}

export function postIkeaPDF(data = null, mode) {
  return new Promise((resolve, reject) => {
    getAuthenticatedUser()
      .then((user) => {
        var URL
        var options
        if (mode === 'matcher') {
          URL = '/api/ikea/matcher'
          options = {
            method: 'POST',
            body: data,
            headers: {
              'Content-Type': 'application/json',
              Authorization: `jwt ${user.accessToken.jwtToken}`,
            },
          }
        } else {
          URL = '/api/ikea/pdf'
          options = { method: 'POST', body: data }
        }

        return fetch(URL, options)
      })
      .then(parseJSON)
      .then((response) =>
        response.ok ? resolve(response.json) : reject(response)
      )
      .catch(reject)
  })
}

/*
Create new row in customer list view by uploading plan PDF
and adding customer data
*/
export const uploadIkeaPDF = (data, mode) => {
  return new Promise((resolve, reject) => {
    getAuthenticatedUser()
      .then((user) => {
        let URL
        if (mode === 'price') {
          URL = '/api/ikea/pdf/price'
        } else {
          URL = '/api/ikea/pdf/upload'
        }
        const options = {
          method: 'POST',
          body: data,
          headers: { Authorization: `jwt ${user.accessToken.jwtToken}` },
        }
        return fetch(URL, options)
      })
      .then((response) => {
        response.ok ? resolve(response.json) : reject(response)
      })
      .catch(reject)
  })
}

/*
New upload item for Shopify-integration
*/
export const uploadIkeaPDFShopify = (data, mode) => {
  return new Promise((resolve, reject) => {
    getAuthenticatedUser()
      .then((user) => {
        const URL = '/api/saso/paos'

        const options = {
          method: 'POST',
          body: data,
          headers: { Authorization: `jwt ${user.accessToken.jwtToken}` },
        }
        return fetch(URL, options)
      })
      .then((response) => {
        response.ok ? resolve(response.json) : reject(response)
      })
      .catch(reject)
  })
}

/*
Get uploaded item by id
*/
export const getUploadItemById = (id) => {
  return new Promise((resolve, reject) => {
    getAuthenticatedUser()
      .then((user) => {
        const URL = `/api/ikea/pdf/upload/${id}`
        const options = {
          method: 'GET',
          headers: { Authorization: `jwt ${user.accessToken.jwtToken}` },
        }
        console.log(options)
        return fetch(URL, options)
      })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch(reject)
  })
}

export function postNewCart(cart, matches, upload, id, shopifyDraftId) {
  return new Promise((resolve, reject) => {
    getAuthenticatedUser()
      .then((user) => {
        // const URL = '/api/ikea/pdf/cart'
        console.log('shopify draft id', shopifyDraftId)
        const URL = '/api/saso/send_cart'
        const data = {
          data: cart,
          matches: matches,
          upload: upload,
          id: id,
          shopify_draft_id: shopifyDraftId,
        }
        console.log('cart sending data', data)
        const options = {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `jwt ${user.accessToken.jwtToken}`,
          },
        }
        return fetch(URL, options)
      })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch(reject)
  })
}

export function getShippingMethods(cart) {
  return new Promise((resolve, reject) => {
    getAuthenticatedUser()
      .then((user) => {
        const URL = '/api/ikea/pdf/shippingmethod'
        const data = { data: cart }
        const options = {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `jwt ${user.accessToken.jwtToken}`,
          },
        }
        return fetch(URL, options)
      })
      .then((response) => response.json())
      .then((data) => {
        console.log(data), resolve(data)
      })
      .catch(reject)
  })
}

export function deleteUploadItem(id) {
  return new Promise((resolve, reject) => {
    getAuthenticatedUser()
      .then((user) => {
        const URL = `/api/ikea/pdf/upload/${id}`
        const options = {
          method: 'DELETE',
          headers: { Authorization: `jwt ${user.accessToken.jwtToken}` },
        }
        return fetch(URL, options)
      })

      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch(reject)
  })
}

export function triggerProductFetch() {
  return new Promise((resolve, reject) => {
    getAuthenticatedUser()
      .then((user) => {
        const URL = '/api/saso/products/trigger_fetch'
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `jwt ${user.accessToken.jwtToken}`,
          },
        }
        return fetch(URL, options)
      })
      .then((response) => response.json())
      .then((data) => {
        return resolve(data)
      })
      .catch(reject)
  })
}

export function getAllProducts() {
  return new Promise((resolve, reject) => {
    getAuthenticatedUser()
      .then((user) => {
        const URL = '/api/saso/products'
        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `jwt ${user.accessToken.jwtToken}`,
          },
        }
        return fetch(URL, options)
      })
      .then((response) => response.json())
      .then((data) => {
        return resolve(JSON.parse(data.products))
      })
      .catch(reject)
  })
}

export function getProductDataStats() {
  return new Promise((resolve, reject) => {
    getAuthenticatedUser()
      .then((user) => {
        const URL = '/api/saso/products/stats'
        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `jwt ${user.accessToken.jwtToken}`,
          },
        }
        return fetch(URL, options)
      })
      .then((response) => response.json())
      .then((data) => {
        return resolve(data.stats)
      })
      .catch(reject)
  })
}

export function convertPriceEstimateToPaos(id) {
  return new Promise((resolve, reject) => {
    getAuthenticatedUser()
      .then((user) => {
        const URL = '/api/saso/convert_pe'
        const options = {
          method: 'POST',
          body: JSON.stringify({ id }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `jwt ${user.accessToken.jwtToken}`,
          },
        }
        return fetch(URL, options)
      })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        console.log(data)
        return resolve(data)
      })
      .catch(reject)
  })
}
