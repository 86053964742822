import React, { Component } from 'react'
import * as R from 'ramda'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import Fab from '@material-ui/core/Fab'
import RefreshIcon from '@material-ui/icons/Refresh'
import { styled } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CardHeader from '@material-ui/core/CardHeader'

import CustomProductRow from '../products/CustomProductRow'
import { Auth } from 'aws-amplify'

const getAuthenticatedUser = async () => {
  const userData = await Auth.currentAuthenticatedUser()
  return userData && userData.signInUserSession
}

const ClearFab = styled(Fab)({
  backgroundColor: '#c7beb8',
  color: '#fafafa',
  '&:hover': {
    backgroundColor: '#e10050',
  },
})

const CustomSideFormControl = styled(FormControlLabel)({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '4px',
  marginLeft: '0px',
  marginTop: '8px',
  marginBottom: '4px',
  width: '100%',
})

const getWidthOptions = (shopifyProducts) => {
  // width options
  return shopifyProducts
    .reduce(
      (acc, c) =>
        acc.includes(c.cabinet_width) || c.cabinet_width === 'Custom'
          ? acc
          : [...acc, c.cabinet_width],
      []
    )
    .sort((a, b) => parseInt(a) - parseInt(b))
  // console.log(widthOptions, widthOptions.length)
}

const getHeightOptions = (shopifyProducts) => {
  return shopifyProducts
    .reduce(
      (acc, c) =>
        acc.includes(c.cabinet_height) || c.cabinet_height === 'Custom'
          ? acc
          : [...acc, c.cabinet_height],
      []
    )
    .sort((a, b) => parseInt(a) - parseInt(b))
}

const getColorOptions = (shopifyProducts) => {
  return shopifyProducts
    .reduce((acc, c) => (acc.includes(c.color) ? acc : [...acc, c.color]), [])
    .sort()
}

const getDoorTypeOptions = (shopifyProducts) => {
  return shopifyProducts
    .reduce(
      (acc, c) => (acc.includes(c.door_type) ? acc : [...acc, c.door_type]),
      []
    )
    .sort()
}

const getCategoryOptions = (shopifyProducts) => {
  return shopifyProducts
    .reduce(
      (acc, c) =>
        acc.includes(c.product_category) ? acc : [...acc, c.product_category],
      []
    )
    .sort()
}

const getTypeOptions = (shopifyProducts) => {
  return shopifyProducts
    .reduce(
      (acc, c) =>
        acc.includes(c.product_type) ? acc : [...acc, c.product_type],
      []
    )
    .filter((item) => item)
    .sort()
}

class CustomItems extends Component {
  constructor(props) {
    super(props)
    this.handleTypeChange = this.handleTypeChange.bind(this)
    this.handleCustomProductChange = this.handleCustomProductChange.bind(this)
    this.handleDoorTypeChange = this.handleDoorTypeChange.bind(this)
    this.handleColorChange = this.handleColorChange.bind(this)
    this.handleWidthChange = this.handleWidthChange.bind(this)
    this.handleHeightChange = this.handleHeightChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClearFilter = this.handleClearFilter.bind(this)
    this.filterProducts = this.filterProducts.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this)

    this.state = {
      type: null,
      typeInput: '',
      width: null,
      widthInput: '',
      height: null,
      heightInput: '',
      category: '',
      door: '',
      color: '',
      searchTerm: '',
      customProduct: false,
      productsCount: 0,
      products: [],
      doorTypeOptions: [],
      colorOptions: [],
      typeOptions: [],
      widthOptions: [],
      heightOptions: [],
      categoryOptions: [],
      page: 0,
    }
  }

  componentWillReceiveProps(nextProps, prevState) {
    /*
    console.log(
      'componentWillReceiveProps',
      nextProps.door,
      this.state.door,
      nextProps.color,
      this.state.color
    )
    */

    /*
    We want to update color and door model filters
    only once, if empty strings are in state and
    someting else is coming from the props. After initial
    update, we only want to use component current state.
    */
    if (
      this.state.door === '' &&
      nextProps.door &&
      nextProps.door !== this.state.door &&
      this.state.category === ''
    ) {
      console.log(
        'door model was in incoming props and has changed to',
        nextProps.door
      )
      this.setState({ door: nextProps.door }, () => this.handleChanges())
    }

    if (
      this.state.color === '' &&
      nextProps.color &&
      nextProps.color !== this.state.color &&
      this.state.category === ''
    ) {
      console.log(
        'color was in incoming props and has changed to',
        nextProps.color
      )
      this.setState({ color: nextProps.color }, () => this.handleChanges())
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { shopifyProducts } = this.props
    if (
      prevProps.shopifyProducts &&
      shopifyProducts &&
      prevProps.shopifyProducts.length !== shopifyProducts.length
    ) {
      // ShopifyProducts changed, let's reduce new filter options
      const typeOptions = getTypeOptions(shopifyProducts)
      // console.log('TYPE OPTIONS', typeOptions)

      const categoryOptions = getCategoryOptions(shopifyProducts)
      // console.log('CATEGORY OPTIONS', categoryOptions)

      // door type options
      const doorTypeOptions = getDoorTypeOptions(shopifyProducts)
      // console.log('DOORTYPE OPTIONS', doorTypeOptions)

      // color options
      const colorOptions = getColorOptions(shopifyProducts)
      // console.log('COLOR OPTIONS', colorOptions)

      // width options
      const widthOptions = getWidthOptions(shopifyProducts)
      // console.log('WIDTH OPTIONS', widthOptions)

      // height options
      const heightOptions = getHeightOptions(shopifyProducts)
      // console.log('HEIGHT OPTIONS', heightOptions)

      this.setState(
        {
          heightOptions,
          widthOptions,
          typeOptions,
          categoryOptions,
          colorOptions,
          doorTypeOptions,
        },
        () => this.handleChanges()
      )
    }
  }

  filterProducts() {
    if (
      // Set maximun width and height for custom products by filtering all
      // options out when measurements are over the limits
      // max width or height is 250 and max width*height is 250*110
      (this.state.customProduct && Number(this.state.height) > 250) ||
      Number(this.state.width) > 250 ||
      Number(this.state.height) * Number(this.state.width) > 250 * 110
    ) {
      return []
    }
    return this.props.shopifyProducts
      .filter((p) =>
        this.state.type ? ['', p.product_type].includes(this.state.type) : true
      )
      .filter((p) => ['', p.color].includes(this.state.color))
      .filter((p) => ['', p.door_type].includes(this.state.door))
      .filter((p) => ['', p.product_category].includes(this.state.category))
      .filter((p) =>
        ['', p.parent_product_title, p.sku].some((item) =>
          item.toUpperCase().includes(this.state.searchTerm.toUpperCase())
        )
      )
      .filter((p) =>
        this.state.customProduct
          ? ['', p.cabinet_height].includes('custom')
          : true
      )
      .map((p) =>
        this.state.customProduct &&
        this.state.height &&
        p.cabinet_height === 'custom'
          ? { ...p, cabinet_height: this.state.height }
          : p
      )
      .map((p) =>
        this.state.customProduct &&
        this.state.width &&
        p.cabinet_width === 'custom'
          ? { ...p, cabinet_width: this.state.width }
          : p
      )
      .filter(
        (p) =>
          this.state.height
            ? ['', p.cabinet_height].includes(this.state.height)
            : true
        // || (this.state.height && p.cabinet_height === 'Custom')
      )
      .filter(
        (p) =>
          this.state.width
            ? ['', p.cabinet_width].includes(this.state.width)
            : true
        // || (this.state.width && p.cabinet_width === 'Custom')
      )
  }

  handleChanges() {
    const products = this.filterProducts()
    /*
    const newDoorTypeOptions = doorTypeOptions.filter(
      (o) => o === '' || products.some((p) => p.door_type === o)
    )
    const newColorOptions = colorOptions.filter(
      (o) => o === '' || products.some((p) => p.color === o)
    )
    const newTypeOptions = typeOptions.filter(
      (o) => o === '' || products.some((p) => p.product_type === o)
    )
    const newWidthOptions = widthOptions.filter(
      (o) => o === '' || helsingoProducts.some((p) => p.cabinet_width === o)
    )
    const newHeightOptions = heightOptions.filter(
      (o) => o === '' || helsingoProducts.some((p) => p.cabinet_height === o)
    )
    const newCategoryOptions = categoryOptions.filter(
      (o) => o === '' || products.some((p) => p.product_category_0 === o)
    )
    */
    // Update: Removed option filtering since it's not very intuitive
    this.setState({
      // type,
      products,
      //categoryOptions: newCategoryOptions,
      //doorTypeOptions: newDoorTypeOptions,
      //colorOptions: newColorOptions,
      //typeOptions: newTypeOptions,
      //widthOptions: newWidthOptions,
      //heightOptions: newHeightOptions,
      page: 0,
    })
  }

  handleTypeChange(newType) {
    this.setState(
      {
        type: newType ? newType : '',
      },
      () => this.handleChanges()
    )
  }

  handleCategoryChange(event) {
    // This is an exeption
    // clear other filters when category is changed
    const { shopifyProducts } = this.props
    this.setState(
      {
        category: event.target.value,
        type: null,
        width: '',
        height: '',
        door: '',
        color: '',
        searchTerm: '',
        widthOptions: getWidthOptions(shopifyProducts),
        heightOption: getHeightOptions(shopifyProducts),
      },
      () => this.handleChanges()
    )
  }

  handleCustomProductChange(event) {
    this.setState(
      {
        // height: '',
        // width: '',
        customProduct: event.target.checked,
      },
      () => this.handleChanges()
    )
  }

  handleDoorTypeChange(event) {
    // this.setState({door: event.target.value})
    // const door = event.target.value
    this.setState({ door: event.target.value }, () => this.handleChanges())
  }

  handleColorChange(event) {
    this.setState({ color: event.target.value }, () => this.handleChanges())
  }

  handleWidthChange(newWidth) {
    console.log('event', newWidth)
    // Also filter out impossible height options ( width - height combos)
    const { shopifyProducts } = this.props
    const heightOptions = getHeightOptions(shopifyProducts)
    const newHeightOptions = heightOptions.filter(
      (o) =>
        o === '' ||
        shopifyProducts.some(
          (p) => p.cabinet_height === o && p.cabinet_width === newWidth
        )
    )
    this.setState(
      {
        width: newWidth,
        heightOptions: newWidth ? newHeightOptions : heightOptions,
      },
      () => this.handleChanges()
    )
  }

  handleHeightChange(newHeight) {
    // Also filter out impossible width options ( width - height combos)
    const { shopifyProducts } = this.props
    const widthOptions = getWidthOptions(shopifyProducts)
    const newWidthOptions = widthOptions.filter(
      (o) =>
        o === '' ||
        shopifyProducts.some(
          (p) => p.cabinet_width === o && p.cabinet_height === newHeight
        )
    )
    this.setState(
      {
        height: newHeight,
        widthOptions: newHeight ? newWidthOptions : widthOptions,
      },
      () => this.handleChanges()
    )
  }

  handleSearchTermChange(event) {
    this.setState({ searchTerm: event.target.value }, () =>
      this.handleChanges()
    )
  }

  handleClearFilter() {
    const { shopifyProducts } = this.props
    const widthOptions = getWidthOptions(shopifyProducts)
    const heightOptions = getHeightOptions(shopifyProducts)

    this.setState(
      {
        type: null,
        width: null,
        height: null,
        door: '',
        color: '',
        category: '',
        searchTerm: '',
        customProduct: false,
        widthOptions,
        heightOptions,
      },
      () => this.handleChanges()
    )
  }

  handleSubmit(event) {
    var custom = 'normal'
    var width = false
    var height = false
    if (this.state.customProduct) {
      custom = 'custom'
      width = this.state.width
      height = this.state.height
    }
    const params =
      '/' +
      custom +
      '/' +
      this.state.type +
      '/' +
      this.state.door +
      '/' +
      this.state.color +
      '/' +
      this.state.width +
      '/' +
      this.state.height

    getAuthenticatedUser()
      .then((user) => {
        return fetch('/api/helsingo/product' + params, {
          method: 'GET',
          headers: { Authorization: `jwt ${user.accessToken.jwtToken}` },
        })
      })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            const hits = R.path(['hits'], data)
            if (hits.length > 0) {
              if (hits.length > 1) {
                this.props.openMatchSelect(
                  hits,
                  'custom' + this.state.productsCount,
                  []
                )
              } else {
                const row = hits[0]
                this.props.selectMatch(
                  row,
                  'custom' + this.state.productsCount,
                  width,
                  height
                )
              }
              this.setState({
                productsCount: Object.keys(this.props.matches).length,
              })
            } else {
              alert("Couldn't find products with the given attributes")
            }
          })
        } else {
          throw new Error('Something went wrong ...')
        }
      })
      .catch((error) => new Error('Something went wrong ...'))
    event.preventDefault()
  }

  handleChangePage(event, newPage) {
    this.setState({ page: newPage })
  }

  render() {
    const {
      cart,
      updateCart,
      matches,
      isSwitch,
      selectedMinicartCategory,
      shopifyProducts,
    } = this.props
    const products = []
    const rowsPerPage = 8
    const page = this.state.page

    console.log('Products after filtering', this.state.products)

    for (var key in matches) {
      if (key.indexOf('custom') > -1) {
        products.push(matches[key])
      }
    }

    let widthSelect = (
      <Autocomplete
        id="combobox-width"
        options={this.state.widthOptions}
        getOptionLabel={(option) => (option ? option.toString() : '')}
        inputValue={this.state.widthInput}
        onInputChange={(event, newValue) =>
          this.setState({ widthInput: newValue })
        }
        value={this.state.width}
        onChange={(event, newValue) => this.handleWidthChange(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Width"
            variant="outlined"
            fullWidth
            margin="dense"
          >
            {R.addIndex(R.map)((value, i) => {
              return (
                <MenuItem key={i} value={value}>
                  {value}
                </MenuItem>
              )
            })(this.state.widthOptions)}
          </TextField>
        )}
      />
    )

    let heightSelect = (
      <Autocomplete
        id="combobox-height"
        options={this.state.heightOptions}
        getOptionLabel={(option) => (option ? option.toString() : '')}
        inputValue={this.state.heightInput}
        onInputChange={(event, newValue) =>
          this.setState({ heightInput: newValue })
        }
        value={this.state.height}
        onChange={(event, newValue) => this.handleHeightChange(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Height"
            variant="outlined"
            fullWidth
            margin="dense"
          >
            {R.addIndex(R.map)((value, i) => {
              return (
                <MenuItem key={i} value={value}>
                  {value}
                </MenuItem>
              )
            })(this.state.heightOptions)}
          </TextField>
        )}
      />
    )

    if (this.state.customProduct) {
      widthSelect = (
        <TextField
          variant="outlined"
          margin="dense"
          type="text"
          fullWidth
          label="Custom Width"
          value={this.state.width}
          error={
            Number(this.state.width) > 250 ||
            Number(this.state.width) * Number(this.state.height) > 250 * 110
          }
          onChange={(e) => this.handleWidthChange(e.target.value)}
        />
      )
      heightSelect = (
        <TextField
          variant="outlined"
          margin="dense"
          type="text"
          fullWidth
          label="Custom Height"
          value={this.state.height}
          error={
            Number(this.state.height) > 250 ||
            Number(this.state.width) * Number(this.state.height) > 250 * 110
          }
          onChange={(e) => this.handleHeightChange(e.target.value)}
        />
      )
    }
    const customSelect = (
      <CustomSideFormControl
        control={
          <Checkbox
            style={{ marginRight: '10px' }}
            size="small"
            name="customProduct"
            type="checkbox"
            checked={this.state.customProduct}
            onChange={this.handleCustomProductChange}
          />
        }
        label="Custom side"
      />
    )

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="Search and add Helsingö Products" />
          <div
            style={{
              alignSelf: 'center',
              paddingLeft: '10px',
            }}
          >
            <ClearFab
              aria-label="clear"
              size="small"
              onClick={this.handleClearFilter}
            >
              <RefreshIcon fontSize="large" />
            </ClearFab>
          </div>
        </div>
        <form
          onSubmit={this.handleSubmit}
          style={{
            margin: '10px 0 20px 0',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>
            <TextField
              select
              label="Category"
              value={this.state.category}
              onChange={this.handleCategoryChange}
              variant="outlined"
              margin="dense"
              fullWidth
            >
              {R.addIndex(R.map)((value, i) => {
                return (
                  <MenuItem key={i} value={value}>
                    {value}
                  </MenuItem>
                )
              })(this.state.categoryOptions)}
            </TextField>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '100%', paddingRight: '10px' }}>
              <Autocomplete
                id="combobox-product"
                options={this.state.typeOptions}
                inputValue={this.state.typeInput}
                onInputChange={(event, newValue) =>
                  this.setState({ typeInput: newValue })
                }
                value={this.state.type}
                onChange={(event, newValue) => this.handleTypeChange(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Product"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                )}
              />
            </div>
            <div style={{ width: '100%' }}>
              <TextField
                select
                label="Color"
                fullWidth
                value={this.state.color}
                onChange={this.handleColorChange}
                variant="outlined"
                margin="dense"
              >
                {R.addIndex(R.map)((value, i) => {
                  return (
                    <MenuItem key={i} value={value}>
                      {value}
                    </MenuItem>
                  )
                })(this.state.colorOptions)}
              </TextField>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '100%', paddingRight: '10px' }}>
              <TextField
                select
                label="Model"
                value={this.state.door}
                onChange={this.handleDoorTypeChange}
                variant="outlined"
                margin="dense"
                fullWidth
              >
                {R.addIndex(R.map)((value, i) => {
                  return (
                    <MenuItem key={i} value={value}>
                      {value}
                    </MenuItem>
                  )
                })(this.state.doorTypeOptions)}
              </TextField>
            </div>
            <div style={{ width: '100%' }}>{widthSelect}</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '100%', paddingRight: '10px' }}>
              {customSelect}
            </div>
            <div style={{ width: '100%' }}>{heightSelect}</div>
          </div>
        </form>

        <div style={{ display: 'flex', width: '100%' }}>
          <TextField
            variant="outlined"
            margin="dense"
            label="Search"
            fullWidth
            onChange={this.handleSearchTermChange}
            value={this.state.searchTerm}
          />
        </div>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell> SKU </TableCell>
              <TableCell> Product Name </TableCell>
              <TableCell> Type </TableCell>
              {isSwitch || <TableCell style={{ width: 50 }}>Qty </TableCell>}
              <TableCell style={{ width: 50 }}> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {R.addIndex(R.map)((product, i) => {
              return (
                <CustomProductRow
                  key={product.sku}
                  rowId={'custom' + i}
                  product={product}
                  cart={cart}
                  updateCart={updateCart}
                  addItemToCart={this.props.addItemToCart}
                  isSwitch={isSwitch}
                  selectMatch={this.props.selectMatch}
                  selectedMinicartCategory={selectedMinicartCategory}
                  isCustomSide={this.state.customProduct}
                />
              )
            })(
              this.state.products.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[8]}
                count={this.state.products.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} of ${count} products`
                }
                onChangePage={this.handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    )
  }
}

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0)
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1)
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1)
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div style={{ flexShrink: 0, marginLeft: 20 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  )
}

export default CustomItems
