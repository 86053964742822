import React, { useState, useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import {
  TableTopMaterialRow,
  TableTopWidthRow,
  TableTopDepthRow,
  TableTopSelectRow,
  TableTopTotal,
} from './TableTopRows'

import { sinkTypes, stoveTypes, faucetTypes, chamferTypes } from './Pricing'

export const TableTop = ({
  tableTopNumber,
  chamferPricing,
  updateTableTopStats,
}) => {
  const [width, setWidth] = useState(0)
  const [depth, setDepth] = useState(0)
  const [sinkPrice, setSinkPrice] = useState(0)
  const [stovePrice, setStovePrice] = useState(0)
  const [faucetPrice, setFaucetPrice] = useState(0)
  const [chamferPrice, setChamferPrice] = useState(0)
  const [material, setMaterial] = useState({})
  const [materialPrice, setMaterialPrice] = useState(0)

  useEffect(() => {
    if (material && material.price) {
      const materialPrice = ((width * depth) / 1e6) * material.price
      setMaterialPrice(materialPrice)
    }
  }, [material, width, depth])

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ fontWeight: 'bold' }}>
            {`Tabletop ${tableTopNumber + 1}`}
          </TableCell>
          <TableCell />
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableTopMaterialRow
          updateTableTopStats={updateTableTopStats}
          setMaterial={setMaterial}
        />
        <TableTopWidthRow
          depth={depth}
          setWidth={setWidth}
          updateTableTopStats={updateTableTopStats}
        />
        <TableTopDepthRow
          width={width}
          setDepth={setDepth}
          materialPrice={materialPrice}
          updateTableTopStats={updateTableTopStats}
        />
        <TableTopSelectRow
          label={'Sink'}
          selectionOptions={sinkTypes}
          setPrice={setSinkPrice}
          updateTableTopStats={updateTableTopStats}
        />
        <TableTopSelectRow
          label={'Stove'}
          selectionOptions={stoveTypes}
          setPrice={setStovePrice}
          updateTableTopStats={updateTableTopStats}
        />
        <TableTopSelectRow
          label={'Faucet'}
          selectionOptions={faucetTypes}
          setPrice={setFaucetPrice}
          updateTableTopStats={updateTableTopStats}
        />
        <TableTopSelectRow
          label={'Chamfers'}
          selectionOptions={chamferTypes}
          setPrice={setChamferPrice}
          pricing={chamferPricing(width, depth)}
          updateTableTopStats={updateTableTopStats}
        />
        <TableTopTotal
          align="right"
          totalPrice={
            sinkPrice + stovePrice + faucetPrice + chamferPrice + materialPrice
          }
        />
      </TableBody>
    </Table>
  )
}
