import * as R from 'ramda'
import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
// import RemoveIcon from '@material-ui/icons/Remove'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import { getProductShortName } from '../../utils/helpers.js'
import { styled } from '@material-ui/styles'

const AddFab = styled(Fab)({
  backgroundColor: '#c7beb8',
  color: '#fafafa',
  '&:hover': {
    backgroundColor: '#4caf50',
  },
})

/*
const RemoveFab = styled(Fab)({
  marginLeft: 10,
  backgroundColor: '#c7beb8',
  color: '#fafafa',
  '&:hover': {
    backgroundColor: '#e10050',
  },
})
*/

const SwapFab = styled(Fab)({
  backgroundColor: '#c7beb8',
  color: '#fafafa',
  '&:hover': {
    backgroundColor: '#e10050',
  },
})

class CustomProductRow extends Component {
  constructor(props) {
    super(props)
    this.handleQtyChange = this.handleQtyChange.bind(this)
    this.handleCustomPriceChange = this.handleCustomPriceChange.bind(this)

    this.state = {
      itemColor: 'white',
      cartText: 'ADD TO CART',
      customPrice: null,
      qty: 1,
    }
  }

  handleQtyChange(event) {
    this.setState({ qty: parseInt(event.target.value) })
  }

  handleCustomPriceChange(event) {
    this.setState({ customPrice: parseInt(event.target.value) })
  }

  render() {
    const {
      rowId,
      product,
      cart,
      updateCart,
      isSwitch,
      selectedMinicartCategory,
      addItemToCart,
    } = this.props
    // Add match dimensions to product name, since parent product name does not contain
    // size info, but variant data does
    console.log('TITLE', product.parent_product_title)
    const matchName =
      R.or(R.path(['parent_product_title'], product), '') +
      ' ' +
      R.or(R.path(['cabinet_width'], product), 0) +
      'x' +
      R.or(R.path(['cabinet_height'], product), 0)
    const matchType = R.or(R.path(['product_type'], product), '')
    const matchDoorType = R.or(R.path(['door_type'], product), '')
    const matchSku = R.or(R.path(['sku'], product), '')
    const matchCategory = R.or(R.path(['product_category'], product), '')
    const matchColor = R.or(R.path(['color'], product), '')
    // const variantId = R.or(R.path(['id'], product), '')
    const matchSize =
      R.or(R.path(['cabinet_width'], product), 0) +
      'x' +
      R.or(R.path(['cabinet_height'], product), 0)
    const id = matchSku + matchSize + selectedMinicartCategory
    // const matchName = `${matchColor} ${matchDoorType} ${matchType} ${matchSize}`
    // const matchName = getProductShortName(product)

    const itemToCart = (id, cart, updateCart, isAdd) => {
      const { isCustomSide } = this.props
      const { qty } = this.state

      /*
      const newCart = { ...cart }
      if (cart[id] == null) {
        isAdd &&
          (newCart[id] = {
            sku: matchSku,
            variant_id: variantId,
            type: matchType,
            product: matchName,
            custom_color: matchColor,
            qty: this.state.qty,
            custom_size: matchSize,
            cart_section: selectedMinicartCategory,
            custom_price: this.state.customPrice,
          })
      } else {
        const newQty = cart[id].qty + (isAdd ? 1 : -1) * this.state.qty
        if (newQty > 0) {
          const item = {
            sku: matchSku,
            variant_id: variantId,
            type: matchType,
            product: matchName,
            category: matchCategory,
            custom_color: matchColor,
            qty: newQty,
            custom_size: matchSize,
            cart_section: selectedMinicartCategory,
          }
          if (cart[id].hasOwnProperty('custom_price'))
            item['custom_price'] = cart[id]['custom_price']
          // this.setState({itemColor: 'white', cartText: 'ADD TO CART'})
          // delete newCart[id]
          newCart[id] = item
        } else {
          delete newCart[id]
        }
      }
      updateCart(newCart)
      */

      addItemToCart(id, {
        sku: matchSku,
        type: matchType,
        qty,
        product: matchName,
        category: matchCategory,
        custom_color: matchColor,
        custom_size: matchSize,
        cart_section: selectedMinicartCategory,
        comment: isCustomSide ? matchSize : '',
      })
    }

    if (R.isEmpty(cart)) {
      // console.log('empty cart')
      if (this.state.cartText !== 'ADD TO CART')
        this.setState({ itemColor: 'white', cartText: 'ADD TO CART' })
    }

    return (
      <TableRow hover={false} style={{ position: 'relative' }}>
        <TableCell>{matchSku}</TableCell>
        <TableCell style={{ color: 'rgb(2, 136, 209)' }}>
          {matchName.replace('A.S.Helsingö', '')}
        </TableCell>
        <TableCell>{matchType}</TableCell>
        {isSwitch || (
          <TableCell>
            <TextField
              type="number"
              inputProps={{ min: '1', max: '100', step: '1', placeholder: '1' }}
              onChange={this.handleQtyChange}
            />
          </TableCell>
        )}
        {matchSku === 'OPEN-XX' && (
          <TableCell>
            <TextField
              type="number"
              inputProps={{
                min: '1',
                max: '100',
                step: '1',
                placeholder: 'price',
              }}
              onChange={this.handleCustomPriceChange}
            />
          </TableCell>
        )}

        {!isSwitch ? (
          <TableCell>
            <AddFab
              aria-label="add"
              size="small"
              onClick={
                !matchSize.includes('custom')
                  ? () => itemToCart(id, cart, updateCart, true)
                  : null
              }
            >
              <AddIcon fontSize="large" />
            </AddFab>
          </TableCell>
        ) : (
          <TableCell>
            <SwapFab
              aria-label="swap"
              size="small"
              onClick={() => this.props.selectMatch(product)}
            >
              <SwapHorizIcon fontSize="large" />
            </SwapFab>
          </TableCell>
        )}
      </TableRow>
    )
  }
}

export default CustomProductRow
