import React, { useState } from 'react'
import * as R from 'ramda'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { styled } from '@material-ui/styles'
import ConfirmDialog from './ConfirmDialog'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import { getOriginalPdf, convertPriceEstimateToPaos } from '../../api/server'

const MetaTable = styled(Table)({
  borderLeft: 'solid 5px #c7beb8',
  marginBottom: 20,
  '& .MuiTableCell-root': {
    borderBottom: 'none',
  },
})

const StyledButton = styled(Button)({
  textTransform: 'none',
  color: 'rgb(119, 119, 119)',
})

const CommentTableCell = styled(TableCell)({
  maxWidth: '10vw',
  maxHeight: '5vh',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

const MatcherMeta = ({ item, handleConvertToPaos }) => {
  console.log('item', item)
  const date = R.path(['date'], item)
  const id = R.path(['id'], item)
  const email = R.path(['email'], item)
  const color = R.path(['color'], item)
  const store = R.path(['store'], item)
  const doormodel = R.path(['doormodel'], item)
  const comment = R.pathOr('', ['comment'], item)
  const sink = R.pathOr('', ['sink'], item)
  const tap = R.pathOr('', ['tap'], item)
  const handle = R.pathOr('', ['handle'], item)
  const type = R.path(['type'], item)
  const shopifyDraftId = R.path(['shopify_draft_order_id'], item)
  const shopifyCustomerId = R.path(['shopify_customer_id'], item)
  const [confirmOpen, setConfirmOpen] = useState(false)
  // const pdf = "data:application/pdf;base64, " + encodeURI(R.path(['pdf'], item))

  return (
    <MetaTable>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>ID</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Color</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Model</TableCell>
          <TableCell>Draftorder ID</TableCell>
          <TableCell>Customer ID</TableCell>
          <TableCell>Store</TableCell>
          {sink === '' || <TableCell>Sink</TableCell>}
          {tap === '' || <TableCell>Tap</TableCell>}
          {handle === '' || <TableCell>Handle</TableCell>}
          <TableCell>
            <Button variant="contained" onClick={() => getOriginalPdf(id)}>
              Download Plan
            </Button>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow hover={false}>
          <TableCell>{date}</TableCell>
          <TableCell>{id}</TableCell>
          <TableCell>{email}</TableCell>
          <TableCell>{color}</TableCell>
          <TableCell>{type}</TableCell>
          <TableCell>{doormodel}</TableCell>
          <TableCell>{shopifyDraftId}</TableCell>
          <TableCell>{shopifyCustomerId}</TableCell>
          <TableCell>{store}</TableCell>
          {sink === '' || <TableCell>{sink}</TableCell>}
          {tap === '' || <TableCell>{tap}</TableCell>}
          {handle === '' || <TableCell>{handle}</TableCell>}
          {item.isPriceEstimate && (
            <TableCell>
              <Button variant="contained" onClick={() => setConfirmOpen(true)}>
                Convert to PAOS
              </Button>
              <ConfirmDialog
                open={confirmOpen}
                id="trigger-product-fetch-dialog"
                onConfirm={() => handleConvertToPaos()}
                onClose={() => setConfirmOpen(false)}
                confirmIcon="Yes"
                clearIcon="No"
              />
            </TableCell>
          )}
        </TableRow>

        <TableRow>
          <Tooltip title={comment}>
            <CommentTableCell>{comment}</CommentTableCell>
          </Tooltip>
        </TableRow>
      </TableBody>
    </MetaTable>
  )
}

export default MatcherMeta
