import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { makeStyles } from '@material-ui/core/styles'
import countriesJSON from '../../countries_json.json'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import {
  uploadIkeaPDF,
  getUploadItemById,
  uploadIkeaPDFShopify,
} from '../../api/server'
import shortId from 'shortid'

const useStyles = makeStyles({
  confirmIcon: {
    color: 'white',
    marginLeft: 10,
    backgroundColor: '#f50057',
    '&:hover': {
      color: 'black',
      backgroundColor: 'rgb(199, 190, 184)',
    },
  },
  cancelIcon: {
    color: 'white',
    backgroundColor: '#8bc34a',
    marginLeft: 10,
    '&:hover': {
      color: 'black',
      backgroundColor: 'rgb(199, 190, 184)',
    },
  },
  deleteIcon: {
    color: 'rgba(0, 0, 0, 0.87)',
    '&:hover': {
      color: 'red',
      cursor: 'pointer',
    },
  },
  input: {
    display: 'none',
  },
})

const AddNewDialog = (props) => {
  const classes = useStyles()
  const { onClose, onSave, open, isPriceEstimate } = props
  const [currentFile, setCurrentFile] = useState(null)
  const [errors, setErrors] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [comment, setComment] = useState('')
  const [country, setCountry] = useState('')
  const [store, setStore] = useState('')
  const [color, setColor] = useState('')
  const [doormodel, setDoorModel] = useState('')
  const [pending, setPending] = useState(false)
  const [errorOnSave, setErrorOnSave] = useState('')
  const [success, setSuccess] = useState(false)

  const models = [
    { label: 'Ingarö', value: 'Ingarö' },
    { label: 'Ensiö', value: 'Ensio' },
    { label: 'Samsö', value: 'Samsö' },
  ]

  const stores = [
    { label: 'FI', value: 'FI' },
    { label: 'EN', value: 'EN' },
    { label: 'SE', value: 'SE' },
  ]

  const colors = [
    { label: 'Clean White', value: 'Clean White' },
    { label: 'Natural White', value: 'Natural White' },
    { label: 'Blush Rose', value: 'Blush Rose' },
    { label: 'Feather Grey', value: 'Feather Grey' },
    { label: 'Linen Brown', value: 'Linen Brown' },
    { label: 'Thermal Grey', value: 'Thermal Grey' },
    { label: 'Polar Grey', value: 'Polar Grey' },
    { label: 'Water Green', value: 'Water Green' },
    { label: 'Petrol Blue', value: 'Petrol Blue' },
    { label: 'Deep Burgundy', value: 'Deep Burgundy' },
    { label: 'Graphite Grey', value: 'Graphite Grey' },
    { label: 'Midnight Black', value: 'Midnight Black' },
    { label: 'Natural Oak', value: 'Natural Oak' },
    { label: 'Natural Walnut', value: 'Natural Walnut' },
    { label: 'Polished Aluminium', value: 'Polished Aluminium' },
    { label: 'Smoke Oak', value: 'Smoke Oak' },
    { label: 'White Oak', value: 'White Oak' },
    { label: 'Ash Green', value: 'Ash Green' },
    { label: 'Ivory Beige', value: 'Ivory Beige' },
    { label: 'Dusty Rose', value: 'Dusty Rose' },
  ]

  function clearFields() {
    setCurrentFile(null)
    setFirstName('')
    setLastName('')
    setEmail('')
    setComment('')
    setCountry('')
    setStore('')
    setErrors(null)
    setDoorModel('')
    setColor('')
    setPending(false)
    setErrorOnSave(false)
    setSuccess(false)
  }

  function handleClose() {
    clearFields()
    onClose()
  }

  function handleFileUpload(event) {
    if (event.target.files && event.target.files[0]) {
      setCurrentFile(event.target.files[0])
    }
  }

  function validateFields() {
    let newErrors = {}
    if (!currentFile) {
      newErrors = { ...newErrors, pdf: true }
    }
    if (!email || email === '') {
      newErrors = { ...newErrors, email: true }
    }
    if (!firstName || firstName === '') {
      newErrors = { ...newErrors, firstName: true }
    }
    if (!lastName || lastName === '') {
      newErrors = { ...newErrors, lastName: true }
    }
    if (!country || country === '') {
      newErrors = { ...newErrors, country: true }
    }
    if (!store || store === '') {
      newErrors = { ...newErrors, store: true }
    }
    /*
    if (!doormodel || doormodel === '') {
      newErrors = { ...newErrors, doormodel: true }
    }
    if (!color || color === '') {
      newErrors = { ...newErrors, color: true }
    }
    */
    setErrors(newErrors)
    return newErrors
  }

  const handleOnSaveResponse = async (id) => {
    // Get the uploaded item from backend by id
    // since upload request does not return
    // the data
    try {
      const res = await getUploadItemById(id)
      if (res) {
        setSuccess(true)
        setPending(false)
        onSave(JSON.parse(res))
      }
    } catch (err) {
      console.log(err)
      setPending(false)
      setErrorOnSave(true)
    }
  }

  function handlePostPDF(formData, id) {
    return uploadIkeaPDFShopify(formData)
      .then(() => {
        handleOnSaveResponse(id)
      })
      .catch((e) => {
        setPending(false)
        setErrorOnSave(true)
        console.log(e)
      })
  }

  function handleSubmit() {
    // TODO: HOW TO AVOID SAME IDs FROM online store
    // AND manual adding
    const newErrors = validateFields()
    if (Object.keys(newErrors) && Object.keys(newErrors).length === 0) {
      const id = shortId.generate()
      const formData = new FormData()
      setPending(true)
      formData.append('pdf', currentFile)
      formData.append('ticket_no', id)
      formData.append('email', email)
      formData.append('country', country)
      formData.append('store', store)
      formData.append('comment', comment)
      formData.append('firstname', firstName)
      formData.append('lastname', lastName)
      formData.append('doormodel', doormodel)
      formData.append('color', color)
      handlePostPDF(formData, id)
    }
  }

  const DialogPending = () => (
    <DialogContent>
      <DialogContentText>
        Uploading file... This might take couple of minutes.
      </DialogContentText>
      <div style={{ textAlign: 'center' }}>
        <CircularProgress color="secondary" />
      </div>
    </DialogContent>
  )

  const DialogSuccess = () => (
    <DialogContent>
      <div style={{ textAlign: 'center', padding: '10px' }}>
        <CheckCircleIcon fontSize="large" style={{ color: 'green' }} />
      </div>
      <DialogContentText>Successfully added new customer!</DialogContentText>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </DialogContent>
  )

  const DialogError = () => (
    <DialogContent>
      <DialogContentText>
        Oops! Something went wrong while uploading new file...
      </DialogContentText>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </DialogContent>
  )

  return (
    <div>
      <Dialog
        name="add-new-customer-dialog"
        onClose={handleClose}
        aria-labelledby="add-new-customer-dialoge"
        open={open}
      >
        <>
          {!pending && !success && !errorOnSave && (
            <>
              <DialogTitle>
                {isPriceEstimate
                  ? 'Add new Price Estimate'
                  : 'Add new Purchase Help'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Manually add new customer info and Ikea plan
                </DialogContentText>
                <input
                  accept="application/pdf"
                  className={classes.input}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={handleFileUpload}
                />
                {errors && errors.pdf && (
                  <p style={{ color: 'red' }}>Please upload the PDF plan!</p>
                )}

                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    component="span"
                    color="secondary"
                  >
                    {currentFile ? 'Upload new PDF' : 'Upload PDF'}
                  </Button>
                </label>
                {currentFile && <AttachFileIcon />}
                <TextField
                  required
                  value={firstName}
                  error={errors && errors.firstName}
                  margin="dense"
                  id="firstname"
                  label="First name"
                  type="name"
                  fullWidth
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  required
                  value={lastName}
                  error={errors && errors.lastName}
                  margin="dense"
                  id="lastname"
                  label="Last name"
                  type="name"
                  fullWidth
                  onChange={(e) => setLastName(e.target.value)}
                />
                <TextField
                  value={email}
                  error={errors && errors.email}
                  required
                  margin="dense"
                  id="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  required
                  error={errors && errors.country}
                  select
                  value={country}
                  margin="dense"
                  id="country"
                  label="Country"
                  fullWidth
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {countriesJSON.map((option) => (
                    <MenuItem key={option.Code} value={option.Code}>
                      {option.Name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  error={errors && errors.store}
                  select
                  value={store}
                  margin="dense"
                  id="store"
                  label="Store"
                  fullWidth
                  onChange={(e) => setStore(e.target.value)}
                >
                  {stores.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  value={doormodel}
                  margin="dense"
                  id="doormodel"
                  label="Model"
                  fullWidth
                  onChange={(e) => setDoorModel(e.target.value)}
                >
                  {models.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  value={color}
                  margin="dense"
                  id="color"
                  label="Color"
                  fullWidth
                  onChange={(e) => setColor(e.target.value)}
                >
                  {colors.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  multiline
                  value={comment}
                  rowsMax={5}
                  margin="dense"
                  id="comment"
                  label="Comment"
                  type="text"
                  fullWidth
                  onChange={(e) => setComment(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                {pending && <CircularProgress color="secondary" />}
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                  Save
                </Button>
              </DialogActions>
            </>
          )}
          {pending && <DialogPending />}
          {success && <DialogSuccess />}
          {errorOnSave && <DialogError />}
        </>
      </Dialog>
    </div>
  )
}

export default AddNewDialog
