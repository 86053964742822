import React, { useState, useEffect } from 'react'
import { pdf } from '@react-pdf/renderer'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import RemoveIcon from '@material-ui/icons/Remove'
import { styled } from '@material-ui/styles'

import { TableTop } from './TableTop'
import { ExtraHoles } from './ExtraHoles'

import PriceTable from './PriceTable'
import MaterialDistanceTable from './MaterialDistanceTable'
import { chamferPricing, chamferPricePerMeter } from './Pricing'

import { TableTopInvoicePdf } from './TableTopInvoicePdf'
import { TextField } from '@material-ui/core'

const languageOptions = [
  { label: 'FI', value: 1 },
  { label: 'EN', value: 2 },
  { label: 'SE', value: 3 },
]

const AddFab = styled(Fab)({
  backgroundColor: '#c7beb8',
  color: '#fafafa',
  '&:hover': {
    backgroundColor: '#4caf50',
  },
})

const Padding = ({ h }) => <div style={{ height: h }} />

const AddTableTops = ({ handleOnClick }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '1vw',
        justifyContent: 'space-between',
        paddingRight: '10px',
      }}
    >
      <div style={{ alignSelf: 'center', flex: 0.4 }}>ADD A TABLETOP</div>
      <AddFab aria-label="add" size="small" onClick={() => handleOnClick()}>
        <AddIcon fontSize="large" />
      </AddFab>
    </div>
  )
}

const RemoveTableTop = ({ handleOnClick }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '1vw',
        justifyContent: 'space-between',
        paddingRight: '10px',
      }}
    >
      <div style={{ alignSelf: 'center', flex: 0.4 }}></div>
      <AddFab aria-label="remove" size="small" onClick={() => handleOnClick()}>
        <RemoveIcon fontSize="large" />
      </AddFab>
    </div>
  )
}

const AddExtraOpenings = ({ handleOnClick }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '1vw',
        justifyContent: 'space-between',
        paddingRight: '10px',
      }}
    >
      <div style={{ alignSelf: 'center', flex: 0.4 }}>ADD EXTRA OPENING</div>
      <AddFab aria-label="add" size="small" onClick={() => handleOnClick()}>
        <AddIcon fontSize="large" />
      </AddFab>
    </div>
  )
}

const RemoveExtraOpening = ({ handleOnClick }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '1vw',
        justifyContent: 'space-between',
        paddingRight: '10px',
      }}
    >
      <div style={{ alignSelf: 'center', flex: 0.4 }}></div>
      <AddFab aria-label="remove" size="small" onClick={() => handleOnClick()}>
        <RemoveIcon fontSize="large" />
      </AddFab>
    </div>
  )
}

const AddTableTopsToCart = ({ handleOnClick, distanceStats }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '1vw',
        justifyContent: 'space-between',
        paddingRight: '10px',
      }}
    >
      <div style={{ alignSelf: 'center', flex: 0.4 }}>
        ADD TABLETOPS TO CART
      </div>
      <AddFab
        aria-label="add"
        size="small"
        onClick={() => handleOnClick()}
        disabled={
          distanceStats &&
          distanceStats.price1 === 0 &&
          distanceStats.price2 === 0 &&
          distanceStats.price3 === 0
        }
      >
        <ShoppingCartIcon />
      </AddFab>
    </div>
  )
}

const SelectPdfLanguage = ({ handleOnClick, selectedValue }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '1vw',
        justifyContent: 'space-between',
        paddingRight: '10px',
      }}
    >
      <div style={{ alignSelf: 'center', flex: 0.4 }}>SELECT PDF LANGUAGE</div>
      <TextField
        select
        value={selectedValue}
        onChange={(event) => {
          handleOnClick(event)
        }}
        SelectProps={{
          native: true,
        }}
      >
        {languageOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </div>
  )
}

/*
const AddPDF = ({ handleOnClick }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1vw' }}>
      <div style={{ alignSelf: 'center', flex: 0.4 }}>Show PDF</div>
      <AddFab aria-label="add" size="small" onClick={() => handleOnClick()}>
        <AddIcon fontSize="large" />
      </AddFab>
    </div>
  )
}
*/

const TableTopSection = ({
  cart,
  updateCart,
  data,
  updateTableTopPdfData,
  selectedMinicartCategory,
}) => {
  const [tableTopStats, setTableTopStats] = useState({})
  const [tableTopCount, setTableTopCount] = useState(0)
  const [selectedLanguage, setSelectedLanguage] = useState(2)

  useEffect(() => {
    /*
    If we already know customer's store, use that
    as default value for tabletop pdf language.
    */
    if (data && data['country']) {
      const languageBasedOnCountry = languageOptions.find(
        (item) => item.label === data['country']
      )
      if (
        languageBasedOnCountry &&
        languageBasedOnCountry.value &&
        languageBasedOnCountry.value !== selectedLanguage
      ) {
        setSelectedLanguage(languageBasedOnCountry.value)
      }
    }
  }, [data])

  const [distanceStats, setDistanceStats] = useState({
    price1: 0,
    price2: 0,
    price3: 0,
    label: '0',
  })
  const [extraHoleCount, setExtraHoleCount] = useState(0)
  const [extraHoles, setExtraHoles] = useState({})

  const clearTabletops = () => {
    // Clear tabletops and
    // set everything back to
    // initial state
    setTableTopStats({})
    setTableTopCount(0)
    setDistanceStats({
      price1: 0,
      price2: 0,
      price3: 0,
      label: '0',
    })
    setExtraHoleCount(0)
    setExtraHoles({})
  }

  const addExtraOpeningToStore = (extraOpeningNumber) => {
    const extraOpening = {
      price: 0,
      qty: 0,
      label: '',
    }
    setExtraHoles({ [extraOpeningNumber]: extraOpening, ...extraHoles })
  }

  const updateExtraHoles = (key, value) => {
    const newState = { ...extraHoles }
    newState[key] = value
    setExtraHoles(newState)
  }

  const removeLatestExtraOpening = () => {
    const newState = { ...extraHoles }
    delete newState[extraHoleCount]
    setExtraHoles(newState)
    setExtraHoleCount(extraHoleCount - 1)
  }

  const addTableTopToStore = (tableTopNumber) => {
    const newTableTop = {
      Sink: {
        price: 0,
        label: 'No opening for sink',
        label_fi: 'Ei aukkoa altaalle',
        label_se: 'Ingen öppning för dikskho',
      },
      Faucet: {
        price: 0,
        label: 'No opening for faucet',
        label_fi: 'Ei aukkoa hanalle',
        label_se: 'Ingen öppning för kran',
      },
      Stove: {
        price: 0,
        label: 'No opening for stove',
        label_fi: 'Ei aukkoa liedelle',
        label_se: 'Ingen öppning för spis',
      },
      Chamfers: {
        price: 0,
        label: 'No chamfers',
        label_fi: 'Ei reunojen viimeistelyä',
        label_se: 'Obehandlade kanter',
      },
      Material: {
        price: 0,
        label: 'No material selected',
        sku: '',
        label_fi: 'Ei valittua materiaalia',
        label_se: 'Inget material valt',
      },
      depth: 0,
      width: 0,
    }
    setTableTopStats({ [tableTopNumber]: newTableTop, ...tableTopStats })
  }

  //tableTopStats == poor mans redux store
  //updateTableTopStats is passed down to individual TableTop components
  const updateTableTopStats = (tableTopNumber) => (key, value) => {
    const newState = { ...tableTopStats }
    newState[tableTopNumber][key] = value
    setTableTopStats(newState)
  }

  const increaseExtraOpeningCount = () => {
    setExtraHoleCount(extraHoleCount + 1)
    addExtraOpeningToStore(extraHoleCount + 1)
  }

  const increaseTableTopCount = () => {
    setTableTopCount(tableTopCount + 1)
    addTableTopToStore(tableTopCount + 1)
  }

  const removeLatestTableTop = () => {
    const newState = { ...tableTopStats }
    delete newState[tableTopCount]
    setTableTopStats(newState)
    setTableTopCount(tableTopCount - 1)
  }

  const materialTotalPrice = Number(
    Object.values(tableTopStats).reduce((total, item) => {
      const width = Number(item.width)
      const depth = Number(item.depth)
      const materialPrice =
        item.Material && item.Material.price && Number(item.Material.price)
      return total + Number(((width * depth) / 1e6) * Number(materialPrice))
    }, 0)
  ).toFixed(3)

  const extraHolesPrice = Number(
    Object.values(extraHoles).reduce(
      (total, item) => Number(total) + Number(item.price) * Number(item.qty),
      0
    )
  )

  const machiningsTotalPrice = (
    Number(extraHolesPrice) +
    Number(
      Object.values(tableTopStats).reduce((total, item) => {
        const width = Number(item.width)
        const depth = Number(item.depth)
        return (
          total +
          (Number(item.Sink.price) +
            Number(item.Faucet.price) +
            Number(item.Stove.price) +
            chamferPricing(width, depth)(item.Chamfers.price))
        )
      }, 0)
    )
  ).toFixed(3)

  const totalTableTopArea = Number(
    Object.values(tableTopStats).reduce((total, item) => {
      const width = Number(item.width)
      const depth = Number(item.depth)
      return total + Number((width * depth) / 1e6)
    }, 0)
  ).toFixed(3)

  const deliveryAndInstallation = Number(
    totalTableTopArea < 3
      ? distanceStats.price1
      : totalTableTopArea < 6
      ? distanceStats.price2
      : distanceStats.price3
  )

  const totalPrice = (
    Number(materialTotalPrice) +
    Number(machiningsTotalPrice) +
    Number(deliveryAndInstallation)
  ).toFixed(2)

  const tableTopInvoicePDf = (
    <TableTopInvoicePdf
      tableTopStats={tableTopStats}
      deliveryAndInstallation={deliveryAndInstallation}
      distance={distanceStats.label}
      extraHoles={extraHoles}
      totalPrice={totalPrice}
      id={data.id}
      email={data.email}
      language={selectedLanguage}
    />
  )

  const tableTopsToCart = () => {
    const newCart = { ...cart }

    const cartItemsFlat =
      newCart &&
      Object.values(newCart) &&
      Object.values(newCart).reduce((acc, itemArr) => {
        return [...acc, ...itemArr]
      }, [])

    const cartTableTopItems = cartItemsFlat.filter(
      (item) =>
        item.product && item.product.toLocaleLowerCase().includes('tabletop')
    )

    const cartTableTopItemsCount = cartTableTopItems && cartTableTopItems.length
    const tableTopIdentifier = `tableTop-${cartTableTopItemsCount + 1}`

    const materialSkuArr = Object.values(tableTopStats).map(
      (item) => item.Material.sku
    )
    // Check if all tabletops are same material. Give that sku for
    // cart product if yes, otherwise use general tabletop sku
    const allEqual = (arr) => arr.every((v) => v === arr[0])
    const allSameMaterial = allEqual(materialSkuArr)
    const firstMaterialSku = materialSkuArr[0]

    const tableTopPdfStats = {
      tableTopStats: tableTopStats,
      deliveryAndInstallation: deliveryAndInstallation,
      distanceStats: distanceStats,
      extraHoles: extraHoles,
      totalPrice: totalPrice,
      id: data.id,
      email: data.email,
      language: selectedLanguage,
    }

    console.log('selected language', selectedLanguage)

    pdf(tableTopInvoicePDf)
      .toBlob()
      .then((result) => {
        let reader = new FileReader()
        reader.readAsDataURL(result)
        reader.onloadend = function () {
          var base64data = reader.result.replace(/^data:.+;base64,/, '')
          // TODO: Which material sku should we use? Or can we use some other string like
          // tabletop name
          const tableTopItem = {
            sku: allSameMaterial ? firstMaterialSku : 'SXX-XX',
            product: `Tabletops ${cartTableTopItemsCount + 1}`,
            tabletopId: tableTopIdentifier,
            custom_color: '',
            qty: 1,
            custom_size: '',
            comment: 'Tabletops',
            row_price: totalPrice,
            custom_price: totalPrice,
            unit_price: totalPrice,
            pdf: {
              info_pdf_b64: base64data,
              info_pdf_name: `Tabletop_order_confirmation_${
                cartTableTopItemsCount + 1
              }.pdf`,
            },
            cart_section: selectedMinicartCategory,
          }
          const categoryItems = newCart[selectedMinicartCategory]
          if (categoryItems) {
            newCart[selectedMinicartCategory] = [...categoryItems, tableTopItem]
          } else {
            newCart[selectedMinicartCategory] = [tableTopItem]
          }

          updateTableTopPdfData(tableTopIdentifier, tableTopPdfStats)
          console.log('CALLING UPDATE CART WITH', newCart)
          updateCart(newCart)
          // clearTabletops()
        }
      })
  }

  return (
    <React.Fragment>
      <div style={{ padding: '15px' }}>
        <Padding h={20} />
        {[...Array(tableTopCount).keys()].map((tableTopNumber) => (
          <TableTop
            key={tableTopNumber}
            tableTopNumber={tableTopNumber}
            chamferPricePerMeter={chamferPricePerMeter}
            chamferPricing={chamferPricing}
            updateTableTopStats={updateTableTopStats(tableTopNumber + 1)}
          />
        ))}
        {tableTopCount > 0 && (
          <RemoveTableTop handleOnClick={removeLatestTableTop} />
        )}
        <AddTableTops handleOnClick={increaseTableTopCount} />
        {tableTopCount > 0 && (
          <AddExtraOpenings handleOnClick={increaseExtraOpeningCount} />
        )}
        {tableTopCount > 0 && <Padding h={20} />}
        {extraHoleCount > 0 && (
          <>
            <ExtraHoles
              updateExtraHoles={updateExtraHoles}
              extraHoles={extraHoles}
            />
            <RemoveExtraOpening handleOnClick={removeLatestExtraOpening} />
          </>
        )}
        {tableTopCount > 0 && <Padding h={20} />}
        {tableTopCount > 0 && <Padding h={20} />}
        {tableTopCount > 0 && (
          <MaterialDistanceTable setDistanceStats={setDistanceStats} />
        )}
        {tableTopCount > 0 && <Padding h={20} />}
        {tableTopCount > 0 && (
          <PriceTable
            totalPrice={totalPrice}
            machinings={machiningsTotalPrice}
            materials={materialTotalPrice}
            deliveryAndInstallation={deliveryAndInstallation}
          />
        )}
        {tableTopCount > 0 && (
          <SelectPdfLanguage
            selectedValue={selectedLanguage}
            handleOnClick={(e) => setSelectedLanguage(Number(e.target.value))}
          />
        )}
        {tableTopCount > 0 && (
          <AddTableTopsToCart
            handleOnClick={tableTopsToCart}
            distanceStats={distanceStats}
          />
        )}
        {tableTopCount > 0 && <Padding h={50} />}
      </div>
    </React.Fragment>
  )
}

export default TableTopSection
