import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

const CartSendingDialog = ({ onClose, open, isAlert, errorMessage = '' }) => {
  if (isAlert) {
    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle>Oops... Something went wrong!</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle>Sending shopping cart...</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <CircularProgress color="secondary" />
        <DialogContentText>
          This might take couple of seconds! :)
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default CartSendingDialog
