import React, { useState } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import { materials } from './Pricing'

export const TableTopMaterialRow = ({ updateTableTopStats, setMaterial }) => {
  return (
    <TableRow>
      <TableCell>Material</TableCell>
      <TableCell>
        <TextField
          select
          onChange={(event) => {
            setMaterial(JSON.parse(event.target.value))
            updateTableTopStats('Material', JSON.parse(event.target.value))
          }}
          SelectProps={{
            native: true,
          }}
        >
          {materials.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </TableCell>
    </TableRow>
  )
}

export const TableTopWidthRow = ({ depth, setWidth, updateTableTopStats }) => {
  const [value, setValue] = useState(0)
  return (
    <TableRow>
      <TableCell>Width</TableCell>
      <TableCell>
        <TextField
          style={{ fontSize: 12 }}
          type="number"
          value={value}
          onChange={(event) => {
            setWidth(Number(event.target.value))
            setValue(event.target.value)
            updateTableTopStats('width', event.target.value)
          }}
        />
        mm
      </TableCell>
      <TableCell>Area {((depth * value) / 1e6).toFixed(3)}m</TableCell>
    </TableRow>
  )
}

export const TableTopDepthRow = ({
  width,
  setDepth,
  materialPrice,
  updateTableTopStats,
}) => {
  const [value, setValue] = useState(0)
  return (
    <TableRow>
      <TableCell>Depth</TableCell>
      <TableCell>
        <TextField
          style={{ fontSize: 12 }}
          type="number"
          value={value}
          onChange={(event) => {
            setDepth(Number(event.target.value))
            setValue(event.target.value)
            updateTableTopStats('depth', event.target.value)
          }}
        />
        mm
      </TableCell>
      <TableCell>Material cost {materialPrice.toFixed(3)}€</TableCell>
    </TableRow>
  )
}

export const TableTopSelectRow = ({
  label,
  selectionOptions,
  setPrice,
  pricing,
  updateTableTopStats,
}) => {
  const [price, setRowPrice] = useState(0)

  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell>
        <TextField
          select
          onChange={(event) => {
            const value = JSON.parse(event.target.value)
            setRowPrice(value.price)
            updateTableTopStats(label, value)
            pricing
              ? setPrice(Number(pricing(value.price)))
              : setPrice(Number(value.price))
          }}
          SelectProps={{
            native: true,
          }}
        >
          {selectionOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </TableCell>
      <TableCell>{pricing ? pricing(price) : price}€</TableCell>
    </TableRow>
  )
}

export const TableTopTotal = ({ totalPrice }) => {
  return (
    <TableRow>
      <TableCell>Tabletop total price</TableCell>
      <TableCell></TableCell>
      <TableCell>{Number(totalPrice).toFixed(3)}€</TableCell>
    </TableRow>
  )
}
