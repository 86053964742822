import React, { useState, useEffect } from 'react'
import CardHeader from '@material-ui/core/CardHeader'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import Fab from '@material-ui/core/Fab'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { styled } from '@material-ui/styles'
import AccessoryProductRow from './AccessoryProductRow'

const AddFab = styled(Fab)({
  backgroundColor: '#c7beb8',
  color: '#fafafa',
  '&:hover': {
    backgroundColor: '#4caf50',
  },
})

const getLegs = (products) =>
  products.filter(
    (product) =>
      product.product_category &&
      product.product_category.toLowerCase() === 'legs'
  )

const getSinks = (products) =>
  products.filter(
    (product) =>
      product.product_category &&
      product.product_category.toLowerCase() === 'sinks'
  )

const getTaps = (products) =>
  products.filter(
    (product) =>
      product.product_category &&
      product.product_category.toLowerCase() === 'taps'
  )

const getHandles = (products) =>
  products.filter(
    (product) =>
      product.product_category &&
      product.product_category.toLowerCase() === 'handles'
  )

const Accessories = (props) => {
  const {
    cart,
    updateCart,
    isSwitch,
    selectedMinicartCategory,
    sinkmodel,
    handlemodel,
    tapmodel,
    shopifyProducts,
  } = props

  console.log('SINK', sinkmodel)
  console.log('HANDLE', handlemodel)
  console.log('TAP', tapmodel)

  const [legs, setLegs] = useState([])
  const [handles, setHandles] = useState([])
  const [taps, setTaps] = useState([])
  const [sinks, setSinks] = useState([])

  const [selectedSinks, setSelectedSinks] = useState({
    0: { item: {}, qty: 1, first: true },
  })
  const [selectedTaps, setSelectedTaps] = useState({
    0: { item: {}, qty: 1, first: true },
  })
  const [selectedLegs, setSelectedLegs] = useState({
    0: { item: {}, qty: 1, first: true },
  })
  const [selectedHandles, setSelectedHandles] = useState({
    0: { item: {}, qty: 1, first: true },
  })

  const handleSelect = (category, index, value) => {
    if (category === 'sinks') {
      const newSinks = {
        ...selectedSinks,
        [index]: { ...selectedSinks[index], item: value },
      }
      setSelectedSinks(newSinks)
    }
    if (category === 'taps') {
      const newTaps = {
        ...selectedTaps,
        [index]: { ...selectedTaps[index], item: value },
      }
      setSelectedTaps(newTaps)
    }
    if (category === 'legs') {
      const newLegs = {
        ...selectedLegs,
        [index]: { ...selectedLegs[index], item: value },
      }
      setSelectedLegs(newLegs)
    }
    if (category === 'handles') {
      const newHandles = {
        ...selectedHandles,
        [index]: { ...selectedHandles[index], item: value },
      }
      setSelectedHandles(newHandles)
    }
  }

  useEffect(() => {
    if (
      shopifyProducts &&
      shopifyProducts.length &&
      shopifyProducts.length > 0
    ) {
      const sinkOptions = getSinks(shopifyProducts)
      console.log('sinkOptions', sinkOptions)
      const handleOptions = getHandles(shopifyProducts)
      const legOptions = getLegs(shopifyProducts)
      const tapOptions = getTaps(shopifyProducts)

      setSinks(sinkOptions)
      setHandles(handleOptions)
      setLegs(legOptions)
      setTaps(tapOptions)
    }
  }, [shopifyProducts])

  useEffect(() => {
    if (sinkmodel && Object.keys(selectedSinks[0].item).length === 0) {
      console.log('incoming sinkmodel', sinkmodel)
      const selectedOption = sinks.find((item) => item.sku === sinkmodel.trim())
      if (selectedOption) {
        // Set sinkmodel based on props data
        console.log('found matching default sink option', selectedOption)
        handleSelect('sinks', 0, selectedOption)
      }
    }

    if (handlemodel && Object.keys(selectedHandles[0].item).length === 0) {
      console.log('incoming handlemodel', handlemodel)
      const selectedOption = handles.find(
        (item) => item.sku === handlemodel.trim()
      )
      if (selectedOption) {
        // Set default handlemodel base on props data
        console.log('found matching default handle model', selectedOption)
        handleSelect('handles', 0, selectedOption)
      }
    }

    if (tapmodel && Object.keys(selectedTaps[0].item).length === 0) {
      const selectedOption = taps.find((item) => item.sku === tapmodel.trim())
      if (selectedOption) {
        // Set default tapmodel based on props data
        handleSelect('taps', 0, selectedOption)
      }
    }
  }, [sinkmodel, handlemodel, tapmodel])

  const handleChangeQty = (category, index, value) => {
    if (category === 'sinks') {
      const newSinks = {
        ...selectedSinks,
        [index]: { ...selectedSinks[index], qty: value },
      }
      setSelectedSinks(newSinks)
    }
    if (category === 'taps') {
      const newTaps = {
        ...selectedTaps,
        [index]: { ...selectedTaps[index], qty: value },
      }
      setSelectedTaps(newTaps)
    }
    if (category === 'legs') {
      const newLegs = {
        ...selectedLegs,
        [index]: { ...selectedLegs[index], qty: value },
      }
      setSelectedLegs(newLegs)
    }
    if (category === 'handles') {
      const newHandles = {
        ...selectedHandles,
        [index]: { ...selectedHandles[index], qty: value },
      }
      setSelectedHandles(newHandles)
    }
  }

  const handleAddRow = (category) => {
    if (category === 'sinks') {
      const sinksCount = Object.keys(selectedSinks).length
      const newSinks = {
        ...selectedSinks,
        [sinksCount]: { item: {}, qty: 1, first: false },
      }
      setSelectedSinks(newSinks)
    }
    if (category === 'taps') {
      const tapsCount = Object.keys(selectedTaps).length
      const newTaps = {
        ...selectedTaps,
        [tapsCount]: { item: {}, qty: 1, first: false },
      }
      setSelectedTaps(newTaps)
    }
    if (category === 'legs') {
      const legsCount = Object.keys(selectedLegs).length
      const newLegs = {
        ...selectedLegs,
        [legsCount]: { item: {}, qty: 1, first: false },
      }
      setSelectedLegs(newLegs)
    }
    if (category === 'handles') {
      const handlesCount = Object.keys(selectedHandles).length
      const newHandles = {
        ...selectedHandles,
        [handlesCount]: { item: {}, qty: 1, first: false },
      }
      setSelectedHandles(newHandles)
    }
  }

  const itemsToCart = () => {
    const newCart = { ...cart }
    const sectionItems = newCart[selectedMinicartCategory]

    const sinksArr = Object.values(selectedSinks)
      .filter((row) => row.item.sku)
      .map((row) => {
        const { item } = row
        // const itemId = item.sku + selectedMinicartCategory
        return {
          sku: item.sku,
          type: item.product_type || '',
          product: item.parent_product_title,
          custom_color: item.color || '',
          qty: row.qty,
          custom_size: null,
          unit_price: item.price,
          cart_section: selectedMinicartCategory,
        }
      })

    const tapsArr = Object.values(selectedTaps)
      .filter((row) => row.item.sku)
      .map((row) => {
        const { item } = row
        // const itemId = item.sku + selectedMinicartCategory
        return {
          sku: item.sku,
          type: item.product_type || '',
          product: item.parent_product_title,
          custom_color: item.color || '',
          qty: row.qty,
          custom_size: null,
          unit_price: item.price,
          cart_section: selectedMinicartCategory,
        }
      })

    const legsArr = Object.values(selectedLegs)
      .filter((row) => row.item.sku)
      .map((row) => {
        const { item } = row
        // const itemId = item.sku + selectedMinicartCategory
        return {
          sku: item.sku,
          type: item.product_type || '',
          product: item.parent_product_title,
          custom_color: item.color || '',
          qty: row.qty,
          custom_size: null,
          unit_price: item.price,
          cart_section: selectedMinicartCategory,
        }
      })

    const handlesArr = Object.values(selectedHandles)
      .filter((row) => row.item.sku)
      .map((row) => {
        const { item } = row
        // const itemId = item.sku + selectedMinicartCategory
        return {
          sku: item.sku,
          type: item.product_type || '',
          product: item.parent_product_title,
          custom_color: item.color || '',
          qty: row.qty,
          custom_size: null,
          unit_price: item.price,
          cart_section: selectedMinicartCategory,
        }
      })

    if (sectionItems) {
      newCart[selectedMinicartCategory] = [
        ...sectionItems,
        ...sinksArr,
        ...tapsArr,
        ...legsArr,
        ...handlesArr,
      ]
    } else {
      newCart[selectedMinicartCategory] = [
        ...sinksArr,
        ...tapsArr,
        ...legsArr,
        ...handlesArr,
      ]
    }
    console.log('old cart', cart)
    console.log('new cart', newCart)
    updateCart(newCart)
  }

  return (
    <div>
      <CardHeader title="Accessories" />
      <div style={{ padding: '15px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 70 }}> Type </TableCell>
              <TableCell> Product Name </TableCell>
              <TableCell style={{ width: 70 }}> SKU </TableCell>
              {isSwitch || <TableCell style={{ width: 50 }}>Qty </TableCell>}
              <TableCell style={{ width: 50 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(selectedHandles).map((handlesRow) => (
              <AccessoryProductRow
                rowId={handlesRow}
                options={handles}
                categoryName="handles"
                selectedProducts={selectedHandles}
                setSelectedProduct={handleSelect}
                handleChangeQty={handleChangeQty}
                handleAddRow={handleAddRow}
              />
            ))}
            {Object.keys(selectedSinks).map((sinksRow) => (
              <AccessoryProductRow
                rowId={sinksRow}
                options={sinks}
                categoryName="sinks"
                selectedProducts={selectedSinks}
                setSelectedProduct={handleSelect}
                handleChangeQty={handleChangeQty}
                handleAddRow={handleAddRow}
              />
            ))}
            {Object.keys(selectedTaps).map((tapsRow) => (
              <AccessoryProductRow
                rowId={tapsRow}
                options={taps}
                categoryName="taps"
                selectedProducts={selectedTaps}
                setSelectedProduct={handleSelect}
                handleChangeQty={handleChangeQty}
                handleAddRow={handleAddRow}
              />
            ))}
            {Object.keys(selectedLegs).map((legsRow) => (
              <AccessoryProductRow
                rowId={legsRow}
                options={legs}
                categoryName="legs"
                selectedProducts={selectedLegs}
                setSelectedProduct={handleSelect}
                handleChangeQty={handleChangeQty}
                handleAddRow={handleAddRow}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={5} align="right">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '4px 0',
                  }}
                >
                  <div style={{ marginRight: '15px' }}>
                    <p>ADD ACCESSORIES TO CART</p>
                  </div>
                  <AddFab
                    aria-label="add"
                    size="small"
                    onClick={() => itemsToCart()}
                  >
                    <ShoppingCartIcon />
                  </AddFab>
                </div>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  )
}

export default Accessories
