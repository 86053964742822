import { createStore, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

import thunkMiddleware from 'redux-thunk'
import rootReducer from './rootReducer'

export const history = createBrowserHistory()

export default function configureStore(preloadedState) {
  let store
  // const reactRouterReduxMiddleware = routerMiddleware(history)

  // We are the browser
  const composeEnhancers = (window.__ENV__ = 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose)

  store = createStore(
    rootReducer(history),
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history),
        thunkMiddleware // for dispatching history actions
        // ... other middlewares ...
      )
    )
  )

  if (module.hot) {
    // HMR for reducers
    module.hot.accept('./rootReducer', () => {
      store.replaceReducer(rootReducer)
    })
  }

  return store
}
