import React from 'react'
import { Typography, TextField } from '@material-ui/core'
import minicartCategories from '../../minicart-options.json'

const CartCategorySelect = ({ handleMinicartCategoryChange }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="subtitle1">
        Choose which products to collect
      </Typography>
      <TextField
        select
        variant="outlined"
        margin="dense"
        label="Category"
        onChange={(e) => handleMinicartCategoryChange(e.target.value)}
        SelectProps={{
          native: true,
        }}
        style={{
          marginRight: 10,
          minWidth: '100px',
          width: '50%',
        }}
      >
        {minicartCategories.map((option) => (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </div>
  )
}

export default CartCategorySelect
